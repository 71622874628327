import {
  Typography,
  Paper,
  Button,
  Grid,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  Input,
  MenuItem,
  Chip,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  FormGroup,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { Comment, Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Fragment, useState } from 'react'
import { useEffect } from 'react'
import TransitionsModal from '../../components/modal/ModalContainer'
import { useLazyQuery } from '@apollo/client'
import { QUERY } from '../../services/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  container: {
    padding: '1.5rem',
  },
  ContainerAddOrder: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '2rem',
    gap: '1rem',
    padding: theme.spacing(0, 15),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textarea: {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    outline: 'none',
    width: '100%!important',
    '&:focus': {
      borderBottom: '2px solid #f69223',
    },
    '&:hover:not(:focus)': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    },
  },
  containerQuoter: {
    marginTop: '1.5rem',
    padding: '0 2rem',
    paddingBottom: '1rem',
  },
  containerButtons: {
    marginTop: '1.5rem',
  },
  message: {
    color: '#000',
    clear: 'both',
    lineHeight: '18px',
    fontSize: '15px',
    padding: '8px',
    position: 'relative',
    margin: '8px 0',
    maxWidth: '85%',
    wordWrap: 'break-word',
    background: '#e1ffc7',
    borderRadius: '5px 0px 5px 5px',
    float: 'right',
    filter: 'drop-shadow(0px 4px 2px #00000033)',
    '&:after': {
      position: 'absolute',
      content: '""',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0px 0 10px 10px',
      borderColor: 'transparent transparent transparent #e1ffc7',
      top: 0,
      right: '-10px',
    },
  },
  metadata: {
    display: 'inline-block',
    float: 'right',
    padding: '0 0 0 7px',
    position: 'relative',
    bottom: '-4px',
  },
  time: {
    color: 'rgba(0, 0, 0, .45)',
    fontSize: '11px',
    display: 'inline-block',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    maxWidth: '150px',
  },
}))

export default function Quoter() {
  const classes = useStyles()
  const [searchTimer, setSearchTimer] = useState(null)
  const [loading, setLoading] = useState(false)
  const [quoter, setQuoter] = useState({
    quoted_product: '',
    amount: '1',
    type_amount: 'kilos',
    frequency: '1',
    frecuency_type: 'dia',
    delivery_city: '',
    observation: '',
    category_type_ids: [],
  })
  const [sentInfo, setSentInfo] = useState({ sent: [], not_sent: [] })

  const [isFull, setIsFull] = useState(false)
  const [producersMatch, setProducers] = useState([])

  const [getProducers, { client }] = useLazyQuery(QUERY.PRODUCERS.PRODUCER_BY_CATEGORY_TYPE, { context: { clientName: 'apiProducer' }, fetchPolicy: 'no-cache' })
  const [getProducersAll] = useLazyQuery(QUERY.PRODUCERS.PRODUCER_BY_CATEGORY_TYPE, { context: { clientName: 'apiProducer' }, fetchPolicy: 'no-cache' })
  const [getFreshProducerByRegion] = useLazyQuery(QUERY.REGIONS.FRESH_PRODUCER_BY_REGION, { context: { clientName: 'apiProducer' } })
  const [getRegions] = useLazyQuery(QUERY.REGIONS.GET_ALL, { context: { clientName: 'apiProducer' } })

  const [allProducers, setAllProducers] = useState(null)

  const [openModal, setOpenModal] = useState({ show: false, component: null })
  const [totalProducers, setTotalProducers] = useState({ _totalFound: 0, _totalProducers: 0 })
  const [regionsOptions, setRegionsOptions] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const checkIsFull = () => {
    const check = Object.keys(quoter).every((key) => {
      switch (key) {
        case 'observation':
          return true
          break
        case 'delivery_city':
          return quoter[key] !== '' && quoter[key].length > 3

        default:
          return quoter[key] !== ''
          break
      }
    })
    setIsFull(check)
    return check
  }

  useEffect(() => {
    checkIsFull()
  }, [quoter])

  const handlerChange = ({ target }) => {
    setQuoter({ ...quoter, [target.id]: target.value })
  }

  const handleSendMatchProducers = async () => {
    await getMatchProducers()
    setOpenModal({ component: 'ModalSendQuote', show: true })
  }

  const handleSendAllProducers = async () => {
    await getAllProducers()
    setOpenModal({ component: 'ModalSendQuote', show: true })
  }

  const getAllProducers = async () => {
    setLoading(true)
    const variables = selectedRegions.length
      ? { idCategoryType: quoter.category_type_ids, region: selectedRegions.flatMap((region) => region.ids) }
      : { idCategoryType: quoter.category_type_ids }
    const { data } = await getProducersAll({ variables })
    const {
      searchProducerByCategoryType: { producers },
    } = data
    setAllProducers(structuredClone(producers))
    setLoading(false)
  }

  const getMatchProducers = async () => {
    setLoading(true)
    const variables = selectedRegions.length
      ? {
        q: quoter.quoted_product,
        region: selectedRegions.flatMap(region => region.ids),
        idCategoryType: quoter.category_type_ids,
      }
      : {
        q: quoter.quoted_product,
        idCategoryType: quoter.category_type_ids,
      }
    const { data } = await getProducers({
      variables
    })

    const {
      searchProducerByCategoryType: {
        producers, _totalFound, _totalProducers
      },
    } = data
    setProducers(structuredClone(producers))
    setTotalProducers({ _totalFound, _totalProducers })
    setLoading(false)
  }
  const getRegionsOptions = async () => {
    if (quoter.category_type_ids.includes('2')) {
      const { data } = await getFreshProducerByRegion()
      const { freshProducerByRegion: { regions } } = data
      setRegionsOptions(regions)
    } else {
      const { data: { regions: { regions } } } = await getRegions()
      setRegionsOptions(regions)
    }
  }

  const createTimesFor = () =>
    quoter.frequency !== '1' ? `veces por ${quoter.frecuency_type}` : `vez por ${quoter.frecuency_type}`

  const createMessage = () => {
    return `${quoter.amount} ${quoter.type_amount} de ${quoter.quoted_product} ${quoter.frequency
      } ${createTimesFor()} para entrega en la ciudad de ${quoter.delivery_city}.\\n\\n${quoter.observation ? `${quoter.observation}` : ''
      }`
  }

  const handleClose = () => {
    setOpenModal({ ...openModal, show: false })
  }

  useEffect(() => {
    !openModal.show && setAllProducers(null)
  }, [openModal])

  useEffect(() => {
    clearTimeout(searchTimer)
    if (quoter.quoted_product !== '') {
      setSearchTimer(
        setTimeout(() => {
          getMatchProducers()
        }, 500)
      )
    }
  }, [quoter.quoted_product, selectedRegions, quoter.category_type_ids])

  useEffect(() => {
    getRegionsOptions()
  }, [quoter.category_type_ids])

  const handleRegionChange = (e) => {
    setSelectedRegions(e.target.value)
  }

  const handleCategoryType = (e) => {
    if (e.target.checked && !quoter.category_type_ids.some((el) => el === e.target.value)) {
      setQuoter((prevState) => {
        return { ...prevState, category_type_ids: [...prevState.category_type_ids, e.target.value] }
      })
    } else {
      setQuoter((prevState) => {
        return {
          ...prevState,
          category_type_ids: [...prevState.category_type_ids.filter((el) => el !== e.target.value)],
        }
      })
    }
  }

  return (
    <Paper className={classes.container}>
      <TransitionsModal
        openModal={openModal}
        handleClose={handleClose}
        setOpenModal={setOpenModal}
        quoter={quoter}
        setQuoter={setQuoter}
        message={createMessage()}
        producers={allProducers || producersMatch}
        setProducers={allProducers ? setAllProducers : setProducers}
        setSentInfo={setSentInfo}
      />
      <Typography variant="h1" className={classes.h1}>
        Cotizador de productos frescos
      </Typography>
      <div className={classes.containerQuoter}>
        <Grid container spacing={3}>
          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="h2" className={classes.h2}>
                Información del producto
              </Typography>
            </Grid>
            <Grid item container spacing={4} alignItems="center">
              <Grid item>
                <FormLabel>Categoría a cotizar</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={quoter.category_type_ids.some((el) => el === '1')} />}
                    value={'1'}
                    label="Frescos"
                    onChange={handleCategoryType}
                  />
                  <FormControlLabel
                    value={'2'}
                    control={<Checkbox checked={quoter.category_type_ids.some((el) => el === '2')} />}
                    label="Agroprocesados"
                    onChange={handleCategoryType}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item container spacing={4} alignItems="center">
              <Grid item sm={12} md={8}>
                <TextField
                  fullWidth
                  id="quoted_product"
                  label="Buscar producto"
                  type="search"
                  variant="outlined"
                  value={quoter.quoted_product}
                  onChange={handlerChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    quoter.quoted_product
                      ? `${totalProducers._totalFound} de ${totalProducers._totalProducers} productores coinciden con tu búsqueda`
                      : ''
                  }
                />
              </Grid>
              <Grid container item md={4} spacing={2} style={{ alignItems: 'flex-end' }}>
                <Grid item sm={12} md={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField label="Cantidad" id="amount" value={quoter.amount} onChange={handlerChange} />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="type_amount-select">Tipo</InputLabel>
                    <Select
                      labelId="type_amount-label"
                      native
                      onChange={handlerChange}
                      value={quoter.type_amount}
                      inputProps={{ id: 'type_amount' }}>
                      <option value="kilos">Kilos</option>
                      <option value="unidades">Unidades</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item sm={12} md={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <Select
                    labelId="label-region"
                    id="select-region"
                    multiple
                    value={selectedRegions}
                    onChange={handleRegionChange}
                    input={<Input id="select-multiple-region" />}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.length ? (
                          selected.map((value) => (
                            <Fragment key={value + 'region'}>
                              <Chip label={value.region} className={classes.chip} />
                            </Fragment>
                          ))
                        ) : (
                          <Chip label={'Todas las regiones'} className={classes.chip} />
                        )}
                      </div>
                    )}
                    MenuProps={MenuProps}>
                    {regionsOptions.map((region) => (
                      <MenuItem key={region.ID_direction + 'region'} value={region}>
                        {region.region}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={4}>
            <Grid container item md={6}>
              <Grid item>
                <Typography variant="h2" className={classes.h2}>
                  Frecuencia
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField label="Frecuencia" id="frequency" value={quoter.frequency} onChange={handlerChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="frecuency_type-select">Tipo de frecuencia</InputLabel>
                    <Select
                      labelId="frecuency_type-label"
                      value={quoter.frecuency_type}
                      onChange={handlerChange}
                      native
                      inputProps={{ id: 'frecuency_type' }}>
                      <option value="vez">Vez</option>
                      <option value="dia">Veces por día</option>
                      <option value="semana">Veces por Semana</option>
                      <option value="mes">Veces por Mes</option>
                      <option value="año">Veces por año</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid item>
                <Typography variant="h2" className={classes.h2}>
                  Información de despacho
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      label="Ciudad de entrega"
                      id="delivery_city"
                      onChange={handlerChange}
                      value={quoter.delivery_city}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={4}>
            <Grid item>
              <Typography variant="h2" className={classes.h2}>
                Descripción/comentario
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <FormControl className={classes.formControl} fullWidth>
                <TextareaAutosize
                  id="observation"
                  aria-label="ingresa un comentario"
                  placeholder="Ingresa comentario"
                  className={classes.textarea}
                  minRows={2}
                  value={quoter.observation}
                  onChange={handlerChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {isFull && (
          <Grid container direction="column" spacing={2} style={{ marginTop: '16px' }}>
            <Grid item>
              <Typography variant="h2" className={classes.h2}>
                Previsualización
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.message} style={{ whiteSpace: 'pre-wrap' }}>
                <span id="random">
                  Hola (Nombre de productor) 👋, un cliente de ComeS está cotizando:
                  <br />
                  <br />
                  {createMessage().replaceAll('\\n', '\n')}
                  {quoter.observation && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  ¿Tienes tú este producto o conoces a alguien que lo tenga? 😊
                </span>
                <span className={classes.metadata}>
                  <span className={classes.time}>8:30 p.m.</span>
                </span>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid className={classes.containerButtons} container spacing={4} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: 'white' }}
              disabled={!isFull || loading}
              onClick={handleSendMatchProducers}>
              Cotizar con productores de {quoter.quoted_product}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!isFull || loading}
              variant="contained"
              color="secondary"
              style={{ color: 'white' }}
              onClick={handleSendAllProducers}>
              Cotizar con todos los productores
            </Button>
          </Grid>
        </Grid>
      </div>
      {Boolean(sentInfo.not_sent.length) && Boolean(sentInfo.sent.length) && (
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2" className={classes.h2}>
              Estado de envío de la cotización
            </Typography>
          </Grid>
          <Grid item>
            <List sx={{ width: '100%', maxWidth: '360px' }}>
              {sentInfo.sent.map((el) => (
                <Fragment key={el.recipient + 'sentInfo'}>
                  <ListItem button>
                    <ListItemText primary={el.recipient} secondary="Enviado" />
                  </ListItem>
                </Fragment>
              ))}
              {sentInfo.not_sent.map((el) => (
                <Fragment key={el.recipient + 'sentInfo'}>
                  <ListItem button>
                    <ListItemText primary={el.recipient} secondary={`No enviado: ${el.error}`} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="reSend">
                        <Comment />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}
