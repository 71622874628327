import React, { useContext } from 'react'
import Amplify from 'aws-amplify'
import awsExports from '../aws-exports'
import { Authenticator, translations, useTheme, View, Image } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { I18n } from 'aws-amplify'
import '../index.css'
import { Auth } from 'aws-amplify'

I18n.putVocabularies(translations)
I18n.setLanguage('es')

Amplify.configure(awsExports)
export default function Dashboard({ children }) {
  const { tokens } = useTheme()
  return (
    <>
      <Authenticator className="login" hideSignUp={true} children={children}>
        {() => <>{children}</>}
      </Authenticator>
    </>
  )
}
