import { gql } from '@apollo/client'

const INVOICE = {
  CREATE: gql`
    mutation Mutation($payload: invoicePayload!) {
      addInvoice(payload: $payload) {
        ID_invoice
        message
        status
      }
    }
  `,
  UPDATE: gql`
    mutation UpdateInvoice($idInvoice: ID!, $payload: updInvoicePayload!) {
      updateInvoice(ID_invoice: $idInvoice, payload: $payload) {
        invoiceUpdated {
          ID_invoice
          amount
          due_date
          issue_date
          n_invoice
          payment
        }
        message
      }
    }
  `,
}
export default INVOICE
