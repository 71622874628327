import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import { FileAttached } from './FileAttached'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  apiFile: {
    background: theme.palette.secondary.main,
    position: 'relative',
    padding: '.5rem',
    color: 'white',
    gap: '.5rem',
    borderRadius: '.5rem',
  },
  fromApi: {
    background: theme.palette.primary.main,
  },
}))
export const FileAttachedDetail = ({ item: { id, details, file, fromApi }, handleDelete }) => {
  const classes = useStyles()
  return (
    <Grid container className={`${classes.apiFile} ${fromApi ? classes.fromApi : ''}`} direction="column">
      <Grid item>
        <Grid container spacing={2}>
          {Object.entries(details).map(([title, value]) =>
            title !== 'ID_type_certification' ? (
              <Grid item key={`${title}-${value}-${id}-attached-detail`}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="subtitle2">
                      <strong>{title}</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {['Fecha de resolución', 'Válido hasta'].includes(title)
                        ? new Date(value).toLocaleDateString()
                        : value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )
          )}
        </Grid>
      </Grid>
      {file ? (
        <Grid item>
          <FileAttached file={file} readOnly />
        </Grid>
      ) : (
        ''
      )}
      <Grid
        item
        style={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
        }}>
        <IconButton size="small" color="inherit" onClick={() => handleDelete(id)}>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  )
}
