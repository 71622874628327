function AppleIcon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#707070"
        fillRule="evenodd"
        d="M14.1222584,4.68222045 C15.4725648,4.68222045 16.9029698,5.42546577 17.9224981,6.71020766 C14.5825789,8.56144598 15.1252231,13.3836655 18.5,14.6731574 C18.0354768,15.7134008 17.8127331,16.1783979 17.2147121,17.0986421 C16.3803503,18.383384 15.2040857,19.9828739 13.7462394,19.9968738 C12.4508155,20.0091237 12.1183069,19.1443792 10.3600904,19.1541291 C8.60212114,19.1641291 8.23549641,20.0118737 6.93982533,19.9998738 C5.48197904,19.9861239 4.36702453,18.541633 3.53216835,17.2571411 C1.19793322,13.6649138 0.954175977,9.44944037 2.39372811,7.20895451 C3.41671741,5.61571456 5.03031122,4.68347045 6.54798433,4.68347045 C8.09309867,4.68347045 9.06441935,5.54046504 10.3417963,5.54046504 C11.5811016,5.54046504 12.335859,4.68222045 14.1222584,4.68222045 Z M13.7301702,0 C13.9091563,1.21549233 13.416203,2.40498482 12.767255,3.24697951 C12.0723243,4.14897382 10.8747989,4.84821941 9.71757008,4.81196964 C9.50644566,3.64872698 10.0490898,2.45198453 10.706196,1.64498962 C11.4300512,0.755745231 12.6700982,0.0739995331 13.7301702,0 Z"
      />
    </svg>
  )
}

export default AppleIcon
