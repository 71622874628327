import {
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  ButtonGroup,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core'
import { Clear, Group, HourglassEmpty, Person, PictureAsPdf, PostAdd, Send, WhatsApp } from '@material-ui/icons'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { useContext, useEffect, useState } from 'react'
import heart from '../../heart_comes.svg'
import TransitionsModal from '../modal/ModalContainer'
import { EnhancedTableHead } from '../orderList/EnhancedTableHead'
import { EnhancedTableToolbar } from '../orderList/EnhancedTableToolbar'
import AlertContext from '../../context/AlertContext'
import SendWsOrder from '../../utils/SendWsOrder'
import { useGetLazyProductsByIds } from '../../hooks/useGetProducts'
import { fullOrderNote } from '../../utils/fullOrderNote'

function createData(
  ID_order_note,
  dateOrder,
  numberInvoice,
  numberOrder,
  storeName,
  store_region,
  statusOrder,
  totalAmount,
  vendor,
  order
) {
  return {
    ID_order_note,
    dateOrder,
    numberInvoice,
    numberOrder,
    storeName,
    store_region,
    statusOrder,
    totalAmount,
    vendor,
    order,
  }
}

const rows = (orders) => {
  if (orders && orders.length !== 0) {
    const dataList = orders.map((order) =>
      createData(
        order.order.ID_order_note,
        new Date(+order.order.creation_date).toLocaleDateString(),
        order.order.invoice.length ? order.order.invoice[0].n_invoice : 'N/A',
        order.order.number,
        order.order.store.name,
        order.order.store_direction.street
          .concat(' ')
          .concat(order.order.store_direction.number)
          .concat(order.order.store_direction.local_or_office ? ', ' + order.order.store_direction.local_or_office : '')
          .concat(
            order.order.store_direction.municipality
              ? ', ' + order.order.store_direction.municipality
              : ', ' + order.order.store_direction.sub_region
          ),

        order.order.order_status.status,
        order.order.total_order,
        order.order.vendor ? order.order.vendor.name : 'Sin información',
        order
      )
    )
    return dataList
  }
  return []
}

function descendingComparator(a, b, field) {
  if (b[field] < a[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }
  return 0
}

function getComparator(order, field) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, field) : (a, b) => -descendingComparator(a, b, field)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  producerSelect: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    justifyContent: 'flex-start',
  },
}))

export default function EnhancedTableOrders({
  orders,
  loading,
  setOrders,
  orderNoteCount,
  setSkip,
  take,
  setTake,
  setField,
  field,
  setOrder,
  order,
  variablesInitial,
}) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [openModal, setOpenModal] = useState({ show: false, component: null })
  const [orderSelected, setOrderSelected] = useState()
  const [selectedID, setSelectedID] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [producersOrder, setProducersOrder] = useState([])
  const { requestProducstByIds } = useGetLazyProductsByIds()

  const handleRequestSort = (event, property) => {
    const isAsc = field === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setField(property)
  }

  function BootstrapTooltip(props) {
    const classes = makeStyles(() => ({
      arrow: {
        color: colorStatus(props.status, true),
      },
      tooltip: {
        backgroundColor: colorStatus(props.status, true),
        color: 'black',
      },
    }))

    return <Tooltip arrow classes={classes()} style={{ backgroundColor: 'red' }} {...props} />
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSkip(take * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setTake(parseInt(event.target.value, 10))
    setSkip(0)
    setPage(0)
  }

  const isSelected = (nameProduct) => selected.indexOf(nameProduct) !== -1

  const { setAlert } = useContext(AlertContext)

  async function handlePDF(order) {
    const response = await fetch(`${process.env.REACT_APP_API_STORE}api/v1/pdf/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer-when-downgrade',
      },
      body: JSON.stringify({ orderNote: order.order, detailOrderNotes: order.detail }),
    })
    const file = await response.blob()
    const ObjURL = URL.createObjectURL(file)
    const a = document.getElementById(`sheet-${order.order.ID_order_note}`)
    a.href = ObjURL
    a.download = `pedido_n${order.order.number}.pdf`
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  }

  const handleViewOrder = async (id, order) => {
    getOrder(id, order).then(() => setOpenModal({ show: !openModal.show, component: 'ModalOrderdetail' }))
  }

  const getOrder = async (id, order) => {
    const fullOrder = await fullOrderNote(order, requestProducstByIds)
    setSelectedID(id)
    setOrderSelected(fullOrder)
  }
  const handleClickTooltip = async (event, row) => {
    setAnchorEl(event.currentTarget)
    const fullOrder = await fullOrderNote(row.order, requestProducstByIds)
    setProducersOrder(
      await fullOrder.detail.reduce((acc, cur) => {
        return !acc.some((el) => Object.keys(el)[0] === cur.product.producer.brand_name)
          ? [
              ...acc,
              {
                [cur.product.producer.brand_name]: {
                  ...row.order,
                  detail: fullOrder.detail.filter(
                    (item) => item.product.producer.brand_name === cur.product.producer.brand_name
                  ),
                },
              },
            ]
          : [...acc]
      }, [])
    )
  }

  const handleCloseTooltip = () => {
    setProducersOrder([])
    setAnchorEl(null)
  }
  const colorStatus = (status, background = false) => {
    if (status === 'PAGADA') return '#8AC541'
    if (status === 'POR_VENCER') return '#F59323'
    if (status === 'VENCIDO') return 'red'
    if (status === 'VIGENTE' && background) return '#FBFBFB'
    if (status === 'VIGENTE' && !background) return '#3A3A3A'
  }

  return (
    <div className={classes.root}>
      <TransitionsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        orderSelected={orderSelected}
        setOrders={setOrders}
        orders={orders}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          {loading ? <LinearProgress style={{ width: '100%' }} /> : ''}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              field={field}
              onRequestSort={handleRequestSort}
              rowCount={orders ? orders.length : 0}
            />
            <TableBody>
              {orders && orders.length !== 0 ? (
                <>
                  {stableSort(rows(orders), getComparator(order, field)).map((row, index) => {
                    const isItemSelected = isSelected(row.nameProduct)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.numberOrder}-${row.nameProduct}.${index}`}>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.dateOrder}
                        </TableCell>
                        <TableCell align="center" padding="none">
                          {row.numberInvoice === 'N/A' ? (
                            row.statusOrder === 'Cancelado' ? (
                              <Tooltip title="Cancelado">
                                <Clear />
                              </Tooltip>
                            ) : row.statusOrder === 'Borrador' ? (
                              ''
                            ) : (
                              <Tooltip title="Ingresar factura">
                                <IconButton
                                  aria-label="Ingresar factura"
                                  onClick={() => {
                                    setSelectedID(row.ID_order_note)
                                    setOrderSelected(row.order)
                                    setOpenModal({
                                      show: !openModal.show,
                                      component: 'ModalInvoiceEntry',
                                      data: variablesInitial,
                                    })
                                  }}>
                                  <PostAdd />
                                </IconButton>
                              </Tooltip>
                            )
                          ) : (
                            <BootstrapTooltip
                              title={`${row.order.order.invoice[0].status}`}
                              placement="top"
                              status={row.order.order.invoice[0].status}>
                              <Button
                                onClick={() => {
                                  setSelectedID(row.ID_order_note)
                                  setOrderSelected(row.order)
                                  setOpenModal({
                                    show: !openModal.show,
                                    component: 'ModalInvoiceEntry',
                                    data: variablesInitial,
                                  })
                                }}
                                style={{ fontWeight: '700', color: colorStatus(row.order.order.invoice[0].status) }}>
                                {row.numberInvoice}
                              </Button>
                            </BootstrapTooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.35rem' }}>
                            {row.order.order.order_note_producer?.ID_order_note_producer ? (
                              <Tooltip title="Productor">
                                <Person />
                              </Tooltip>
                            ) : (
                              <Tooltip title="ComeS">
                                <img src={heart} width={'20px'} />
                              </Tooltip>
                            )}
                            {/* {row.order.order.order_note_producer?.ID_order_note_producer ? 'P - ' : ''} */}
                            {row.numberOrder}
                          </div>
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Tooltip title="Ver detalle">
                            <IconButton
                              aria-label="Ver detalle"
                              onClick={() => {
                                handleViewOrder(row.ID_order_note, row.order)
                              }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">{row.storeName}</TableCell>
                        <TableCell align="center">{row.store_region}</TableCell>

                        <TableCell align="center">{row.statusOrder}</TableCell>
                        <TableCell align="right">
                          {'$' + new Intl.NumberFormat('de-DE').format(row.totalAmount)}
                        </TableCell>
                        <TableCell align="center"> {row.vendor}</TableCell>
                        <TableCell align="center" padding="none">
                          <ButtonGroup style={{ marginRight: '10px' }}>
                            <Tooltip
                              style={{ borderRadius: '15px' }}
                              data-id={row.ID_order_note}
                              title="Enviar WhatsApp a productores"
                              disabled={!(row.statusOrder === 'Recibido' || row.statusOrder === 'En preparación')}
                              aria-controls={`simple-menu-${row.ID_order_note}`}
                              aria-haspopup="true"
                              color="secondary"
                              onClick={(e) => handleClickTooltip(e, row)}>
                              <IconButton aria-label="Enviar WhatsApp a productores">
                                <WhatsApp />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              id={`simple-menu-${row.ID_order_note}`}
                              anchorEl={anchorEl}
                              keepMounted
                              open={anchorEl?.dataset.id === row.ID_order_note}
                              onClose={handleCloseTooltip}>
                              {producersOrder.length ? (
                                producersOrder.map((order) => (
                                  <MenuItem
                                    key={Object.keys(order)[0]}
                                    onClick={() =>
                                      SendWsOrder(order[Object.keys(order)]).then((resolve) => {
                                        setAlert({
                                          open: true,
                                          message: resolve[0].message,
                                          severity: resolve[0].type,
                                        })
                                      })
                                    }>
                                    <div className={classes.producerSelect}>
                                      <Person /> {Object.keys(order)}
                                    </div>
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <div className={classes.producerSelect}>
                                    <HourglassEmpty /> <span> ...cargando productores </span>
                                  </div>
                                </MenuItem>
                              )}
                              <MenuItem
                                style={{ borderTop: '1px solid #ABAAB0', marginTop: '8px' }}
                                onClick={async () => {
                                  const fullOrder = await fullOrderNote(row.order, requestProducstByIds)
                                  SendWsOrder(fullOrder).then((resolve) => {
                                    if (resolve.some((item) => item.type === 'error')) {
                                      setAlert({
                                        open: true,
                                        message: resolve
                                          .filter((item) => item.type === 'error')
                                          .map((item) => item.message)
                                          .toLocaleString()
                                          .replace(',', '\n'),
                                        severity: resolve.filter((item) => item.type === 'error')[0].type,
                                      })
                                    } else {
                                      setAlert({
                                        open: true,
                                        message: 'Mensaje enviado a los productores',
                                        severity: resolve[0].type,
                                      })
                                    }
                                  })
                                }}>
                                <div className={classes.producerSelect}>
                                  <Group /> <span> Todos los productores </span>
                                </div>
                              </MenuItem>
                            </Menu>

                            <Tooltip
                              style={{ borderRadius: '15px' }}
                              data-id={row.ID_order_note}
                              title="Descargar PDF"
                              onClick={() => handlePDF(row.order)}>
                              <IconButton aria-label="Descargar PDF">
                                <PictureAsPdf />
                              </IconButton>
                            </Tooltip>
                            <a id={`sheet-${row.ID_order_note}`} className="anchor-btn"></a>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <h3>{loading ? 'Cargando...' : 'Aún no se han ingresado pedidos'}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {take && (
          <TablePagination
            rowsPerPageOptions={[20, 40, 60]}
            component="div"
            count={orderNoteCount}
            rowsPerPage={take}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  )
}
