import { useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetCategories() {
  const { data, error, loading } = useQuery(QUERY.CATEGORIES.PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { categories: data?.categoriesWithProducts.categories, error, loading }
}
