import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useContext } from 'react'
import AlertContext from '../../context/AlertContext'
import sendWsQuote from '../../utils/sendWsQuote'
import { useMutation } from '@apollo/client'
import { QUERY } from '../../services/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
    maxWidth: '500px',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
    color: 'white',
  },
  message: {
    color: '#000',
    clear: 'both',
    lineHeight: '18px',
    fontSize: '15px',
    padding: '8px',
    position: 'relative',
    margin: '8px 0',
    maxWidth: '85%',
    wordWrap: 'break-word',
    background: '#e1ffc7',
    borderRadius: '5px 0px 5px 5px',
    float: 'right',
    filter: 'drop-shadow(0px 4px 2px #00000033)',
    '&:after': {
      position: 'absolute',
      content: '""',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0px 0 10px 10px',
      borderColor: 'transparent transparent transparent #e1ffc7',
      top: 0,
      right: '-10px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  metadata: {
    display: 'inline-block',
    float: 'right',
    padding: '0 0 0 7px',
    position: 'relative',
    bottom: '-4px',
  },
  time: {
    color: 'rgba(0, 0, 0, .45)',
    fontSize: '11px',
    display: 'inline-block',
  },
  producer: {
    padding: '0.5rem',
    backgroundColor: '#8ac541',
    borderRadius: '1rem',
    color: 'white',
  },
  producerContainer: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#8ac541',
      borderRadius: '0.5rem',
    },
  },
}))

export default function ModalSendQuote({
  handleClose,
  message,
  producers,
  quoter,
  setQuoter,
  setProducers,
  setSentInfo,
}) {
  const classes = useStyles()

  const { setAlert } = useContext(AlertContext)
  const [addQuote] = useMutation(QUERY.QUOTER.ADD, { context: { clientName: 'apiProducer' } })

  function generatePayload(sentStatusArray) {
    return {
      payload: {
        ...quoter,
        amount: Number(quoter.amount),
        frequency: Number(quoter.frequency),
        producer_quote: sentStatusArray,
      },
    }
  }

  function handleConfirm() {
    sendWsQuote(producers, message)
      .then((responses) => Promise.all(responses.map((res) => res.value.json())))
      .then((resJSON) => {
        const producerSentStatus = resJSON.map((res) => {
          return {
            status: res.sent ? 'SENT' : 'ERROR',
            ID_producer: producers.find((producer) => producer.contact[0].phone === `+${res.logs[0].recipient}`)
              .ID_producer,
          }
        })
        setSentInfo(
          resJSON.reduce(
            (acc, cur) => {
              cur.sent
                ? acc.sent.push({
                    ...cur.logs[0],
                    recipient: producers.find((producer) => producer.contact[0].phone === `+${cur.logs[0].recipient}`)
                      .brand_name,
                  })
                : acc.not_sent.push({
                    ...cur.logs[0],
                    recipient: producers.find((producer) => producer.contact[0].phone === `+${cur.logs[0].recipient}`)
                      .brand_name,
                  })
              return acc
            },
            { sent: [], not_sent: [] }
          )
        )
        const notSent = resJSON.filter((res) => res.not_sent)
        if (notSent.length) {
          const notSentNumbers = notSent.map((notSentNumber) => `+${notSentNumber.logs[0].recipient}`)
          const notSentProducers = notSentNumbers
            .map((number) => producers.find((producer) => producer.contact[0].phone === number).brand_name)
            .join(', ')
          setAlert({
            open: true,
            severity: 'success',
            message: `Mensaje enviado a los productores, excepto a ${notSentProducers}`,
          })
        } else {
          setAlert({
            open: true,
            severity: 'success',
            message: `Mensaje enviado a los productores`,
          })
        }

        const payload = generatePayload(producerSentStatus)
        return addQuote({ variables: payload })
      })
      .then((res) =>
        setTimeout(
          () =>
            setAlert({ open: true, severity: 'success', message: 'Se ha guardado la cotización en la Base de datos.' }),
          3000
        )
      )
    setQuoter({
      quoted_product: '',
      amount: '1',
      type_amount: 'kilos',
      frequency: '1',
      frecuency_type: 'dia',
      delivery_city: '',
      observation: '',
      category_type_ids: [],
    })
    handleClose()
  }
  const handleRemove = (idProducer) => {
    const currentProducers = producers.filter((producer) => producer.ID_producer !== idProducer)
    setProducers(currentProducers)
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="column" alignContent="center" style={{ textAlign: 'center' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Estás a punto de envíar una cotización a
          </Typography>
          <Typography id="modal-modal-title" variant="subtitle2" component="h6">
            <strong>{producers.length}</strong> productores.
          </Typography>
        </Grid>
        <Grid item container wrap="nowrap" className={classes.producerContainer}>
          {producers.map((producer) => (
            <Grid key={producer.ID_producer} item style={{ margin: '0 4px' }}>
              <span
                className={classes.producer}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: 'max-content' }}>
                {producer.brand_name}
                <Close
                  fontSize="small"
                  onClick={() => handleRemove(producer.ID_producer)}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </Grid>
          ))}
        </Grid>
        <Grid container item alignItems="center">
          <Grid item container direction="column" spacing={2} style={{ marginTop: '16px' }}>
            <Grid item>
              <Typography variant="subtitle1">Previsualización:</Typography>
            </Grid>
            <Grid item>
              <div className={classes.message} style={{ whiteSpace: 'pre-wrap' }}>
                <span id="random">
                  Hola (Nombre de productor) 👋, un cliente de ComeS está cotizando:
                  <br />
                  <br />
                  {message.replaceAll('\\n', '\n')}
                  <br />
                  <br />
                  ¿Tienes tú este producto o conoces a alguien que lo tenga? 😊
                </span>
                <span className={classes.metadata}>
                  <span className={classes.time}>8:30 p.m.</span>
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item>
            <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              onClick={handleConfirm}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
