import { Button, CircularProgress, Divider, Grid, List, makeStyles, NativeSelect } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import { formatRut } from '../../utils/formatRut'
import priceFormat from '../../utils/priceFormat'
import EditIcon from '@material-ui/icons/Edit'
import { withStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import Loader from '../Loader'
import icon_calendar from '../../icon_calendar.png'
import icon_credit_card from '../../icon_credit_card.png'
import icon_checklist from '../../icon_checklist.png'
import icon_shipping from '../../icon_shipping.png'
import icon_note from '../../icon_note.png'
import { Clear, Save } from '@material-ui/icons'
import UserContext from '../../context/UserContext'
import SendWsOrder from '../../utils/SendWsOrder'
import { totalPriceByProducer } from '../../utils/totalOrderByProducer'
import sendEmailOrder from '../../utils/sendEmailOrder'
import AlertContext from '../../context/AlertContext'
import sendWsStore from '../../utils/sendWsStore'
import sendWsSurveyStore from '../../utils/sendWsSurveyStore'
import { groupIdShippingSummary } from '../../utils/groupIdShippingSummary'
import { useGetOrderStatus } from '../../hooks/useGetOrderStatus'
import { useGetShippingStatus } from '../../hooks/useGetShippingStatus'
import { useGetLazyOrdersCompletedByStore } from '../../hooks/useGetNotesCompletedStore'
import { usePostUpdateStatus } from '../../hooks/usePostUpdateStatus'
import { useGetLazyOrderNoteByProducer } from '../../hooks/useGetOrderNotes'
import { useGetLazyProductsByIds } from '../../hooks/useGetProducts'
import { fullOrderNote } from '../../utils/fullOrderNote'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '60vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5rem 4rem',
  },
  titleOrderDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '90%',
  },
  info: {
    padding: '0 4rem',
  },
  infoBilling: {
    padding: '0rem 4rem',
    width: '100%',
  },
  infoBillingStore: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  infoBillingDirection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tittle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  divider: {
    margin: '1rem 0',
  },
  infoProducts: {
    margin: '0.5rem 4rem',
  },
  thName: {
    textAlign: 'left',
    border: '1px solid #DCDCDC',
    padding: '0.5rem 0.725rem 0.5rem 1rem ',
  },
  tdName: {
    textAlign: 'left',
    padding: '0.5rem 1rem 0.5rem 1rem ',
    minWidth: '18rem',
    border: '1px solid #DCDCDC',
  },
  thNumber: {
    textAlign: 'center',
    padding: '0.5rem 0rem',
    paddingRight: '1.5rem',
    border: '1px solid #DCDCDC',
    padding: '0.5rem 1rem 0.5rem 1rem ',
  },
  tdNumber: {
    textAlign: 'right',
    padding: '0rem 1rem',
    border: '1px solid #DCDCDC',
  },
  button: {
    margin: theme.spacing(1),
    height: '2.5rem',
  },
  totalAmountContainer: {
    width: '100%',
    textAlign: '-webkit-right',
    paddingRight: '4rem',
    marginTop: '2rem',
  },
  tdTotal: {
    textAlign: 'right',
    padding: '0.5rem 1rem 0.5rem 4rem',
    border: '1px solid #DCDCDC',
  },
  tdTotalTitle: {
    textAlign: 'center',
    padding: '0.5rem 4rem',
    border: '1px solid #DCDCDC',
  },
  iconContainer: {
    background: '#bdbdbd',
    padding: '0.5rem',
    borderRadius: '25px',
  },
  iconImg: {
    verticalAlign: 'middle',
  },
  nameStore: {
    marginLeft: '0.25rem',
    marginTop: '0',
  },
}))
const EditButton = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
})(Button)

export default function ModalOrderDetail({ orderSelected, toPDF, orders, setOrders, openModal }) {
  const [order, setOrder] = useState({ ...orderSelected, order: orderSelected.orderNote })
  const [editStatus, setEditStatus] = useState(false)
  const [optionsStatus, setOptionsStatus] = useState()
  const [shippingStatusProducer, setShippingStatusProducer] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useContext(UserContext)
  const [selectedStatus, setSelectedStatus] = useState(order.order.order_status.ID_order_status)
  const classes = useStyles()
  const { setAlert } = useContext(AlertContext)
  const { data: dataStatus, error: errorStatus, loading: loadingStatus } = useGetOrderStatus()
  const {
    data: dataShipping,
    error: errorShipping,
    loading: loadingShipping,
  } = useGetShippingStatus(order.order.ID_order_note)
  const { requestOrdersCompletedByStore } = useGetLazyOrdersCompletedByStore()
  const { updateStatusOrder } = usePostUpdateStatus()
  const { requestOrderNoteByProducer } = useGetLazyOrderNoteByProducer()
  const { requestProducstByIds } = useGetLazyProductsByIds()
  //Datos tienda
  const nameStore = order.order.store.name
  const bussinesName = order.order.store.bussines_name
  const rut = order.order.store.rut
  const phoneStore = order.order.store.phone
  const email = order.order.store.email
  // direccion tienda
  const street = order.order.store_direction.street
  const number = order.order.store_direction.number
  const local_or_office = order.order.store_direction.local_or_office
    ? ', ' + order.order.store_direction.local_or_office
    : ''
  const commune = order.order.store_direction.municipality
    ? order.order.store_direction.municipality
    : order.order.store_direction.sub_region
    ? order.order.store_direction.sub_region
    : ''
  const city = order.order.store_direction.country
  const region = order.order.store_direction.region
  // productos del pedido
  const products = order.detail

  const totalDsc = (percent) => {
    const total = order.order.total
    return Math.round(total * (percent * 0.01))
  }

  const modificationField = (dateCreation, dateModification) => {
    if (dateCreation === dateModification) {
      return 'Pedido sin modificar'
    } else {
      return `${new Date(+dateModification).toLocaleDateString()} por ${
        order.order.modificated_by ? order.order.modificated_by.name : ''
      }`
    }
  }
  const createdField = (dateCreation) => {
    return `${new Date(+dateCreation).toLocaleDateString()} ${
      order.order.created_by ? ' por ' + order.order.created_by.name : ''
    }`
  }
  useEffect(() => {
    if (dataStatus) {
      setOptionsStatus(dataStatus.ordersStatus.orderStatus)
    }
    if (dataShipping) {
      setShippingStatusProducer(groupIdShippingSummary(dataShipping.shippingStatus.shipping))
    }
  }, [dataStatus, dataShipping])

  // id del estado actual del pedido
  const currentStatus = order.order.order_status.ID_order_status

  const handleSaveStatus = async (ID_order_note) => {
    setLoading(true)
    if (currentStatus === selectedStatus) {
      setLoading(false)
      setEditStatus(!editStatus)
    } else {
      try {
        // peticion de pedidos completados del cliente
        const {
          data: { orderCompletedCountByIdStore },
        } = await requestOrdersCompletedByStore({
          variables: {
            idStore: order.order.store.ID_store,
          },
        })
        const {
          data: { orderStatusChange },
        } = await updateStatusOrder({
          variables: {
            idOrderStatus: selectedStatus,
            idOrderNote: ID_order_note,
            modificatedBy: user?.userApi?.ID_user,
          },
        })
        if (orderStatusChange?.currentStatus) {
          setEditStatus(!editStatus)
          setOrder({
            ...order,
            order: {
              ...order.order,
              order_status: orderStatusChange.currentStatus,
              number: orderStatusChange.orderNote.number,
              order_note_producer: orderStatusChange.orderNote.order_note_producer,
            },
          })
          // Actualiza el estado del pedido y el numero en caso de que venga
          const orderFound = orders.find((item) => item.order.ID_order_note == order.order.ID_order_note)
          const updatedOrder = {
            ...orderFound,
            order: {
              ...orderFound.order,
              number: orderStatusChange.orderNote.number,
              order_status: orderStatusChange.currentStatus,
              order_note_producer: orderStatusChange.orderNote.order_note_producer,
            },
          }
          const orderIndex = orders.findIndex((item) => item.order.ID_order_note == order.order.ID_order_note)
          const newOrders = [...orders]
          newOrders[orderIndex] = updatedOrder
          setOrders(newOrders)

          if (orderStatusChange.currentStatus.ID_order_status == '1') {
            if (orderCompletedCountByIdStore._count === 0) {
              try {
                await fetch(process.env.REACT_APP_EMAIL_PAYMENT_CONFIRMATION, {
                  method: 'POST',
                  body: JSON.stringify({
                    environment: process.env.REACT_APP_ENV,
                    storeEmail: order.order.store.email,
                    storeName: order.order.store.name,
                    orderDate: order.order.creation_date,
                    orderNumber: orderStatusChange.orderNote.number,
                    orderTotal: order.order.total_order,
                  }),
                })
              } catch (error) {
                console.error(error)
              }
            }
            SendWsOrder({ ...order, order: { ...order.order, number: orderStatusChange.orderNote.number } }).then(
              (resolve) => {
                if (resolve.some((item) => item.type === 'error')) {
                  setAlert({
                    open: true,
                    message: resolve
                      .filter((item) => item.type === 'error')
                      .map((item) => item.message)
                      .toLocaleString()
                      .replace(',', '\n'),
                    severity: resolve.filter((item) => item.type === 'error')[0].type,
                  })
                } else {
                  setAlert({
                    open: true,
                    message: 'Mensaje enviado a productores ',
                    severity: resolve[0].type,
                  })
                }
              }
            )
          } else if (orderStatusChange.currentStatus.ID_order_status == '2') {
            // status 2 = en preparacion
            // obtenemos los ID de todos los productores para luego traer los pedidos separados por productor, cambiar los totales por los costos y finalmente enviar el correo al productor
            const idProducers = Array.from(new Set(order.detail.map((product) => product.product.producer.ID_producer)))
            const sends = idProducers.map(async (idProducer) => {
              const result = await getOrderByProducer(order.order.ID_order_note, idProducer)
              return result
            })
            Promise.all(sends).then((results) => {
              const error = results.some((status) => status !== 200)
              if (error) {
                setAlert({
                  open: true,
                  message: 'Ocurrió un error al enviar las notas del pedido a productores',
                  severity: 'error',
                })
              } else {
                setAlert({
                  open: true,
                  message:
                    results.length == 1
                      ? 'Nota de pedido enviada al productor'
                      : 'Notas de pedidos enviadas a los productores',
                  severity: 'success',
                })
              }
            })
            //Envío whatsapp de confirmación del pedido a tienda
            await sendWsStore(order)
          } else if (orderStatusChange.currentStatus.ID_order_status == '3') {
            if (orderCompletedCountByIdStore._count === 0) {
              sendWsSurveyStore(nameStore, phoneStore).then((data) => {
                if (data.sent === 1) {
                  setAlert({
                    open: true,
                    message: `Encuesta enviada a cliente ${nameStore}`,
                    severity: 'success',
                  })
                } else if (data.not_sent === 1) {
                  setAlert({
                    open: true,
                    message: `Falló el envío de encuesta al cliente: ${nameStore} - Motivo: ` + data.logs[0].error,
                    severity: 'error',
                  })
                }
              })
            }
          }
        } else {
          setAlert({
            open: true,
            message: orderStatusChange?.message,
            severity: 'error',
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getOrderByProducer = async (idOrder, idProducer) => {
    try {
      const {
        data: { orderNoteById },
      } = await requestOrderNoteByProducer({
        variables: {
          idProducer: idProducer,
          idOrderNote: idOrder,
        },
      })
      const fullOrder = await fullOrderNote(orderNoteById, requestProducstByIds)
      if (fullOrder) {
        const newOrder = await totalPriceByProducer(fullOrder)
        const { status } = await sendEmailOrder({ orderNote: newOrder.orderNote, detailOrderNotes: newOrder.detail })
        return status
      }
    } catch (error) {
      console.error(error)
    }
  }

  const disabledStatus = (option) => {
    if (currentStatus == 0) {
      const disabled = ['0', '2', '3', '5']
      return disabled.some((item) => item === option.ID_order_status)
    } else if (currentStatus == 3) {
      return option.ID_order_status != 1
    } else if (currentStatus == 5) {
      return option.ID_order_status < 3
    } else {
      return option.ID_order_status < currentStatus || option.ID_order_status == 3 || option.ID_order_status == 5
    }
  }

  function SortProducts(x, y) {
    if (x.product.producer.brand_name < y.product.producer.brand_name) {
      return -1
    }
    if (x.product.producer.brand_name > y.product.producer.brand_name) {
      return 1
    }
    return 0
  }

  return (
    <>
      {toPDF && <Loader text="Generando PDF" size="small" />}
      <div className={classes.titleOrderDetail}>
        <h1 style={{ margin: '0 7rem 0rem 0' }}>Pedido N° {order.order.number} </h1>
        {!openModal.viewProducer &&
          (editStatus ? (
            <>
              <EditButton
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<Clear />}
                onClick={() => {
                  setEditStatus(!editStatus)
                  setSelectedStatus(currentStatus)
                }}>
                Cancelar
              </EditButton>
              <EditButton
                className={classes.button}
                variant="contained"
                color="secondary"
                startIcon={loading ? <CircularProgress color="white" size={18} /> : <Save />}
                onClick={() => handleSaveStatus(order.order.ID_order_note)}>
                Guardar
              </EditButton>
            </>
          ) : (
            <EditButton
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              disabled={currentStatus == 4}
              onClick={() => setEditStatus(!editStatus)}>
              Editar estado
            </EditButton>
          ))}
      </div>
      <h3 className={classes.nameStore}>Tienda: {nameStore}</h3>
      <List className={classes.root} id="to-print">
        <ListItem className={classes.container}>
          <div className={classes.tittle}>
            <div className={classes.iconContainer}>
              <img className={classes.iconImg} src={icon_calendar}></img>
            </div>
            <h3 style={{ marginLeft: '1rem' }}>Datos de pedido</h3>
          </div>
          <div className={classes.infoContainer}>
            <Grid container>
              <Grid container item spacing={7} justifyContent="space-between">
                <Grid item sm={3} xs={12}>
                  <h3>Estado del pedido:</h3>
                  {editStatus ? (
                    <NativeSelect
                      value={selectedStatus}
                      name="status"
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      inputProps={{ 'aria-label': 'Estado del pedido' }}>
                      <>
                        {optionsStatus.map((option) =>
                          currentStatus == 0 ? (
                            <option
                              disabled={disabledStatus(option)}
                              value={option.ID_order_status}
                              key={option.ID_order_status}>
                              {option.status}
                            </option>
                          ) : (
                            option.ID_order_status != 0 && (
                              <>
                                <option
                                  disabled={disabledStatus(option)}
                                  value={option.ID_order_status}
                                  key={option.ID_order_status}>
                                  {option.status}
                                </option>
                              </>
                            )
                          )
                        )}
                      </>
                    </NativeSelect>
                  ) : (
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{order.order.order_status.status}</p>
                  )}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <h3>Vendedor:</h3>
                  <p>{order.order.vendor ? order.order.vendor.name : 'Sin información'}</p>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <h3>Fecha de creación:</h3> {createdField(order.order.creation_date)}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <h3>Fecha de modificación:</h3>{' '}
                  {modificationField(order.order.creation_date, order.order.modification_date)}
                </Grid>
              </Grid>
              <Grid container item spacing={7} justifyContent="flex-start">
                <Grid item sm={3} xs={12}>
                  <h3>Tipo de pedido:</h3> {order.order?.order_note_producer?.ID_producer ? 'Productor' : 'ComeS'}
                </Grid>
                {Boolean(order.order?.order_note_producer?.ID_producer) && (
                  <>
                    <Grid item sm={3} xs={12}>
                      <h3>Productor:</h3> {order.order?.order_note_producer?.producer_brand_name}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <h3>N° pedido productor:</h3> {order.order?.order_note_producer?.number}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div>
            <Divider className={classes.divider} orientation="horizontal" />
            <div className={classes.tittle}>
              <div className={classes.iconContainer}>
                <img className={classes.iconImg} src={icon_credit_card}></img>
              </div>
              <h3 style={{ marginLeft: '1rem' }}>Datos de facturación </h3>
            </div>

            <div className={classes.infoBilling}>
              <Grid container item spacing={2} justifyContent="space-between">
                <Grid item sm={4} xs={12}>
                  <h3>Razón social:</h3> <p>{bussinesName}</p>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <h3>Rut :</h3> <p>{formatRut(rut)}</p>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <h3>Email:</h3> <p>{email ? email : 'No especificado'}</p>
                </Grid>
              </Grid>
              <Grid container item spacing={2} justifyContent="space-between">
                <Grid item sm={4} xs={12}>
                  <h3>Teléfono:</h3>
                  <p>{phoneStore ? `${phoneStore}` : 'No especificado'}</p>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <h3>Dirección:</h3>
                  <p>
                    {street} #{number}
                    {local_or_office}
                  </p>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <h3>Ciudad/Región/País:</h3>
                  <p>
                    {commune && `${commune}, `} {region && `${region}, `} {city && `${city}`}
                  </p>
                </Grid>
              </Grid>
            </div>
            <Divider className={classes.divider} orientation="horizontal" />
            <div className={classes.tittle}>
              <div className={classes.iconContainer}>
                <img className={classes.iconImg} src={icon_checklist}></img>
              </div>
              <h3 style={{ marginLeft: '1rem' }}>Productos</h3>
            </div>
            <table className={classes.infoProducts} style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr className={classes.containerTitleColumn}>
                  <th className={classes.thName}>Productor</th>
                  <th className={classes.thName}>Producto</th>
                  <th className={classes.thNumber}>Formato</th>
                  <th className={classes.thNumber}>Cantidad</th>
                  <th className={classes.thNumber}>{openModal.viewProducer ? 'Costo neto' : 'Precio neto'}</th>
                  <th className={classes.thNumber}>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {products.sort(SortProducts).map((product) => (
                  <tr key={product.ID_product} className={classes.infoProducts}>
                    <td className={classes.tdName}>{product.product.producer.brand_name} </td>
                    <td className={classes.tdName}>{product.product.name}</td>
                    <td className={classes.tdNumber}> {product.product.sale_format} un.</td>
                    <td className={classes.tdNumber}>{product.qty}</td>
                    <td className={classes.tdNumber}>
                      {priceFormat(
                        openModal.viewProducer ? product.net_historical_cost / product.qty : product.unit_price
                      )}
                    </td>
                    <td className={classes.tdNumber}>
                      {priceFormat(openModal.viewProducer ? product.net_historical_cost : product.sub_total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={classes.totalAmountContainer}>
              <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  <tr className={classes.infoProducts}>
                    <th className={classes.tdTotalTitle}>Neto </th>
                    <td className={classes.tdTotal}> {priceFormat(order.order.neto)} </td>
                  </tr>
                  <tr className={classes.infoProducts}>
                    <th className={classes.tdTotalTitle}>IVA </th>
                    <td className={classes.tdTotal}> {priceFormat(order.order.iva)} </td>
                  </tr>
                  <tr className={classes.infoProducts}>
                    <th className={classes.tdTotalTitle}>
                      {' '}
                      {order.order.discount && !openModal.viewProducer ? 'Subtotal' : 'Total'}{' '}
                    </th>
                    <td className={classes.tdTotal} style={{ fontWeight: 'bold' }}>
                      {' '}
                      {priceFormat(order.order.total)}{' '}
                    </td>
                  </tr>
                  {!openModal.viewProducer && (
                    <>
                      <tr className={classes.infoProducts}>
                        <th className={classes.tdTotalTitle}> Descuento </th>
                        <td className={classes.tdTotal} style={{ fontWeight: 'bold' }}>
                          -{' '}
                          {order.order.type_discount === 'percent'
                            ? priceFormat(totalDsc(order.order.discount))
                            : priceFormat(order.order.discount)}
                        </td>
                      </tr>
                      <tr className={classes.infoProducts}>
                        <th className={classes.tdTotalTitle}> Despacho </th>
                        <td className={classes.tdTotal} style={{ fontWeight: 'bold' }}>
                          {' '}
                          {priceFormat(order.order.shipping)}{' '}
                        </td>
                      </tr>
                      <tr className={classes.infoProducts}>
                        <th className={classes.tdTotalTitle}> Total </th>
                        <td className={classes.tdTotal} style={{ fontWeight: 'bold' }}>
                          {' '}
                          {priceFormat(order.order.total_order)}{' '}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!openModal.viewProducer && (
              <>
                <Divider className={classes.divider} orientation="horizontal" style={{ marginTop: '3rem' }} />
                <table style={{ borderCollapse: 'collapse' }}>
                  <thead>
                    <tr className={classes.tittle}>
                      <th className={classes.iconContainer}>
                        <img className={classes.iconImg} src={icon_note}></img>
                      </th>
                      <td>
                        <h3 style={{ marginLeft: '1rem' }}>Nota de pedido</h3>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.infoContainer}>
                      <td>{order.order.comment_by_order ? order.order.comment_by_order : 'No hay nota de pedido'}</td>
                    </tr>
                  </tbody>
                </table>
                <Divider className={classes.divider} orientation="horizontal" />
                <div className={classes.tittle}>
                  <div className={classes.iconContainer}>
                    <img className={classes.iconImg} src={icon_shipping}></img>
                  </div>
                  <h3 style={{ marginLeft: '1rem' }}>Datos de envío</h3>
                </div>
                {shippingStatusProducer.length ? (
                  <table className={classes.infoProducts} style={{ borderCollapse: 'collapse', width: '90%' }}>
                    <thead>
                      <tr className={classes.containerTitleColumn}>
                        <th className={classes.thName}>Productor</th>
                        <th className={classes.thName}>Estado</th>
                        <th className={classes.thName}>Fecha</th>
                        <th className={classes.thName}>Courier</th>
                        <th className={classes.thNumber}>N° de seguimiento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shippingStatusProducer.map((shipping) => (
                        <tr key={shipping.ID_shipping_status} className={classes.infoProducts}>
                          <td className={classes.tdName}>{shipping.producer.brand_name}</td>
                          <td className={classes.tdNumber}>{shipping.status}</td>
                          <td className={classes.tdNumber}>{new Date(+shipping.created_at).toLocaleDateString()}</td>
                          <td className={classes.tdNumber}>{shipping.courier.name}</td>
                          <td className={classes.tdNumber}>{shipping.tracking_number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <span style={{ color: '#777', fontSize: '20px', fontWeight: 'bold', display: 'block' }}>
                        Aún no existen datos de envío
                      </span>
                      <span style={{ color: '#777', fontSize: '14px', textAlign: 'center', display: 'block' }}>
                        Ningún productor ha realizado un envío
                      </span>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </div>
        </ListItem>
      </List>
    </>
  )
}
