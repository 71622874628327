import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Button, ButtonGroup, Grid, TextField, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { AddCircle } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  btnDelete: {
    cursor: 'pointer',
  },
  productDetail: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
  },
  containerNotProduct: {
    width: 'auto',
    padding: '1%',
    width: '85%',
    position: 'absolute',
    textAlign: 'center',
    color: '#C0C0C0',
    margin: '2px',
    borderBottom: 'none',
  },
  inputNumber: {
    width: '22%',
    minWidth: '80px',
  },
})

function createData(ID_product, name, producer, format, price, wholesale_unit_price, product) {
  return {
    ID_product,
    name,
    producer,
    format,
    price,
    wholesale_unit_price,
    product,
  }
}

function Row(props) {
  const { row, openModal, setOpenModal, productSelected, setRowSelected, setProductSelected } = props
  const [open, setOpen] = useState(false)
  const classes = useRowStyles()
  const DeleteProductOrder = () => {
    setOpenModal({ show: !openModal.show, component: 'ModalDeleteProductOrder' })
    setRowSelected(row)
  }
  const netoProduct = row.product.net_price_package.toFixed(2)

  const editPriceProduct = (newPrice) => {
    const formatPrice = newPrice.replaceAll('.', '').replaceAll(',', '.')
    const completeProduct = productSelected.map((item) =>
      item.ID_product === row.ID_product ? { ...item, net_price_package: +formatPrice } : item
    )
    setProductSelected(completeProduct)
  }

  const decreaseProduct = () => {
    const completeProduct = productSelected.map((item) =>
      item.ID_product === row.ID_product ? { ...item, qty: row.product.qty - 1 } : item
    )
    setProductSelected(completeProduct)
  }
  const increaseProduct = () => {
    const completeProduct = productSelected.map((item) =>
      item.ID_product === row.ID_product ? { ...item, qty: row.product.qty + 1 } : item
    )
    setProductSelected(completeProduct)
  }

  const editQtyProduct = (e) => {
    const newQty = e.target.value <= 0 ? (e.type === 'blur' ? 1 : '') : Number(e.target.value)
    const completeProduct = productSelected.map((item) =>
      item.ID_product === row.ID_product ? { ...item, qty: newQty } : item
    )
    setProductSelected(completeProduct)
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.producer}</TableCell>
        <TableCell align="center">
          <ButtonGroup style={{ justifyContent: 'center' }}>
            <Button aria-label="reduce" onClick={() => decreaseProduct()} disabled={row.product.qty === 1}>
              <RemoveIcon fontSize="small" />
            </Button>
            <TextField
              id={`qty-product-${row.ID_product}`}
              type="number"
              className={classes.inputNumber}
              size={'small'}
              variant="outlined"
              value={row.product.qty}
              onChange={(e) => editQtyProduct(e)}
              onBlur={(e) => editQtyProduct(e)}
            />

            <Button aria-label="increase" onClick={() => increaseProduct()}>
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </TableCell>
        <TableCell align="right">{row.format} un.</TableCell>
        <TableCell
          align="right"
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: 'none' }}>
          <span>$</span>
          <TableCell
            align="right"
            style={{ position: 'relative', border: 'none' }}
            suppressContentEditableWarning={true}
            contentEditable
            onBlur={(e) => {
              editPriceProduct(e.target.textContent)
            }}>
            {new Intl.NumberFormat('de-DE').format(netoProduct)}
          </TableCell>
        </TableCell>
        <TableCell align="right">
          {'$' + new Intl.NumberFormat('de-DE').format(netoProduct * row.product.qty)}
        </TableCell>
        <TableCell align="right">
          <DeleteIcon
            className={classes.btnDelete}
            onClick={() => {
              DeleteProductOrder()
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle producto
              </Typography>
              <Table size="small" aria-label="purchases">
                <div className={classes.productDetail}>
                  <p>
                    Precio por unidad iva incl. :{' '}
                    {'$' + new Intl.NumberFormat('de-DE').format(row.wholesale_unit_price)}
                  </p>
                  <p>Precio por caja iva incl. : {'$' + new Intl.NumberFormat('de-DE').format(row.price)} </p>
                </div>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
}
const rows = (productSelected) => {
  const dataList = productSelected.map((product) =>
    createData(
      product.ID_product,
      product.name,
      product.producer.brand_name,
      product.sale_format,
      product.price_package,
      product.wholesale_unit_price,
      product
    )
  )
  return dataList
}
const HelpButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button)

export default function CollapsibleTable({
  openModal,
  setOpenModal,
  productSelected,
  setRowSelected,
  setProductSelected,
}) {
  const classes = useRowStyles()
  return (
    <Grid container spacing={4} alignItems="flex-start" justifyContent="center">
      <Grid container item spacing={7} justifyContent="flex-start">
        <Grid item md={12} xs={12}>
          <HelpButton
            variant="text"
            color="primary"
            onClick={() => {
              setOpenModal({ show: !openModal.show, component: 'ModalAddProductOrder' })
            }}>
            <AddCircle /> Agregar producto
          </HelpButton>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Producto</TableCell>
              <TableCell align="right">Productor</TableCell>
              <TableCell align="center"> Cantidad</TableCell>
              <TableCell align="right">Formato</TableCell>
              <TableCell align="center">Precio neto</TableCell>
              <TableCell align="right">Subtotal neto</TableCell>
            </TableRow>
          </TableHead>
          {productSelected.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell className={classes.containerNotProduct}>No hay productos agregados</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows(productSelected).map((row) => (
                <Row
                  key={row.name}
                  row={row}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  productSelected={productSelected}
                  setRowSelected={setRowSelected}
                  setProductSelected={setProductSelected}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  )
}
