import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useDeleteProducts() {
  const [deleteProducts, { data, loading, error }] = useMutation(QUERY.PRODUCTS.DELETE, {
    refetchQueries: [
      {
        query: QUERY.PRODUCTS.FULL,
        variables: {
          take: 20,
          skip: 0,
          all: true,
          field: 'NAME',
          order: 'ASC',
          categories: null,
          q: '',
        },
        fetchPolicy: 'cache-and-network',
        context: { clientName: 'apiProducer' },
      },
    ],
    context: { clientName: 'apiProducer' },
  })

  return { deleteProducts, data, loading, error }
}

export function usePostCreateProduct() {
  const [createProduct, { data, loading, error }] = useMutation(QUERY.PRODUCTS.CREATE, {
    context: { clientName: 'apiProducer' },
  })

  return { createProduct, data, loading, error }
}
export function usePostUpdateProduct() {
  const [updateProduct, { data, loading, error }] = useMutation(QUERY.PRODUCTS.UPDATE, {
    context: { clientName: 'apiProducer' },
  })

  return { updateProduct, data, loading, error }
}
