import { Button, CircularProgress, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'

export default function Discounts({ initialStateDsc, dscFirstPurchase, setDscFirstPurchase, saveDiscount, load }) {
  const handleCancel = () => {
    setDscFirstPurchase(initialStateDsc)
  }
  return (
    <Paper style={{ padding: '2rem', margin: '1rem 0rem' }}>
      <Grid container>
        <Typography variant="h5">Descuentos</Typography>
        <Grid container item xs={12} spacing={4} justifyContent="space-between" alignItems="center">
          <Grid item md={4} xs={12}>
            <Typography variant="subtitle1">{'- Descuento de primer pedido en ComeS: '}</Typography>
          </Grid>

          <Grid item md={2} xs={6}>
            <TextField
              id="outlined-number"
              label="Porcentaje"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              placeholder="0"
              value={dscFirstPurchase}
              onChange={(e) => setDscFirstPurchase(Number(e.target.value.substring(0, 2)))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ fontWeight: '700' }}>
                    %
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              disabled={load.discount.status}
              color="primary"
              variant="contained"
              style={{ color: 'white', margin: '0.5rem' }}
              onClick={() => handleCancel()}>
              <Grid container spacing={2}>
                <Grid item>Cancelar</Grid>
                {load.discount.type == 'cancel' && (
                  <Grid item>
                    <CircularProgress color="primary" size={15} />
                  </Grid>
                )}
              </Grid>
            </Button>
            <Button
              disabled={load.discount.status}
              color="secondary"
              variant="contained"
              style={{ color: 'white', margin: '0.5rem' }}
              onClick={() => saveDiscount()}>
              <Grid container spacing={2}>
                <Grid item>Guardar</Grid>
                {load.discount.type == 'save' && (
                  <Grid item>
                    <CircularProgress color="primary" size={15} />
                  </Grid>
                )}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
