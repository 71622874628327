import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useCreateInvoice() {
  const [createInvoice, { data, loading, error }] = useMutation(QUERY.INVOICE.CREATE, {
    context: { clientName: 'apiStore' },
  })

  return { createInvoice, data, loading, error }
}

export function useUpdateInvoice() {
  const [updateInvoice, { data, loading, error }] = useMutation(QUERY.INVOICE.UPDATE, {
    context: { clientName: 'apiStore' },
  })

  return { updateInvoice, data, loading, error }
}
