import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, lighten, withStyles, Toolbar, Typography, makeStyles, Tooltip, IconButton } from '@material-ui/core'
import { ArrowDropDown, ArrowRight, Close, Delete, GetApp } from '@material-ui/icons/'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignItems: 'flex-start',
    paddingTop: '1rem',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    fontWeight: 'bold',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
  },
  '@keyframes fadeInOutAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  listProducts: {
    animation: '$fadeInOutAnimation 0.45s ease-in-out',
  },
}))

const HelpButton = withStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'navajowhite',
    },
  },
})(Button)

export const EnhancedTableToolbar = ({
  selectedId,
  setSelectedId,
  setSelected,
  numSelected,
  openModal,
  setOpenModal,
  productsDownload,
}) => {
  const [open, setOpen] = useState(false)
  console.log('selected', selectedId)
  const classes = useToolbarStyles()

  const deleteSelectedId = (id) => {
    const products = selectedId.filter((product) => product.ID_product !== id)
    setSelectedId(products)
    setSelected(products.map((product) => product.name))
  }

  useEffect(() => {
    numSelected === 0 && setOpen(false)
  }, [numSelected])

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
            title="Ver productos"
            onClick={() => setOpen(!open)}>
            <b>
              {numSelected}{' '}
              {numSelected > 1 ? <span>Productos seleccionados</span> : <span>Producto seleccionado</span>}
            </b>
            <span className={classes.arrow}>{open ? <ArrowDropDown /> : <ArrowRight />}</span>
          </Typography>
          {open && (
            <div className={classes.listProducts} style={{ background: 'white', borderRadius: '5px', width: '750px' }}>
              <ol style={{ maxHeight: '170px', overflow: 'auto' }}>
                {selectedId.map((product) => (
                  <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.87)' }}>
                    <li>{product.name}</li>{' '}
                    <span
                      style={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'red' }}
                      onClick={() => deleteSelectedId(product.ID_product)}>
                      {' '}
                      <Close fontSize="small" />
                    </span>
                  </div>
                ))}
              </ol>
            </div>
          )}
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer', padding: '0.5rem' }}
            onClick={() => {
              setSelectedId([])
              setSelected([])
            }}>
            Quitar todos
          </a>
        </div>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Productos
        </Typography>
      )}

      {numSelected > 0 ? (
        <div style={{ display: 'flex', width: '60%', maxWidth: '409px' }}>
          <HelpButton
            variant="text"
            color="primary"
            onClick={() => {
              setOpenModal({ show: !openModal.show, component: 'ModalDownloadProducts' })
            }}
            title="Descargar excel">
            <GetApp /> <span style={{ paddingLeft: '1rem', fontSize: '14px' }}>Descargar excel</span>
          </HelpButton>
          <HelpButton
            variant="text"
            color="primary"
            style={{ color: 'red' }}
            onClick={() => {
              setOpenModal({ show: !openModal.show, component: 'ModalDeleteProduct' })
            }}
            title="Eliminar">
            <Delete /> <span style={{ paddingLeft: '1rem', fontSize: '14px' }}> Eliminar </span>
          </HelpButton>
        </div>
      ) : (
        <Tooltip title="Descargar productos">
          <IconButton aria-label="Descargar productos" onClick={() => productsDownload()}>
            <GetApp />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}
