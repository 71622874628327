import SALES from './sales'
import PRODUCTS from './products'
import TOKEN from './tokenPost'
import CATEGORIES from './categories'
import PRODUCERS from './producers'
import ORDER_NOTES from './orderNotes'
import ORDER_STATUS from './orderStatus'
import SHIPPING_STATUS from './shippingStatus'
import ORDERS_COMPLETED_BY_STORE from './ordersCompletedByStore'
import USER from './user'
import STORES from './stores'
import DIRECTION from './direction'
import ZONES_DELIVERY from './zonesDelivery'
import DISCOUNTS from './discounts'
import REGIONS from './regions'
import QUOTER from './quoter'
import EDITPRODUCER from './editProducer'
import CERTIFICATION from './certificates'
import INVOICE from './invoice'

export const QUERY = {
  SALES,
  PRODUCTS,
  TOKEN,
  CATEGORIES,
  PRODUCERS,
  ORDER_NOTES,
  ORDER_STATUS,
  SHIPPING_STATUS,
  ORDERS_COMPLETED_BY_STORE,
  USER,
  STORES,
  DIRECTION,
  ZONES_DELIVERY,
  DISCOUNTS,
  REGIONS,
  QUOTER,
  EDITPRODUCER,
  CERTIFICATION,
  INVOICE,
}
