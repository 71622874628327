import sendEmailErrorWsp from './sendEmailErrorWsp'

async function SendWsOrder(response) {
  const orderNote = { detail: response.detail, order: response.order ?? response.orderNote }
  const body = (number, name, numberOrder, producer, place, storeName) => {
    const phone = number.replace('+', '')
    let productsString = ''
    producer.forEach((product) => {
      productsString += `- *Producto :* ${product.product.name} \\n*Formato :* ${
        product.product.sale_format + ' un.'
      } *Cantidad :* ${product.qty} \\n`.toString()
    })
    return {
      recipients: phone,
      template_id: `${process.env.REACT_APP_ID_TEMPLATE_SEND_ORDER_WASAPI}`,
      phoneSelect: `${process.env.REACT_APP_NUMBER_WASAPI}`,
      contact_type: 'phone',
      chatbot_status: 'disable',
      conversation_status: 'unchanged',
      body_vars: [
        {
          text: '{{1}}',
          val: name,
        },
        {
          text: '{{2}}',
          val: storeName,
        },
        {
          text: '{{3}}',
          val: place,
        },
        {
          text: '{{4}}',
          val: numberOrder,
        },
        {
          text: '{{5}}',
          val: productsString.toString(),
        },
      ],
    }
  }
  const postWs = async (number, name, numberOrder, producer, place, storeName) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_WSP}/whatsapp-messages/send-template`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_WASAPI}`,
        },
        body: JSON.stringify(body(number, name, numberOrder, producer, place, storeName)),
      })
      const resJson = await res.json()
      return resJson
    } catch (error) {
      return error
    }
  }
  // Enlista todos los productores del pedido
  const producers = Array.from(new Set(orderNote.detail.map((product) => product.product.producer.brand_name)))
  // agrupa los productos por productor
  let productsByProducer = []
  producers.forEach((producer) => {
    let products = orderNote.detail.filter((product) => product.product.producer.brand_name === producer)
    productsByProducer = [...productsByProducer, products]
  })
  // Se realiza el post por cada productor
  const send = productsByProducer.map((producer) => {
    return new Promise(async (resolve, reject) => {
      postWs(
        producer[0].product.producer.contact[0].phone,
        producer[0].product.producer.brand_name,
        orderNote.order.number,
        producer,
        orderNote.order.store_direction.municipality
          ? orderNote.order.store_direction.municipality
          : orderNote.order.store_direction.sub_region,
        orderNote.order.store.name
      ).then((res) => {
        if (res.success) {
          if (res.sent === 1) {
            resolve({
              message: `Pedido enviado a productor ${producer[0].product.producer.brand_name}`,
              type: 'success',
            })
          } else if (res.not_sent === 1) {
            sendEmailErrorWsp({
              production: Boolean(process.env.REACT_APP_ENV === 'production'),
              nameProducer: producer[0].product.producer.brand_name,
              nameStore: orderNote.order.store.name,
              error: res.logs[0].error,
              numberOrder: orderNote.order.number,
              place: orderNote.order.store_direction.municipality
                ? orderNote.order.store_direction.municipality
                : orderNote.order.store_direction.sub_region,
              products: body(
                producer[0].product.producer.contact[0].phone,
                producer[0].product.producer.brand_name,
                orderNote.order.number,
                producer,
                orderNote.order.store_direction.municipality
                  ? orderNote.order.store_direction.municipality
                  : orderNote.order.store_direction.sub_region,
                orderNote.order.store.name
              )
                .body_vars[4].val.replaceAll('\\n', '<br/>')
                .replaceAll('*', ''),
            })
            resolve({
              message: `Productor: ${producer[0].product.producer.brand_name} - ` + res.logs[0].error,
              type: 'error',
            })
          }
        } else {
          resolve({
            message: `Error al enviar mensaje a productor ${producer[0].product.producer.brand_name}`,
            type: 'error',
          })
        }
      })
    })
  })
  return Promise.all(send).then((responses) => responses)
}
export default SendWsOrder
