import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

// titulos de columnas
const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Productos', name: true },
  { id: 'producer', disablePadding: true, label: 'Productor' },
  { id: 'sale_format', disablePadding: true, label: 'Formato de venta' },
  { id: 'net_unit_cost', disablePadding: true, label: 'Costo neto' },
  { id: 'net_price_package', disablePadding: true, label: 'Precio de venta neto' },
  { id: 'price_package', disablePadding: true, label: 'Precio de venta/IVA' },
  { id: 'wholesale_unit_price', disablePadding: true, label: 'Precio unitario al por mayor/IVA' },
  { id: 'suggested_sale_price', disablePadding: true, label: 'Precio de venta sugerido' },
  { id: 'isActive', disablePadding: true, label: 'Estado' },
  { id: 'edit', disablePadding: true, label: 'Editar', edit: true },
]

export const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.name ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <b>
              {headCell.id !== 'edit' ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span aling="right">{headCell.label}</span>
              )}
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}
