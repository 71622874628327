import { Grid, IconButton, Paper, makeStyles } from '@material-ui/core'
import { AttachFile, Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  filePaper: {
    display: 'flex',
    gap: '.5rem',
    padding: '.5rem',
    alignItems: 'center',
  },
}))

const isValidURL = (url) => {
  try {
    return Boolean(new URL(url))
  } catch (error) {
    return false
  }
}

export const FileAttached = ({ file, handleRemove, readOnly = false }) => {
  const classes = useStyles()

  return (
    <Grid container style={{ gap: '1rem' }}>
      <Grid item xs>
        <Paper elevation={3} className={classes.filePaper}>
          <AttachFile />
          {!isValidURL(file.name) && <span style={{ flex: '1' }}>{file.name}</span>}
          {isValidURL(file.name) && (
            <a href={file.name} style={{ flex: '1', textDecoration: 'none', color: 'var(--dark)' }}>
              {file.name.replace(/.*\//g, '')}
            </a>
          )}
          {!readOnly && (
            <IconButton size="small" onClick={handleRemove}>
              <Close />
            </IconButton>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
