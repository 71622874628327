import { gql } from '@apollo/client'

const USER = {
  GET: gql`
    query UserByAuthId($authId: String) {
      userByAuthId(authId: $authId) {
        user {
          store {
            store_direction {
              ID_store_direction
              country
              code_country
              region
              sub_region
              municipality
              street
              number
              label
              local_or_office
              reference
              latitude
              longitud
              isPublic
              ID_store
            }
            store_image {
              ID_image
              file_image
              name
              isMain
              ID_store
            }
            store_category {
              ID_store_category
              categories_store {
                ID_categories_store
                category
                description
              }
            }
            social_network {
              ID_social_network
              name
              url
              isMain
              ID_store
            }
            rut
            phone
            name
            bussines_name
            ID_store
          }
          role {
            ID_role
            role
          }
          ID_user
          name
          email
          ID_store
          auth_id
        }
      }
    }
  `,
  GET_USERS: gql`
    query Users {
      users {
        user {
          ID_user
          name
          email
          role {
            ID_role
            role
          }
          ID_store
          auth_id
          store {
            ID_store
            name
            bussines_name
            phone
            rut
            store_direction {
              ID_store_direction
              country
              code_country
              region
              sub_region
              municipality
              street
              number
              label
              local_or_office
              reference
              latitude
              longitud
              isPublic
              ID_store
            }
            store_category {
              ID_store_category
              categories_store {
                ID_categories_store
                category
                description
              }
            }
            social_network {
              ID_social_network
              name
              url
              isMain
              ID_store
            }
            store_image {
              ID_image
              file_image
              name
              isMain
              ID_store
            }
          }
        }
      }
    }
  `,
}
export default USER
