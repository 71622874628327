import { client } from '../services/client'
import { QUERY } from '../services/queries'

export async function getToken(auth_id, email) {
  const { data } = await client.mutate({
    mutation: QUERY.TOKEN.GET,
    variables: {
      payload: {
        auth_id,
        email,
        origen: 'ADMIN_DASHBOARD',
      },
    },
    context: { clientName: 'apiProducer' },
  })
  data?.auth?.token && localStorage.setItem('TOKEN_DASH', data?.auth?.token)
  return data?.auth?.token
}
