import { gql } from '@apollo/client'

const DETAILS_FRAGMENT = gql`
  fragment DetailsProducer on Producer {
    ID_producer
    rut
    brand_name
    additional_info
    web
    isActive
    isDeleted
    created_at
    modified_at
    isPublished
    slug
    slogan
    history
    min_purchase
    business_name
    modified_by
    delivery_time
    contrat_start
    type_sale {
      ID_type_sale
      type
    }
    contact {
      ID_contact
      auth_id
      email
      name
      phone
      isMain
    }
  }
`
const FULL_FRAGMENT = gql`
  fragment FullProducer on Producer {
    ID_producer
    rut
    brand_name
    additional_info
    web
    isActive
    isDeleted
    created_at
    modified_at
    isPublished
    slug
    slogan
    history
    min_purchase
    business_name
    modified_by
    delivery_time
    contrat_start
    type_sale {
      ID_type_sale
      type
    }
    formalization {
      ID_formalization
      health_resolution
      start_activities
      created_at
      modified_at
    }
    bank_account {
      ID_bank_account
      rut
      holder
      bank
      type
      number
    }
    files {
      ID_files
      file_name
      ID_producer
    }
    direction {
      ID_direction
      street
      number
      reference
      ID_producer
      code_country
      country
      label
      latitud
      longitud
      municipality
      region
      sub_region
      local_or_office
    }
    producer_category {
      ID_producer_category
      category {
        ID_category
        category
        category_type {
          ID_category_type
          type
        }
      }
    }
    contact {
      ID_contact
      auth_id
      email
      name
      phone
      isMain
    }
  }
`
const EXCEL_PRODUCT_FRAGMENT = gql`
  fragment ExcelProductProducer on Producer {
    ID_producer
    rut
    brand_name
    business_name
    min_purchase
    type_sale {
      ID_type_sale
      type
    }
  }
`
const PRODUCER = {
  FULL_FRAGMENT,
  EXCEL_PRODUCT_FRAGMENT,
  DETAILS_FRAGMENT,
}
export default PRODUCER
