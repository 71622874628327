import { gql } from '@apollo/client'

const ORDERS_COMPLETED_BY_STORE = {
  GET: gql`
    query OrderCompletedCountByIdStore($idStore: ID!) {
      orderCompletedCountByIdStore(ID_store: $idStore) {
        _count
      }
    }
  `,
}
export default ORDERS_COMPLETED_BY_STORE
