import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { materialDateInput } from '../../utils/dates'
import { Autocomplete } from '@material-ui/lab'
import newOrderContext from '../../context/NewOrderState'
import AlertContext from '../../context/AlertContext'
import { useGetStores } from '../../hooks/useGetStores'
import { useGetUsers } from '../../hooks/useGetUser'
import { useGetDirectionsByIdStore } from '../../hooks/useGetDirection'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
}))

export default function InputsAddOrder({ openModal, setOpenModal }) {
  const classes = useStyles()
  const [value, setvalue] = useState({})
  const [selectedStore, setSelectedStore] = useState('')
  const [directions, setDirections] = useState([])
  const [direction, setDirection] = useState('')
  const { orderStore, setOrderStore } = useContext(newOrderContext)
  const { setAlert } = useContext(AlertContext)
  const [internalOrder, setInternalOrder] = useState(true)
  const [vendorSelected, setVendorSelected] = useState('')
  const { stores } = useGetStores()
  let { vendors } = useGetUsers()
  vendors = vendors?.filter((user) => user.role.ID_role == 4 || user.role.ID_role == 1 || user.role.ID_role == 5)
  const { requestGetDirectionsStore } = useGetDirectionsByIdStore()

  //obtiene las direcciones de la tienda seleccionada
  const getStoreAddress = async (id) => {
    const {
      data: {
        storeDirectionById: { storeDirection },
      },
    } = await requestGetDirectionsStore({
      variables: {
        idStore: id,
      },
    })
    setDirections(storeDirection)
  }

  //ocurre cuando cambia la tienda seleccionada
  useEffect(() => {
    if (selectedStore) {
      //busca la tienda seleccionada para sacar los datos necesarios
      const { ID_store, rut, phone, email, bussines_name, name } = stores.find((store) => store.name === selectedStore)

      //pide las direcciones
      getStoreAddress(ID_store)

      //setea en value los datos de la tienda
      setvalue({ ...value, rut, phone, email, bussines_name, ID_store, name })

      //le agrega al estado global lo que contiene value
      setOrderStore({ ...orderStore, ID_store, rut, phone, email, bussines_name, name })
    } else if (!selectedStore) {
      //sino hay seleccionado, limpia todos los datos
      setvalue({})
      setDirections([])
      setOrderStore({ ID_order_status: '0' })
      setVendorSelected('')
    }
    setOrderStore({ ...orderStore, address: '' })
    setDirection('')
  }, [selectedStore])

  //ocurre cuando cambia el value y lo setea al estado global
  useEffect(() => {
    setOrderStore({ ...orderStore, ...value })
  }, [value])

  useEffect(() => {
    setOrderStore({ ...orderStore, addressArray: directions })
  }, [directions])

  //ocurre cuando cambia la direccion y deja la deja como la direccion seleccionada
  useEffect(() => {
    setvalue({ ...value, address: directions.find((address) => address.ID_store_direction == direction) })
    setOrderStore({ ...orderStore, address: directions.find((address) => address.ID_store_direction == direction) })
  }, [direction])

  useEffect(() => {
    setOrderStore({ ...orderStore, vendor: vendorSelected })
  }, [vendorSelected])

  useEffect(() => {
    const initialState = {
      ID_order_status: '0',
      details: [],
      discount: '0',
      discountType: 'percent',
      image: [],
      internalOrder: true,
    }
    if (JSON.stringify(orderStore) === JSON.stringify(initialState)) {
      setSelectedStore('')
    }
  }, [orderStore])

  useEffect(() => {
    setvalue({ ...value, internalOrder })
  }, [internalOrder])

  return (
    <Grid container spacing={4} alignItems="flex-start" justifyContent="center">
      {/* -------------PRIMERA FILA -------------- */}
      <Grid container item spacing={1} justifyContent="justifyContent" direction="column">
        <Grid item sm={4} xs={12}>
          <FormLabel>Tipo de pedido</FormLabel>
        </Grid>
        <Grid item sm={7} xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={internalOrder} />}
              value={internalOrder}
              label="ComeS"
              onChange={() => setInternalOrder(!internalOrder)}
            />
            <FormControlLabel
              value={!internalOrder}
              control={<Checkbox checked={!internalOrder} />}
              label="Productor"
              onChange={() => setInternalOrder(!internalOrder)}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item spacing={7} justifyContent="space-between">
        <Grid item sm={4} xs={12}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              id="nameStore"
              value={selectedStore}
              aria-describedby="Nombre de Tienda"
              options={stores?.map((store) => store.name) ?? []}
              onChange={(e, newValue) => {
                setSelectedStore(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nombre de tienda"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            {/* Para la validación a posterioridad */}
            {/* {error && <FormHelperText id={`on-error-${id}`}>{errorText}</FormHelperText>} */}
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="nameStore">Rut</InputLabel>
            <Input
              type="text"
              id="rut"
              aria-describedby="Rut"
              onChange={(e) => setvalue({ ...value, [e.target.id]: e.target.value })}
              value={value.rut ? value.rut : ''}
            />
            {/* Para la validación a posterioridad */}
            {/* {error && <FormHelperText id={`on-error-${id}`}>{errorText}</FormHelperText>} */}
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <InputLabel htmlFor="dateOrderLabel">Fecha</InputLabel>
          <TextField
            id="dateOrder"
            defaultValue={materialDateInput()}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>

      {/* -------------SEGUNDA FILA -------------- */}
      <Grid container item spacing={7} justifyContent="space-between">
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            label="Razón social"
            id="bussines_name"
            // defaultValue={''}
            InputProps={{
              readOnly: true,
            }}
            value={value.bussines_name ? value.bussines_name : ''}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            id="dispatchAddress"
            select
            label="Dirección de despacho"
            value={direction ? direction : ''}
            variant="standard"
            onChange={(e) => setDirection(e.target.value)}
            helperText={
              <Button
                variant="text"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  !selectedStore
                    ? setAlert({ open: true, message: 'Debes seleccionar una tienda', severity: 'error' })
                    : setOpenModal({ show: !openModal.show, component: 'ModalAddAddress', setDirections, directions })
                }}>
                Añadir otra dirección
              </Button>
            }>
            {directions.map((direction) => (
              <MenuItem
                key={`${direction.ID_store}_${direction.ID_store_direction}`}
                value={direction.ID_store_direction}>
                {`${direction.street} #${direction.number}, ${
                  direction.local_or_office ? direction.local_or_office + ',' : ''
                } ${direction.municipality ? direction.municipality : direction.sub_region}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12}>
          <InputLabel htmlFor="dateOrderLabel">N° de pedido</InputLabel>
          <TextField
            id="dateOrder"
            defaultValue={'Por asignar'}
            // disabled
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      {/* -------------TERCERA FILA -------------- */}
      <Grid container item spacing={7} justifyContent="flex-start">
        <Grid item md={4} xs={12}>
          <TextField
            label="Teléfono"
            fullWidth
            id="phone"
            value={value.phone ? value.phone : ''}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            style={{ marginLeft: '5rem' }}
            fullWidth
            label="Email"
            id="email"
            value={value.email ? value.email : ''}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      {/* -------------CUARTA FILA -------------- */}
      <Grid container item spacing={7} justifyContent="space-between">
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            select
            value={vendorSelected}
            variant="standard"
            label="Vendedor"
            id="vendor"
            onChange={(e) => setVendorSelected(e.target.value)}>
            {vendors
              ? vendors.map((vendor, index) => (
                  <MenuItem key={index} value={vendor}>
                    {vendor.name}
                  </MenuItem>
                ))
              : ''}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12}></Grid>
      </Grid>
    </Grid>
  )
}
