import { Button, Grid, makeStyles } from '@material-ui/core'
import { Error } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
}))

export default function ModalAddCategory({ handleClose, confirm, category }) {
  const classes = useStyles()
  const handleConfirm = () => {
    confirm()
    handleClose()
  }

  return (
    <>
      <Grid container direction="column" alignItems="center" className={classes.root}>
        <Error fontSize="large" color="primary" />
        <h2 id="transition-modal-title">¿Seguro quieres agregar esta categoría?</h2>
        <p id="transition-modal-description">{category}</p>
        <div>
          <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={handleConfirm}>
            Continuar
          </Button>
        </div>
      </Grid>
    </>
  )
}
