import { Chip, Dialog, Grid, Typography } from '@material-ui/core'
import { Info } from '@material-ui/icons'

export const ProductInfo = ({ title, hasInfo = false, handleClick, value }) => {
  const showInfo = hasInfo && handleClick
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Chip label={value} color="secondary" style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }} />
          </Grid>
          {showInfo && (
            <Grid item>
              <Info color="primary" onClick={handleClick} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
