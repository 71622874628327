import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetDirectionsByIdStore() {
  const [requestGetDirectionsStore, { data, error }] = useLazyQuery(QUERY.DIRECTION.GET_BY_ID_STORE, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { requestGetDirectionsStore, data, error }
}
