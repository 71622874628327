import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f69223',
      backgroundToolbar: '#FBFBFB',
    },
    secondary: {
      main: '#8ac541',
    },
  },
})

export default theme
