export function groupIdShipping(shipping) {
  const newOrderShippingStatus = shipping.reduce((prev, act) => {
    if (!prev.find((el) => el?.tracking_number === act.tracking_number)) {
      return [...prev, { ...act, ID_shipping_status: [act.ID_shipping_status] }]
    }

    const elToEdit = prev.findIndex((el) => el.tracking_number === act.tracking_number)

    const newArr = prev
    newArr[elToEdit] = {
      ...newArr[elToEdit],
      ID_shipping_status: [...newArr[elToEdit].ID_shipping_status, act.ID_shipping_status],
    }
    return newArr
  }, [])
  return newOrderShippingStatus
}
