import { useEffect, useRef, useState } from 'react'

export function useCalculatePrices({ setProductData, productData }) {
  // Referencias de los inputs
  const i_net_unit_cost = useRef(null)
  const i_unit_cost = useRef(null)
  const i_net_price_package = useRef(null)
  const i_price_package = useRef(null)
  // --- Se acaban las referencias
  const [margin, setMargin] = useState(0)

  const UpdateUnitCost = (e) => {
    const net_unit_cost = +e.target.value
    const unit_cost = +(net_unit_cost * 1.19).toFixed(2)
    setProductData({ ...productData, net_unit_cost, unit_cost })
  }

  const UpdateNetUnitCost = (e) => {
    const unit_cost = +e.target.value
    const net_unit_cost = +(unit_cost / 1.19).toFixed(2)
    setProductData({ ...productData, net_unit_cost, unit_cost })
  }

  const UpdateNetPricePackage = (e) => {
    const price_package = +e.target.value
    const net_price_package = +(price_package / 1.19).toFixed(2)
    setProductData({ ...productData, net_price_package, price_package })
  }

  const UpdatePricePackage = (e) => {
    const net_price_package = +e.target.value
    const price_package = +(net_price_package * 1.19).toFixed(2)
    setProductData({ ...productData, net_price_package, price_package })
  }

  useEffect(() => {
    const ref_net_unit_cost = i_net_unit_cost.current
    const ref_unit_cost = i_unit_cost.current
    const ref_net_price_package = i_net_price_package.current
    const ref_price_package = i_price_package.current

    if (ref_net_price_package && ref_net_unit_cost && ref_price_package && ref_unit_cost) {
      ref_net_unit_cost.addEventListener('change', UpdateUnitCost)
      ref_unit_cost.addEventListener('change', UpdateNetUnitCost)
      ref_net_price_package.addEventListener('change', UpdatePricePackage)
      ref_price_package.addEventListener('change', UpdateNetPricePackage)

      return () => {
        ref_net_unit_cost.removeEventListener('change', UpdateUnitCost)
        ref_unit_cost.removeEventListener('change', UpdateNetUnitCost)
        ref_net_price_package.removeEventListener('change', UpdatePricePackage)
        ref_price_package.removeEventListener('change', UpdateNetPricePackage)
      }
    }
  }, [productData.price_package, productData.net_price_package, productData.net_unit_cost, productData.unit_cost])

  useEffect(() => {
    if (productData.sale_format && productData.price_package) {
      setProductData({ ...productData, wholesale_unit_price: productData.price_package / productData.sale_format })
    }
  }, [productData.price_package, productData.wholesale_unit_price, productData.sale_format])

  // Cambia el valor de margin
  useEffect(() => {
    if (productData.suggested_sale_price && productData.wholesale_unit_price) {
      const newmargin = Math.round(
        (productData.suggested_sale_price * 100) /
          (productData.offer_price / productData.sale_format || productData.wholesale_unit_price) -
          100
      )
      setMargin(newmargin)
    }
  }, [productData.suggested_sale_price, productData.wholesale_unit_price, margin])

  return { i_net_price_package, i_net_unit_cost, i_price_package, i_unit_cost, setMargin, margin }
}
