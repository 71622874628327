import { useLazyQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetLazyOrdersCompletedByStore() {
  const [requestOrdersCompletedByStore, { data, error }] = useLazyQuery(QUERY.ORDERS_COMPLETED_BY_STORE.GET, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { requestOrdersCompletedByStore, data, error }
}
