import { gql } from '@apollo/client'

const REGIONS = {
  FRESH_PRODUCER_BY_REGION: gql`
    query Regions {
      freshProducerByRegion {
        regions {
          ids
          region
        }
      }
    }
  `,
  GET_ALL: gql`
    query Regions {
      regions {
        regions {
          ID_direction
          region
        }
      }
    }
  `
}
export default REGIONS
