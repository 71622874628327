export default function sendWsQuote(producers, message) {
  const wsPosts = producers.map((producer) => {
    return fetch(`${process.env.REACT_APP_API_WSP}/whatsapp-messages/send-template`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_WASAPI}`,
      },
      body: createBody(producer, message),
    })
  })

  return Promise.allSettled(wsPosts)
}

const createBody = (producer, message) => {
  return JSON.stringify({
    recipients: producer.contact[0].phone.replace('+', ''),
    template_id: `${process.env.REACT_APP_ID_TEMPLATE_SEND_QUOTER_WASAPI}`,
    phoneSelect: `${process.env.REACT_APP_NUMBER_WASAPI}`,
    contact_type: 'phone',
    chatbot_status: 'disable',
    conversation_status: 'unchanged',
    body_vars: [
      {
        text: '{{1}}',
        val: producer.brand_name,
      },
      {
        text: '{{2}}',
        val: message,
      },
    ],
  })
}
