import {
  Grid,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Divider,
  TableContainer,
  InputLabel,
  InputAdornment,
  MenuItem,
  Chip,
} from '@material-ui/core'
import Input from '../../components/Input'
import { makeStyles } from '@material-ui/core/styles'
import NewProductState from '../../context/NewProductState'
import ErrorContext from '../../context/ErrorContext'
import { useContext, useEffect, useRef, useState } from 'react'
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg'
import { useParams } from 'react-router-dom'
import { ContentState, convertFromHTML } from 'draft-js'
import valueOrEmpty from '../../utils/valueorEmpty'
import Loader from '../../components/Loader'
import TransitionsModal from '../../components/modal/ModalContainer'
import AlertContext from '../../context/AlertContext'
import UserContext from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { HighlightOff } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { useGetCategories } from '../../hooks/useGetCategories'
import { useGetLazyProductById } from '../../hooks/useGetProducts'
import { useGetProducersList } from '../../hooks/useGetProducers'
import { usePostCreateProduct, usePostUpdateProduct } from '../../hooks/usePostProducts'
import { usePostCreateCategoryProduct, usePostDeleteCategoryProduct } from '../../hooks/usePostCategories'
import { QUERY } from '../../services/queries'
import { useCalculatePrices } from '../../hooks/useCalculatePrices'
import { useFormalizationDocuments } from '../../hooks/useFormalizationDocuments'
import { OptionSelect } from './OptionSelect'
import ListSelect from '../../components/ListSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  container: {
    padding: '1.5rem',
  },
  containerBtns: {
    padding: '1.5rem 2.5rem !important',
  },
  chip: {
    background: theme.palette.secondary.main,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  menuSelected: {
    background: `${theme.palette.grey[100]}!important`,
    '& > .item': {
      background: 'orange!important',
      color: 'white',
    },
  },
}))

export default function AddProduct() {
  const classes = useStyles()
  const params = useParams()
  const { setAlert } = useContext(AlertContext)
  const { user } = useContext(UserContext)
  const [producers, setProducers] = useState([])
  const [loaded, setLoaded] = useState(params.id ? false : true)
  const { productData, setProductData } = useContext(NewProductState)
  const [openModal, setOpenModal] = useState({ show: false, component: 'ModalConfirmacion' })
  const [block, setBlock] = useState(false)

  //guarda todas las categorías en la bd
  const [categories, setCategories] = useState([])
  //guarda una nueva categoría (para agregar a categories)
  const [newCategory, setNewCategory] = useState('')
  //Guarda las categorías seleccionadas (para guardar en el producto)
  const [categoriesSelected, setCategoriesSelected] = useState([])
  //Guarda las categorías a eliminar del producto
  const [deleteCategories, setDeleteCategories] = useState([])
  // estado si está o no cargando
  const [load, setLoad] = useState(false)
  // toma el valor del producto que viene desde la API
  const [apiProduct, setApiProduct] = useState([])
  // guardamos la tabla nutricional de la imagen seleccionada
  const [nutritionalTable, setNutritionalTable] = useState()
  // guardamos los ingredientes de la imagen seleccionada
  const [ingredients, setIngredients] = useState()
  const [nameProducer, setNameProducer] = useState('')
  const [imageRemoved, setImageremoved] = useState(null)
  const { categories: categoriesProducts } = useGetCategories()
  const { producers: producersList } = useGetProducersList({})
  const { requestProductById, data: apiProductData } = useGetLazyProductById()
  const { createProduct } = usePostCreateProduct()
  const { updateProduct } = usePostUpdateProduct()
  const { createCategoryProduct } = usePostCreateCategoryProduct()

  const { deleteCategoryProduct } = usePostDeleteCategoryProduct()

  const {
    handleSelectedResolutions,
    handleSelectedCertificates,
    selectedResolution,
    selectedCertificates,
    optionsCertificates,
    optionsResolution,
    updateProductCertification,
  } = useFormalizationDocuments(apiProductData, productData)

  const history = useHistory()
  const unblockHandle = useRef()

  const initialState = {
    description: MUIEditorState.createEmpty(),
    benefit: MUIEditorState.createEmpty(),
    conservation: MUIEditorState.createEmpty(),
    isActive: true,
    product_image: [],
  }

  const convertToMUIEditor = (sample) => {
    if (sample !== null && sample !== undefined) {
      const html = convertFromHTML(sample)
      const contentState = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)
      const editorState = MUIEditorState.createWithContent({}, contentState)
      return editorState
    } else {
      const editorState = MUIEditorState.createEmpty()
      return editorState
    }
  }

  useEffect(() => {
    !params.id
      ? setProductData({
          ...productData,
          product_category: categoriesSelected.map((category) => {
            return { ...category }
          }),
        })
      : setProductData({
          ...productData,
          product_category: categoriesSelected.map((category) => {
            return { ...category }
          }),
        })
  }, [categoriesSelected])

  useEffect(() => {
    !params.id && setApiProduct([])
    !params.id && setProductData(initialState)
    !params.id && setCategoriesSelected([])
    !params.id && setIngredients()
    !params.id && setNutritionalTable()
  }, [params.id])

  useEffect(() => {
    if (categoriesProducts) {
      setCategories(categoriesProducts)
    }
    if (producersList) {
      setProducers(producersList.producer.map((producer) => ({ ID: producer.ID_producer, name: producer.brand_name })))
    }
  }, [categoriesProducts, producersList])

  useEffect(async () => {
    if (params.id) {
      const { data } = await requestProductById({ variables: { idProduct: params.id } })
      const { product } = data.productById
      const {
        benefit,
        description,
        conservation,
        stock: { ID_stock },
        producer: { ID_producer },
        ...rest
      } = product
      const newBenefit = convertToMUIEditor(benefit)
      const newDescription = convertToMUIEditor(description)
      const newConservation = convertToMUIEditor(conservation)
      const newProductData = {
        benefit: newBenefit,
        description: newDescription,
        conservation: newConservation,
        ID_stock,
        ID_producer,
        ...rest,
      }
      if (product.nutritional_info) {
        setNutritionalTable({ nutritional_info: JSON.parse(product.nutritional_info) })
      }
      if (product.ingredients) {
        setIngredients({ ingredients: product.ingredients })
      }
      setProductData(newProductData)
      setApiProduct({
        ...product,
        product_category: newProductData.product_category.map((category) => {
          return {
            category: category.category_product.category,
            ID_category_product: category.category_product.ID_category_product,
          }
        }),
      })
      setLoaded(true)
      setCategoriesSelected(
        newProductData.product_category.map((category) => {
          return {
            category: category.category_product.category,
            ID_category_product: category.category_product.ID_category_product,
          }
        })
      )
    } else {
      setProductData(initialState)
    }
  }, [])

  useEffect(() => {
    unblockHandle.current = history.block((location) => {
      if (block) {
        setOpenModal({ show: true, component: 'ModalConfirmacion', confirm: unblockHandle, location })
        return false
      }
    })
    return () => {
      unblockHandle.current && unblockHandle.current()
    }
  }, [productData])

  const stockStatus = [
    { ID: '2', name: 'Temporalmente sin stock', selected: true },
    { ID: '1', name: 'En stock' },
  ]

  function setProductState(payload) {
    setProductData({ ...productData, ...payload })
    if (!block) {
      setBlock(true)
    }
  }
  const { errors, setErrors } = useContext(ErrorContext)

  const sendForm = async (newProduct, url = '/api/v2/product', method = 'POST') => {
    const { benefit, description, conservation, product_image, resolution, product_certification, ...rest } = newProduct
    const htmlDescription = toHTML(description.getCurrentContent())
    const htmlConservation = toHTML(conservation.getCurrentContent())
    const htmlBenefit = toHTML(benefit.getCurrentContent())
    let newImage = { ...product_image }
    setLoad(true)

    const requiredFields = [
      'ID_producer',
      'name',
      'sale_format',
      'ID_stock',
      'unit_cost',
      'wholesale_unit_price',
      'price_package',
    ]
    const requiredFieldsEntered = requiredFields
      .map((item) => {
        const isAllEntered = Object.keys(newProduct).some((fieldEntered) => fieldEntered === item)
        const isEmpty = newProduct[item] === ''
        return {
          msg: `Este campo es requerido.`,
          param: `product.${item}`,
          status: isAllEntered && !isEmpty,
        }
      })
      .filter((item) => item.status === false)

    if (requiredFieldsEntered.length) {
      setErrors(requiredFieldsEntered)
      setLoad(false)
    } else {
      if (params.id && product_image.length === 0) {
        newImage = product_image
      } else if (params.id && product_image.length !== 0) {
        if (Array.isArray(product_image)) {
          newImage = product_image[0]
        } else {
          let { file_image } = product_image
          file_image = file_image.name
          newImage = [{ ...product_image, file_image }]
        }
      } else if (product_image.length !== 0) {
        let { file_image } = product_image
        file_image = file_image.name
        newImage = { ...newImage, file_image }
      }

      let newObject

      if (params.id) {
        const { product_category, ID_product, created_at, modified_at, modified_by, __typename, ...newRest } = rest
        newObject = {
          idProduct: ID_product,
          productPayload: {
            ...newRest,
            ingredients: ingredients ? ingredients.ingredients : null,
            nutritional_info: nutritionalTable ? JSON.stringify(nutritionalTable.nutritional_info) : null,
            description: htmlDescription,
            conservation: htmlConservation,
            modified_by: user?.userApi?.ID_user,
            modified_at: new Date().toISOString(),
            benefit: htmlBenefit,
          },
          categoryProduct: product_category.map((category) => {
            return { ID_category_product: category.ID_category_product }
          }),
          imagePayload: newImage[0]?.file_image ? newImage : [],
          imageDelete: imageRemoved
            ? [
                {
                  ID_product_image: imageRemoved.ID_product_image,
                },
              ]
            : [],
        }
      } else {
        const { product_category, ID_product, created_at, modified_at, modified_by, __typename, ...newRest } = rest
        newObject = {
          productPayload: {
            ...newRest,
            ingredients: ingredients ? ingredients.ingredients : null,
            nutritional_info: nutritionalTable ? JSON.stringify(nutritionalTable.nutritional_info) : null,
            description: htmlDescription,
            conservation: htmlConservation,
            modified_by: user?.userApi?.ID_user,
            modified_at: new Date().toISOString(),
            created_at: new Date().toISOString(),
            isDeleted: false,
            benefit: htmlBenefit,
            category_product: product_category.map((category) => {
              return { ID_category_product: category.ID_category_product }
            }),
          },
          imagePayload: newImage?.file_image ? [newImage] : [],
        }
      }
      setErrors([])
      try {
        const { data, loading, error } = await (method === 'PUT' ? updateProduct : createProduct)({
          variables: newObject,
        })
        const accessDenied = data?.updateProduct?.message === 'Acceso denegado'
        if (accessDenied) {
          setAlert({ open: true, message: data.updateProduct.message, severity: 'error' })
        }
        if (data && !accessDenied) {
          if (imageRemoved && imageRemoved.file_image) {
            await fetch(`${process.env.REACT_APP_API_PRODUCER}api/v1/delete/file?dir=products_files`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ fileName: imageRemoved.file_image }),
            })
          }
          if (params.id) {
            if (data.updateProduct.message === 'Producto actualizado con exito') {
              if (deleteCategories.length) {
                const { data, loading, error } = await deleteCategoryProduct({
                  variables: {
                    idProduct: params.id,
                    categories: deleteCategories.map((category) => category.ID_category_product),
                  },
                })
                if (error) {
                  setAlert({ open: true, message: 'Producto actualizado, categorías no eliminadas', severity: 'error' })
                }
              }
              await updateProductCertification(data.updateProduct.ID_product)
              setAlert({ open: true, message: 'Producto actualizado', severity: 'success' })
              setDeleteCategories([])
            }
          } else {
            if (data.createProduct.message === 'Producto ingresado') {
              setProductData({
                description: MUIEditorState.createEmpty(),
                benefit: MUIEditorState.createEmpty(),
                conservation: MUIEditorState.createEmpty(),
                isActive: true,
                image: [],
              })
              setNutritionalTable()
              setIngredients()
              setCategoriesSelected([])
              await updateProductCertification(data.createProduct.ID_product)
              setAlert({ open: true, message: data.createProduct.message, severity: 'success' })
            } else {
              if (data.createProduct.message === 'No se ha podido ingresar el producto. Slug debe ser unico') {
                setErrors([
                  {
                    msg: `Nombre de producto debe ser unico.`,
                    param: `product.slug`,
                    status: false,
                  },
                ])
              }

              setAlert({ open: true, message: data.createProduct.message, severity: 'error' })
            }
          }
          if (
            data?.updateProduct?.message === 'Producto actualizado con exito' ||
            data?.createProduct?.message === 'Producto ingresado'
          ) {
            if (product_image.file_image) {
              const formData = new FormData()
              formData.append('file', product_image.file_image)
              try {
                const res = await fetch(`${process.env.REACT_APP_API_PRODUCER}api/v1/upload/file?dir=products_files`, {
                  method: 'POST',
                  body: formData,
                })
                const response = await res.json()
              } catch (error) {
                console.error(error)
                setAlert({ open: true, message: 'Hubo un error al subir la imagen', severity: 'error' })
              }
              location.reload()
            }
          }
        }
      } catch (error) {
        console.error('error', error)
        setAlert({ open: true, message: 'Error de comunicación.', severity: 'error' })
      } finally {
        setLoad(false)
      }
    }
  }

  const typeDuration = [
    { ID: 'Día', name: 'Día' },
    { ID: 'Mes', name: 'Mes' },
    { ID: 'Año', name: 'Año' },
  ]

  async function addCategory() {
    // fetch POST > request = category y response = {ID_category, category}
    if (newCategory.length >= 3) {
      const { data } = await createCategoryProduct({
        variables: {
          categoryPayload: [
            {
              category: newCategory,
            },
          ],
        },
        refetchQueries: [
          {
            query: QUERY.CATEGORIES.PRODUCTS,
            fetchPolicy: 'cache-and-network',
            context: { clientName: 'apiProducer' },
          },
        ],
      })
      if (data?.createCategory?.newCategories?.length) {
        const newCategory = {
          ...data?.createCategory?.newCategories[0],
          ID_category_product: data?.createCategory?.newCategories[0].ID_category_product,
        }
        setCategories([...categories, newCategory])
        setCategoriesSelected([...categoriesSelected, newCategory])
        setAlert({ open: true, message: 'Categoria ingresada', severity: 'success' })
      } else if (data?.createCategory?.message) {
        setAlert({ open: true, message: data?.createCategory?.message, severity: 'error' })
      } else if (res.status >= 500) {
        setAlert({ open: true, message: 'Error del servidor.', severity: 'error' })
      }
    } else {
      setAlert({
        open: true,
        message: 'El largo de la nueva categoría debe ser igual o superior a 3',
        severity: 'error',
      })
    }
  }

  function handleOpenAddCategory() {
    if (newCategory.length >= 3) {
      setOpenModal({ show: true, component: 'ModalAddCategory', confirm: addCategory, category: newCategory })
    } else {
      setAlert({
        open: true,
        message: 'El largo de la nueva categoría debe ser igual o superior a 3',
        severity: 'error',
      })
    }
  }

  function handleProductCategories(localState, action, { option }) {
    switch (action) {
      case 'remove-option':
        const hasCategory = params.id
          ? apiProduct.product_category.some((category) => category.ID_category_product === option.ID_category_product)
          : false
        if (hasCategory) {
          setDeleteCategories([...deleteCategories, option])
          setCategoriesSelected(localState)
        } else {
          setCategoriesSelected(localState)
        }
        break
      case 'select-option':
        const isDeleted = deleteCategories.some(
          (category) => category.ID_category_product === option.ID_category_product
        )
        if (isDeleted) {
          const newDelete = deleteCategories.filter(
            (category) => category.ID_category_product !== option.ID_category_product
          )
          setDeleteCategories(newDelete)
          setCategoriesSelected(localState)
        } else {
          setCategoriesSelected(localState)
        }
        break
      default:
        break
    }
  }
  const handleReset = () => {
    history.push('/productos')
  }

  // USEEFFECT PARA INPUTS
  const { i_net_price_package, i_net_unit_cost, i_price_package, i_unit_cost, margin, setMargin } = useCalculatePrices({
    setProductData,
    productData,
  })

  return (
    <>
      <TransitionsModal openModal={openModal} setOpenModal={setOpenModal} />
      <Paper className={classes.container}>
        {!loaded ? (
          <Loader />
        ) : (
          <>
            {params.id ? (
              <Typography variant="h1" className={classes.h1}>
                Editar producto
              </Typography>
            ) : (
              <Typography variant="h1" className={classes.h1}>
                Subir nuevo producto
              </Typography>
            )}
            <Grid container spacing={4} direction="column">
              <Grid container item spacing={4} alignItems="center">
                <Grid container item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Input
                      label="Nombre del Productor"
                      id="ID_producer"
                      idError="product.ID_producer"
                      list={producers}
                      type="select"
                      init={valueOrEmpty(productData.ID_producer)}
                      dataset={productData}
                      fullWidth
                      inputProps={{ required: true }}
                      setState={setProductState}
                      setNameProducer={setNameProducer}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* consume el context y envía el slug producido */}
                    <Input
                      label="Nombre del producto"
                      id="name"
                      idError={productData.name ? 'product.slug' : 'product.name'}
                      style={{ fontSize: '1.5rem' }}
                      helperText={`vista previa de la ruta: https://alimentos.comes.cl/product/${valueOrEmpty(
                        productData.slug
                      )}`}
                      init={valueOrEmpty(productData.name)}
                      inputProps={{ autoComplete: 'off' }}
                      setState={setProductState}
                      nameProducer={nameProducer}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <Grid container item spacing={4} alignItems="center">
                    <Grid item xs={12}>
                      {params.id ? (
                        <Input
                          type="file"
                          label="Subir una imagen"
                          id="product_image"
                          idError="product.product_image"
                          setState={setProductState}
                          init={productData?.product_image[0]}
                          setImageremoved={setImageremoved}
                          imageRemoved={imageRemoved}
                          productData={productData}
                        />
                      ) : (
                        <Input
                          type="file"
                          label="Subir una imagen"
                          id="product_image"
                          idError="product.product_image"
                          setState={setProductState}
                          productData={productData}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={12}>
                  <Grid container item spacing={4} alignItems="center">
                    <Grid item xs={12}>
                      <Input
                        label="Presentación comercial (Packaging)"
                        id="packaging"
                        idError="product.packaging"
                        style={{ fontSize: '1.5rem' }}
                        helperText={`Por ejemplo: Envase de vidrio microondeable con tapa de aluminio.`}
                        init={valueOrEmpty(productData.packaging)}
                        inputProps={{ autoComplete: 'off' }}
                        setState={setProductState}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={4} direction="column">
                <Grid item xs={12}>
                  <Input
                    label="Descripción"
                    id="description"
                    idError="product.description"
                    type="rte"
                    init={valueOrEmpty(productData.description)}
                    setState={setProductState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Usos y beneficios"
                    id="benefit"
                    idError="product.benefit"
                    type="rte"
                    init={valueOrEmpty(productData.benefit)}
                    setState={setProductState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Conservación"
                    id="conservation"
                    idError="product.conservation"
                    type="rte"
                    init={valueOrEmpty(productData.conservation)}
                    setState={setProductState}
                  />
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  spacing={4}
                  justifyContent="space-between"
                  className={classes.containerBtns}>
                  <Grid item md={7} lg={5} xs={12}>
                    <Grid container direction="column" spacing={6}>
                      <Grid item md={6} xs={12}>
                        <Grid container justifyContent="space-between">
                          <InputLabel>
                            <Typography variant="subtitle1">Información Nutricional</Typography>
                          </InputLabel>
                          {nutritionalTable ? (
                            <IconButton
                              style={{ float: 'right', color: '#f69223' }}
                              size="small"
                              onClick={() => {
                                setNutritionalTable()
                                document.getElementById('nutritional_info').value = ''
                              }}>
                              <HighlightOff />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Divider style={{ marginBottom: '1rem', marginTop: '.5rem' }} />
                        {!nutritionalTable ? (
                          <Input
                            setNutritionalTable={setNutritionalTable}
                            label="Cargar imagen"
                            id="nutritional_info"
                            idError="product.nutritional_info"
                            type="file_nutritional_info"
                            init={valueOrEmpty(productData.conservation)}
                            setState={setProductState}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item md={9} xs={12}>
                        {nutritionalTable ? (
                          <Paper>
                            <TableContainer>
                              <Table
                                style={{
                                  overflow: 'hidden',
                                  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.15)',
                                }}>
                                <TableHead>
                                  <TableRow>
                                    {nutritionalTable.nutritional_info.map(
                                      (row, index) =>
                                        index === 0 &&
                                        row.map((col, index) => (
                                          <TableCell
                                            key={col + index}
                                            style={{ background: 'white', color: '#92C94E', fontWeight: '700' }}
                                            contentEditable
                                            suppressContentEditableWarning={true}>
                                            {col}
                                          </TableCell>
                                        ))
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody id="tableBody">
                                  {nutritionalTable.nutritional_info.map(
                                    (row, index) =>
                                      index !== 0 && (
                                        <TableRow
                                          key={row + index}
                                          style={index % 2 !== 0 ? { background: '#F5F8F1' } : { background: 'white' }}>
                                          {row.map((col, index) => (
                                            <TableCell
                                              id={col + index}
                                              style={
                                                index === 0
                                                  ? { padding: '0.4rem 1rem' }
                                                  : { padding: '0.4rem 1rem', textAlign: 'center' }
                                              }
                                              key={col + index}
                                              contentEditable
                                              suppressContentEditableWarning={true}>
                                              {col}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <Grid container direction="column" spacing={6}>
                      <Grid item md={8} xs={12}>
                        <Grid container justifyContent="space-between">
                          <InputLabel>
                            <Typography variant="subtitle1">Ingredientes</Typography>
                          </InputLabel>
                          {ingredients ? (
                            <IconButton
                              style={{ float: 'right', color: '#f69223' }}
                              size="small"
                              onClick={() => {
                                setIngredients()
                                document.getElementById('ingredients').value = ''
                              }}>
                              <HighlightOff />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </Grid>

                        <Divider style={{ marginBottom: '1rem', marginTop: '.5rem' }} />
                        {!ingredients ? (
                          <Input
                            setIngredients={setIngredients}
                            label="Cargar imagen"
                            id="ingredients"
                            idError="product.ingredients"
                            type="file_ingredients"
                            init={valueOrEmpty(productData.conservation)}
                            setState={setProductState}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <>
                          <TextField
                            multiline
                            value={ingredients ? ingredients.ingredients : ''}
                            placeholder="Ingredientes: Azúcar, suero de leche, etc."
                            fullWidth
                            onChange={(e) => setIngredients({ ingredients: e.target.value })}></TextField>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item direction="column">
                <Grid item>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="categories"
                    clearOnBlur={false}
                    options={categoriesProducts ?? []}
                    getOptionLabel={(option) => option.category}
                    getOptionSelected={(option, value) => option?.category === value?.category}
                    filterSelectedOptions
                    value={categoriesSelected ? categoriesSelected : undefined}
                    onChange={(_, localState, action, selection) => {
                      handleProductCategories(localState, action, selection)
                      !block && setBlock(true)
                    }}
                    onInput={(e) => {
                      setNewCategory(e.target.value)
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Agregar categorias" />}
                    noOptionsText={'No se encontraron opciones, intenta agregar una nueva categoría'}
                  />
                </Grid>
                <Grid item>
                  <p
                    onClick={handleOpenAddCategory}
                    id="btnAddCategory"
                    style={{
                      marginTop: '1rem',
                      textDecoration: 'underline',
                      color: '#8ac541',
                      cursor: 'pointer',
                      display: 'inline-block',
                    }}>
                    Agregar nueva categoría
                  </p>
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid container item justifyContent="space-between" md={6} xs={12} direction="row">
                  <Grid item md={7} xs={12}>
                    <Input
                      label="Duración"
                      id="duration"
                      idError="product.duration"
                      type="number"
                      setState={setProductState}
                      init={valueOrEmpty(productData.duration)}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Input
                      label="Tipo de duración"
                      id="duration_type"
                      idError="product.duration_type"
                      type="select"
                      list={typeDuration}
                      setState={setProductState}
                      init={valueOrEmpty(productData.duration_type)}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Peso (en gramos)"
                    id="weight"
                    idError="product.weight"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.weight)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Unidades por caja"
                    id="sale_format"
                    idError="product.sale_format"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.sale_format)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Tiempo estimado de entrega (en días)"
                    id="delivery_time"
                    idError="product.delivery_time"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.delivery_time)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Estado del producto"
                    id="ID_stock"
                    idError="product.ID_stock"
                    type="select"
                    list={stockStatus}
                    setState={setProductState}
                    init={valueOrEmpty(productData.ID_stock)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Código de barra"
                    id="barcode"
                    setState={setProductState}
                    init={valueOrEmpty(productData.barcode)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item md={3} xs={6}>
                  <Input
                    label="Costo neto"
                    id="net_unit_cost"
                    idError="product.net_unit_cost"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.net_unit_cost)}
                    innerRef={i_net_unit_cost}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Input
                    label="Costo con IVA"
                    id="unit_cost"
                    idError="product.unit_cost"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.unit_cost)}
                    innerRef={i_unit_cost}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Input
                    label="Precio neto"
                    id="net_price_package"
                    idError="product.net_price_package"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.net_price_package)}
                    innerRef={i_net_price_package}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Input
                    label="Precio con IVA"
                    id="price_package"
                    idError="product.price_package"
                    type="number"
                    setState={setProductState}
                    init={valueOrEmpty(productData.price_package)}
                    innerRef={i_price_package}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Precio unitario al por mayor"
                    id="wholesale_unit_price"
                    idError="product.wholesale_unit_price"
                    type="number"
                    disabled
                    init={valueOrEmpty(productData.wholesale_unit_price)}
                    setState={setProductState}
                  />
                </Grid>
                <Grid item md={3} xs={8}>
                  <Input
                    label="Precio de venta sugerido"
                    id="suggested_sale_price"
                    idError="product.suggested_sale_price"
                    type="number"
                    init={valueOrEmpty(productData.suggested_sale_price)}
                    setState={setProductState}
                  />
                </Grid>
                <Grid item md={3} xs={4}>
                  <Input
                    label="Margen"
                    id="product_margin"
                    idError="product.suggested_sale_price"
                    type="number"
                    disabled
                    init={margin}
                    setState={setMargin}
                    endAdornment={<InputAdornment position="end">{'%'}</InputAdornment>}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item md={6} xs={12}>
                  <Input
                    label="Activo"
                    id="isActive"
                    idError="product.isActive"
                    type="checkbox"
                    init={Boolean(productData.isActive)}
                    setState={setProductState}
                  />
                </Grid>
              </Grid>
              {optionsResolution?.length > 0 && (
                <ListSelect
                  id="resolution"
                  label={'Resolución sanitaria'}
                  selectedValues={selectedResolution}
                  options={optionsResolution}
                  onChange={handleSelectedResolutions}
                  renderElement={(value) => (
                    <Chip
                      key={value}
                      label={`${
                        optionsResolution.find((resolution) => resolution.ID_resolution === value)?.number
                      } Emitido el:${
                        optionsResolution.find((resolution) => resolution.ID_resolution === value)?.emit_date
                      }`}
                      className={classes.chip}
                    />
                  )}
                  optionsRender={(item, index) => (
                    <MenuItem
                      key={item.ID_resolution}
                      value={item.ID_resolution}
                      classes={{ selected: classes.menuSelected }}>
                      <OptionSelect
                        data={item}
                        keysMap={{
                          number: 'Número',
                          verification_code: 'Código de verificación',
                          emit_date: 'Fecha res.',
                        }}
                      />
                    </MenuItem>
                  )}
                />
              )}
              {optionsCertificates?.length > 0 && (
                <ListSelect
                  id="certificate"
                  label={'Certificados del producto'}
                  multiple
                  selectedValues={selectedCertificates}
                  options={optionsCertificates}
                  onChange={handleSelectedCertificates}
                  renderElement={(value) => (
                    <Chip
                      key={value}
                      label={optionsCertificates.find((certificate) => certificate.ID_certification === value)?.type}
                      className={classes.chip}
                    />
                  )}
                  optionsRender={(item, index) => (
                    <MenuItem
                      key={item.ID_certification}
                      value={item.ID_certification}
                      classes={{ selected: classes.menuSelected }}>
                      <OptionSelect
                        data={item}
                        keysMap={{
                          type: 'Tipo',
                          description: 'Descripción',
                          valid_until: 'Válido hasta',
                        }}
                      />
                    </MenuItem>
                  )}
                />
              )}
              <Grid
                container
                item
                spacing={5}
                justifyContent="center"
                direction="row"
                style={{ marginTop: optionsCertificates?.length || optionsResolution?.length ? '2rem' : '0' }}>
                {params.id && (
                  <Grid item>
                    <Button
                      disabled={load}
                      color="secondary"
                      variant="text"
                      style={{ color: '#f69223' }}
                      onClick={handleReset}>
                      <Grid container spacing={2}>
                        <Grid item>Volver</Grid>
                      </Grid>
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    disabled={load}
                    color="secondary"
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() =>
                      !params.id
                        ? sendForm(productData)
                        : sendForm(
                            { ...productData, ID_resolution: selectedResolution },
                            `/api/v1/product/${productData.ID_product}`,
                            'PUT'
                          )
                    }>
                    <Grid container spacing={2}>
                      <Grid item>Enviar</Grid>
                      {load && (
                        <Grid item>
                          <CircularProgress color="primary" size={20} />
                        </Grid>
                      )}
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </>
  )
}
