import { Button, FormControl, Grid, InputLabel, makeStyles, Select, TextField, Tooltip } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import AlertContext from '../../context/AlertContext'
import { groupIdShipping } from '../../utils/groupIdShipping'
import Loader from '../Loader'
import { useGetCourier, useGetLazyShippingStatus } from '../../hooks/useGetShippingStatus'
import { useCreateShippingStatus, useUpdateShippingStatus } from '../../hooks/usePostShippingStatus'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '500px',
    padding: '0 2rem',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  iconAdd: {
    cursor: 'pointer',
  },
  helperText: {
    color: '#F59323',
  },
}))

export default function ModalInfoShipping({ handleClose, orderSelected = null, getData }) {
  const [loading, setLoading] = useState(true)
  const [infoShipping, setInfoShipping] = useState([
    {
      courier: { name: '', ID_courier: '' },
      tracking_number: '',
      status: '',
      ID_shipping_status: '',
    },
  ])
  // const [couriers, setCouriers] = useState([])
  const [invalidNumbers, setInvalidNumbers] = useState([])
  const classes = useStyles()
  const { id } = useParams()
  const infoRef = useRef(infoShipping)
  const { setAlert } = useContext(AlertContext)
  const { couriers } = useGetCourier()
  const { getShippingStatus } = useGetLazyShippingStatus()
  const { createShippingStatus } = useCreateShippingStatus()
  const { updateShippingStatus } = useUpdateShippingStatus()

  const handleSave = async () => {
    try {
      const body = {
        payload: orderSelected.detail.flatMap((detail) =>
          infoShipping.map((info) => ({
            tracking_number: info.tracking_number,
            status: info.status || null,
            ID_detail_order_note: detail.ID_detail_order_note,
            ID_courier: info.courier.ID_courier,
            created_at: new Date().toISOString(),
            modified_at: new Date().toISOString(),
          }))
        ),
      }
      const { data } = await createShippingStatus({ variables: body })

      if (data) {
        const {
          createShippingStatus: { invalid },
        } = data
        if (invalid.length) {
          setInvalidNumbers(invalid.map((number) => number.tracking_number))
        } else {
          setAlert({ open: true, message: 'Se guardaron los datos de envío', severity: 'success' })
          getData()
          handleClose()
        }
      } else {
        setAlert({ open: true, message: 'Error al guardar los datos de envío', severity: 'error' })
      }
    } catch (err) {
      throw new Error(err.message)
    }
  }

  const compareStatus = (obj1, obj2) => Object.keys(obj1).every((key) => obj1[key] === obj2[key])

  const handleUpdate = async () => {
    if (!compareStatus(infoRef.current, infoShipping)) {
      try {
        const body = {
          payload: infoShipping.flatMap((el) => {
            return el.ID_shipping_status.map((ID) => ({
              ID_shipping_status: ID,
              status: el.status,
              tracking_number: el.tracking_number,
              ID_courier: el.courier.ID_courier,
            }))
          }),
        }
        const { data, loading, error } = await updateShippingStatus({ variables: body })

        if (error) {
          setAlert({ open: true, message: 'Error al actualizar los datos de envío', severity: 'error' })
        } else {
          setAlert({ open: true, message: 'Se actualizaron los datos de envío', severity: 'success' })
        }

        getData()
        handleClose()
      } catch (err) {
        throw new Error(err.message)
      }
    } else {
      handleClose()
    }
  }

  const handleClick = () => {
    infoRef.current[0].ID_shipping_status ? handleUpdate() : handleSave()
  }

  const getInfo = async () => {
    const {
      data: {
        shippingStatus: { shipping },
      },
    } = await getShippingStatus({ variables: { idOrderNote: orderSelected.orderNote.ID_order_note } })
    const info = shipping.length
      ? groupIdShipping(shipping.filter((el) => el.producer.ID_producer === id))
      : infoRef.current
    setInfoShipping(info)
    infoRef.current = info
    return true
  }
  const addTrackerNumber = () => {
    setInfoShipping((prevState) => [
      ...prevState,
      {
        courier: infoShipping[0].courier,
        tracking_number: '',
        status: infoShipping[0].status,
        ID_shipping_status: '',
      },
    ])
  }
  const removeTrackerNumber = (index) => {
    setInfoShipping((prevStatus) => prevStatus.filter((info, indexFilter) => indexFilter !== index))
  }
  const changeTrackerNumber = (e, idShipping, index) => {
    setInfoShipping(
      infoShipping.map((info, indexInfo) =>
        index === indexInfo ? { ...info, tracking_number: e.target.value } : { ...info }
      )
    )
  }

  const status = [
    { name: 'En ruta', id: '0' },
    { name: 'Entregado', id: '1' },
    { name: 'Revisar', id: '2' },
  ]

  useEffect(() => {
    setLoading(true)
    const promises = []

    if (orderSelected.detail[0].order_status.ID_order_status === '5') {
      promises.push(getInfo())
    }

    Promise.all(promises)
      .then((responses) => responses)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      {loading ? (
        <Loader size="small" />
      ) : (
        <Grid container alignContent="center" direction="column" className={classes.root}>
          <Grid fullWidth>
            <h2 id="transition-modal-title">Datos del envío</h2>
          </Grid>
          <Grid container item spacing={1} xs={9}>
            <Grid item sm={12} md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="courier">Empresa de envío/Courier</InputLabel>
                <Select
                  labelId="courier-label"
                  disabled={orderSelected.detail[0].order_status.ID_order_status === '5'}
                  value={infoShipping[0]?.courier.ID_courier}
                  onChange={(e) =>
                    setInfoShipping(
                      infoShipping.map((info) => ({
                        ...info,
                        courier: couriers?.find((courier) => courier.ID_courier === e.target.value),
                      }))
                    )
                  }
                  native
                  inputProps={{ id: 'courier' }}>
                  <option value=""></option>
                  {couriers?.map((courier) => (
                    <option key={courier.ID_courier} value={courier.ID_courier}>
                      {' '}
                      {courier.name}{' '}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {infoShipping.map((info, index) => (
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                spacing={2}
                key={'trackingNumber' + index}>
                <Grid item md={10} sm={10}>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      label="N° de seguimiento"
                      id={'tracking_number-' + index}
                      value={info.tracking_number}
                      onChange={(e) => changeTrackerNumber(e, info.ID_shipping_status, index)}
                      helperText={
                        invalidNumbers.some((number) => info.tracking_number === number) ? 'Número invalido' : ''
                      }
                      error={invalidNumbers.some((number) => info.tracking_number === number)}
                    />
                  </FormControl>
                </Grid>
                {!Boolean(infoShipping[0]?.ID_shipping_status) &&
                  (infoShipping[0]?.courier.ID_courier === '2' || infoShipping[0]?.courier.ID_courier === '3') && (
                    <Grid item md={2} sm={2}>
                      {index === 0 ? (
                        <Tooltip title="Agregar otro N° de seguimiento">
                          <AddCircle
                            fontSize="medium"
                            color="secondary"
                            className={classes.iconAdd}
                            onClick={() => addTrackerNumber()}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Quitar N° de seguimiento">
                          <RemoveCircle
                            fontSize="medium"
                            color="primary"
                            className={classes.iconAdd}
                            onClick={() => removeTrackerNumber(index)}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  )}
              </Grid>
            ))}
            {infoShipping[0]?.courier.ID_courier !== '2' && infoShipping[0]?.courier.ID_courier !== '3' && (
              <Grid item sm={12} md={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="status">Estado de envío</InputLabel>
                  <Select
                    labelId="status-label"
                    value={infoShipping[0]?.status}
                    onChange={(e) =>
                      setInfoShipping(
                        infoShipping.map((info) => ({
                          ...info,
                          status: e.target.value,
                        }))
                      )
                    }
                    native
                    inputProps={{ id: 'status', value: infoShipping[0]?.status }}>
                    <option value=""></option>
                    {status.map((status) => (
                      <option
                        key={status.id}
                        value={status.name}
                        disabled={status.name === 'Entregado' && !infoRef.current[0].ID_shipping_status}>
                        {status.name}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText className={classes.helperText}>
                    {infoShipping[0].status === 'Entregado'
                      ? '*Se enviará un mensaje al cliente para confirmar la recepción de los productos.'
                      : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container item fullWidth justifyContent="center">
            <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={handleClick}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}
