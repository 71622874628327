import { LinearProgress, makeStyles, NativeSelect, withStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import EnhancedTableOrdersProducer from '../../components/orderListProducer/EnhancedTableOrdersProducer'
import { totalCostByProducer } from '../../utils/totalOrderByProducer'
import { useGetProducersList } from '../../hooks/useGetProducers'
import { useGetLazyOrderNotesByProducer } from '../../hooks/useGetOrderNotes'

export default function OrderProducer() {
  const [orders, setOrders] = useState([])
  const [producerSelected, setProducerSelected] = useState({ brand_name: null, ID_producer: null })
  const [loading, setLoading] = useState(false)
  const [errorOrders, setErrorOrders] = useState(null)
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(20)
  const [orderNoteCount, setOrderNoteCount] = useState(0)
  const [field, setField] = useState('created_at')
  const [order, setOrder] = useState('desc')
  const params = useParams()
  const history = useHistory()
  const { producers } = useGetProducersList({ isActive: null })
  const { requestOrderNotesByProducer } = useGetLazyOrderNotesByProducer()

  const getData = async () => {
    const {
      data: { orderNotes },
      loading,
      error,
    } = await requestOrderNotesByProducer({
      variables: {
        take,
        skip,
        field: field.toUpperCase(),
        order,
        all: true,
        q: '',
        idProducer: producerSelected.ID_producer,
      },
    })
    if (orderNotes) {
      const allOrdersByProducer = true
      setOrderNoteCount(orderNotes.orderNoteCount)
      totalCostByProducer(orderNotes.orderNote, allOrdersByProducer).then((result) => {
        setOrders(result)
      })
    }
    if (error) {
      console.error(error)
      setErrorOrders('Error en la petición')
      setOrders([])
    }
    setLoading(false)
  }

  const useStyles = makeStyles((theme) => ({
    containerSelect: {
      margin: '1rem',
      marginBottom: '2rem',
    },
  }))

  const classes = useStyles()

  const Select = withStyles({
    root: {
      padding: '1rem',
      backgroundColor: '#ffff',
      fontSize: '1.125rem',
    },
  })(NativeSelect)

  useEffect(() => {
    if (producerSelected.ID_producer) {
      setLoading(true)
      getData()
    } else {
      setOrders([])
    }
  }, [producerSelected.ID_producer, skip, take, order, field])

  useEffect(() => {
    setProducerSelected({ brand_name: params ? params.name : null, ID_producer: params ? params.id : null })
  }, [params])

  const selectProducer = (e) => {
    const name = e.target.options[e.target.options.selectedIndex].innerText
    const id = e.target.value
    setProducerSelected({
      ID_producer: id,
      brand_name: name,
    })
    history.push(`/pedidos-productor/${name}/${id}`)
  }

  return (
    <>
      <div className={classes.containerSelect}>
        <Select
          value={producerSelected.ID_producer}
          name="producer"
          onChange={(e) => selectProducer(e)}
          inputProps={{ 'aria-label': 'Selecciona un productor' }}>
          <option disabled selected>
            Selecciona un productor
          </option>
          {producers?.producer?.map((producer) => (
            <option key={producer.ID_producer} value={producer.ID_producer}>
              {producer.brand_name}
            </option>
          ))}
        </Select>
      </div>
      {loading ? (
        <LinearProgress style={{ width: '100%' }} />
      ) : (
        <EnhancedTableOrdersProducer
          orders={orders}
          setOrders={setOrders}
          loading={loading}
          producerSelected={producerSelected}
          errorOrders={errorOrders}
          skip={skip}
          setSkip={setSkip}
          take={take}
          setTake={setTake}
          orderNoteCount={orderNoteCount}
          field={field}
          setField={setField}
          order={order}
          setOrder={setOrder}
          getData={getData}
        />
      )}
    </>
  )
}
