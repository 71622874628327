import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useCreateStoreDirection() {
  const [createStoreDirection, { data, loading, error }] = useMutation(QUERY.DIRECTION.CREATE_FOR_STORE, {
    context: { clientName: 'apiStore' },
  })

  return { createStoreDirection, data, loading, error }
}
