import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Button, Grid, TextField } from '@material-ui/core'
import { LocalOffer, LocalShipping } from '@material-ui/icons'
import priceFormat from '../../utils/priceFormat'
import NewOrderContext from '../../context/NewOrderState'
import valueOrEmpty from '../../utils/valueorEmpty'

const TAX_RATE = 0.19

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    width: '3rem',
  },
  containerTable: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: '2rem',
  },
  containerTotalAmount: {
    marginTop: '1%',
  },
})

export default function SpanningTable({ productSelected, setOpenModal }) {
  const { orderStore, setOrderStore } = useContext(NewOrderContext)

  const classes = useStyles()

  const handleChange = (value) => setOrderStore({ ...orderStore, comment_by_order: value })

  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-start"
      justifyContent="center"
      className={classes.containerTotalAmount}>
      <Grid container item spacing={7} justifyContent="flex-end">
        <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<LocalShipping />}
            onClick={() => {
              setOpenModal({ show: true, component: 'ModalShipping' })
            }}>
            costo de despacho
          </Button>
        </Grid>
        <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<LocalOffer />}
            onClick={() => {
              setOpenModal({ show: true, component: 'ModalDiscount' })
            }}>
            Aplicar descuento
          </Button>
        </Grid>
      </Grid>
      <Grid container item spacing={7} justifyContent="flex-start">
        <Grid item md={6} xs={12}>
          <TextField
            id="noteOrder"
            label="Agregar nota al pedido"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange(e.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TableContainer component={Paper} className={classes.containerTable}>
            <Table className={classes.table} aria-label="spanning table">
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={6} />
                  <TableCell colSpan={2}>Neto</TableCell>
                  <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.totalNeto))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>IVA</TableCell>
                  <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                  <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.totalTax))}</TableCell>
                </TableRow>
                {(orderStore.discount > 0 || orderStore.shipping > 0) && (
                  <TableRow>
                    <TableCell colSpan={2}>Subtotal</TableCell>
                    <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.total))}</TableCell>
                  </TableRow>
                )}
                {orderStore.discount > 0 && (
                  <>
                    <TableRow>
                      <TableCell>Descuento</TableCell>
                      <TableCell align="right">
                        {orderStore.discountType == 'percent' ? `${orderStore.discount}%` : ''}
                      </TableCell>
                      <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.totalDiscount))}</TableCell>
                    </TableRow>
                  </>
                )}
                {orderStore.shipping > 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>Despacho</TableCell>
                    <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.shipping))}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">{priceFormat(valueOrEmpty(orderStore.totalDsc))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}
