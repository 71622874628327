import { Button, CircularProgress, Divider, Grid, InputLabel, Paper, Typography } from '@material-ui/core'
import MUIEditor from 'react-mui-draft-wysiwyg'
import configRte from '../../../utils/configRte'

export default function Ads({
  initialStateHome,
  initialStateCatalogue,
  adCatalogue,
  setAdCatalogue,
  adHomePage,
  setAdHomePage,
  saveAdCatalogue,
  saveAdHome,
  load,
}) {
  const handleCancel = (e) => {
    if (e.target.offsetParent.id === 'adHomePage') {
      setAdHomePage(initialStateHome)
    } else if (e.target.offsetParent.id === 'adCatalogue') {
      setAdCatalogue(initialStateCatalogue)
    }
  }
  return (
    <div>
      <Paper style={{ padding: '2rem' }}>
        <>
          <Grid container spacing={3}>
            <Typography variant="h5">Apariencia</Typography>
            <Grid item xs={12}>
              <InputLabel htmlFor={'id'}>
                <Typography variant="subtitle1">{'Anuncio en inicio'}</Typography>
              </InputLabel>
              <Divider style={{ marginBottom: '1rem', marginTop: '.5rem' }} />
              <div>
                <MUIEditor id={'id'} editorState={adHomePage} config={configRte} onChange={(e) => setAdHomePage(e)} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={load.adHomePage.status}
                color="primary"
                id="adHomePage"
                variant="contained"
                style={{ color: 'white', margin: '0.5rem' }}
                onClick={(e) => handleCancel(e)}>
                <Grid container spacing={2}>
                  <Grid item>Cancelar</Grid>
                  {load.adHomePage.type == 'cancel' && (
                    <Grid item>
                      <CircularProgress color="primary" size={15} />
                    </Grid>
                  )}
                </Grid>
              </Button>
              <Button
                disabled={load.adHomePage.status}
                color="secondary"
                variant="contained"
                style={{ color: 'white', margin: '0.5rem' }}
                onClick={() => saveAdHome()}>
                <Grid container spacing={2}>
                  <Grid item>Guardar</Grid>
                  {load.adHomePage.type == 'save' && (
                    <Grid item>
                      <CircularProgress color="primary" size={15} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </>
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel htmlFor={'id'}>
                <Typography variant="subtitle1">{'Anuncio en catálogo'}</Typography>
              </InputLabel>
              <Divider style={{ marginBottom: '1rem', marginTop: '.5rem' }} />
              <div>
                <MUIEditor id={'id'} editorState={adCatalogue} config={configRte} onChange={(e) => setAdCatalogue(e)} />
              </div>
            </Grid>
            <Grid item>
              <Button
                disabled={load.adCatalogue.status}
                color="primary"
                id="adCatalogue"
                variant="contained"
                style={{ color: 'white', margin: '0.5rem' }}
                onClick={(e) => handleCancel(e)}>
                <Grid container spacing={2}>
                  <Grid item>Cancelar</Grid>
                  {load.adCatalogue.type == 'cancel' && (
                    <Grid item>
                      <CircularProgress color="primary" size={15} />
                    </Grid>
                  )}
                </Grid>
              </Button>
              <Button
                disabled={load.adCatalogue.status}
                color="secondary"
                variant="contained"
                style={{ color: 'white', margin: '0.5rem' }}>
                <Grid container spacing={2} onClick={() => saveAdCatalogue()}>
                  <Grid item>Guardar</Grid>
                  {load.adCatalogue.type == 'save' && (
                    <Grid item>
                      <CircularProgress color="primary" size={15} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </>
      </Paper>
    </div>
  )
}
