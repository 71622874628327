import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  Checkbox,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import TransitionsModal from '../modal/ModalContainer'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { EnhancedTableHead } from './EnhancedTableHead'
import { CloudDownload, Edit } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useGetLazyProductById } from '../../hooks/useGetProducts'

function createData(idProduct, nameProduct, producer, stock, format, unitPrice, price, priceOffer, statusProduct) {
  return { idProduct, nameProduct, producer, stock, format, unitPrice, price, priceOffer, statusProduct }
}

const rows = (products) => {
  if (products && products.length !== 0) {
    const dataList = products.map((product) =>
      createData(
        product.ID_product,
        product.name,
        product.producer.brand_name,
        product.stock.ID_stock === '1' ? product.stock.status : 'Temporalmente sin stock',
        product.sale_format,
        product.wholesale_unit_price,
        product.price_package,
        product.offer_price,
        product.isActive ? 'Activo' : 'Inactivo'
      )
    )
    return dataList
  }
  return []
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  linearProgress: {
    width: '100%',
  },
}))

export const EnhancedTableProduct = ({
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSkip,
  rowsPerPage,
  setRowsPerPage,
  loading,
  products,
  setProducts,
  productCount,
  skip,
  setProductCount,
  setLoading,
  isSearched,
  productsFound,
  productsDownload,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [page, setPage] = useState(0)
  const [openModal, setOpenModal] = useState({ show: false, component: null })
  const [productSelected, setProductSelected] = useState([])
  const [pageProducts, setPageProducts] = useState([])
  const { requestProductById } = useGetLazyProductById()

  async function downloadSheet(id) {
    const {
      data: {
        productById: { product },
      },
    } = await requestProductById({ variables: { idProduct: id } })
    const response = await fetch(`${process.env.REACT_APP_API_STORE}api/v1/pdf/product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer-when-downgrade',
      },
      body: JSON.stringify([product]),
    })
    const file = await response.blob()
    const ObjURL = URL.createObjectURL(file)
    const a = document.getElementById(`sheet-${id}`)
    a.href = ObjURL
    a.download = `sheet-${product.name}.pdf`
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  }

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setSkip(0)
  }

  const handleClick = (name, idProduct) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    let newObject = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
      newObject = [...selectedId, { ID_product: idProduct, name }]
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newObject = selectedId.filter((el) => el.ID_product !== idProduct)
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newObject = selectedId.filter((el) => el.ID_product !== idProduct)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
      newObject = selectedId.filter((el) => el.ID_product !== idProduct)
    }

    setSelectedId(newObject)
    setSelected(newSelected)
  }
  useEffect(() => {
    setPage(0)
    isSearched && setPageProducts(productsFound.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [productsFound])

  useEffect(() => {
    isSearched && setPageProducts(productsFound.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [page, rowsPerPage])

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
    !isSearched && setSkip(rowsPerPage * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const isSelected = (nameProduct) => selected.indexOf(nameProduct) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows(products).length - page + 1 * rowsPerPage)

  const isProductsEmpty = (!isSearched && products.length !== 0) || (isSearched && pageProducts.length !== 0)
  return (
    <div className={classes.root}>
      <TransitionsModal
        selected={selected}
        openModal={openModal}
        setOpenModal={setOpenModal}
        productSelected={productSelected}
        setSelected={setSelected}
        products={products}
        setProducts={setProducts}
        skip={skip}
        setProductCount={setProductCount}
        setLoading={setLoading}
        rowsPerPage={rowsPerPage}
        orderBy={orderBy}
        order={order}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setSelected={setSelected}
          numSelected={selectedId.length}
          openModal={openModal}
          setOpenModal={setOpenModal}
          productsDownload={productsDownload}
        />
        <TableContainer>
          {loading ? <LinearProgress style={{ width: '100%' }} /> : ''}
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedId.length}
              order={order}
              productCount={productCount}
              orderBy={orderBy}
              products={products}
              onRequestSort={handleRequestSort}
              rowCount={products ? products.length : 0}
            />
            <TableBody id="tableBody">
              {isProductsEmpty ? (
                <>
                  {stableSort(rows(isSearched ? pageProducts : products), getComparator(order, orderBy))
                    .slice(0, rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.nameProduct)
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.idProduct}-${row.nameProduct}.${index}`}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={() => handleClick(row.nameProduct, row.idProduct)}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              selected={isItemSelected}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.nameProduct}
                          </TableCell>
                          <TableCell align="center">{row.producer}</TableCell>
                          <TableCell align="center">{row.stock}</TableCell>
                          <TableCell align="center">{row.format + ' un.'}</TableCell>
                          <TableCell align="center">
                            {'$' + new Intl.NumberFormat('de-DE').format(row.unitPrice)}
                          </TableCell>
                          <TableCell align="right">{'$' + new Intl.NumberFormat('de-DE').format(row.price)}</TableCell>
                          <TableCell align="right">
                            {row.priceOffer === null || row.priceOffer === 0
                              ? '-'
                              : '$' + new Intl.NumberFormat('de-DE').format(row.priceOffer)}
                          </TableCell>
                          <TableCell align="center">{row.statusProduct}</TableCell>
                          <TableCell align="center" padding="none">
                            <ButtonGroup>
                              <Tooltip title="Editar">
                                <Link to={`/editar-producto/${row.idProduct}`}>
                                  <IconButton aria-label="Editar">
                                    <Edit />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <Tooltip title="Descargar Ficha Técnica">
                                <IconButton aria-label="Ficha Técnica" onClick={() => downloadSheet(row.idProduct)}>
                                  <CloudDownload />
                                </IconButton>
                              </Tooltip>
                              <a id={`sheet-${row.idProduct}`} className="anchor-btn"></a>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <h3>{loading ? 'Cargando...' : 'Aún no se han ingresado productos'}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={isSearched ? productsFound.length : productCount ? productCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<label>Productos por pagina</label>}
        />
      </Paper>
    </div>
  )
}
