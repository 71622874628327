import { Button, Divider, Grid, IconButton, Paper, Typography, makeStyles } from '@material-ui/core'
import { Close, CloudUpload, Restore } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    borderRadius: '8px',
    transition: 'all ease 0.5s',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23ABAAB0' stroke-width='1' stroke-dasharray='24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  noInteractive: {
    pointerEvents: 'none',
  },
  dragging: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23e9e9e9' rx='8' ry='8' stroke='%23ABAAB0' stroke-width='4' stroke-dasharray='24' stroke-dashoffset='500' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
}))

export const InputUploadImage = ({ fileList = null, handleImageFile }) => {
  const fileURL = fileList && `${process.env.REACT_APP_S3_PRODUCER_URL}${fileList?.file_name}`
  const classes = useStyles()
  const inputContainer = useRef()
  const initialFileList = useRef()
  const inputFile = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [file, setFile] = useState(null)

  const getImageFile = async (fileURL) => {
    let response = await fetch(fileURL)
    let data = await response.blob()
    let file = data.type === 'text/html' ? null : new File([data], `${fileList?.file_name}`)
    initialFileList.current = file
    return setFile(file)
  }

  const handleDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }
  const handleDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
  }
  const handleDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const inputFileElement = inputFile.current
    inputFileElement.files = null
    inputFileElement.value = ''
    const files = event.dataTransfer.files
    if (files.length > 1) {
      return alert('Sólo puedes poner un archivo')
    }
    setFile(files[0])
    setIsDragging(false)
  }

  const handleFileInput = (event) => {
    setFile(event.target.files[0])
  }

  const handleRemoveFile = () => {
    handleImageFile({ toAdd: null, toRemove: fileList })
    setFile(null)
  }

  useEffect(() => {
    const inputContainerElement = inputContainer.current
    if (`${initialFileList.current?.size}${initialFileList.current?.name}` !== `${file?.size}${file?.name}`) {
      handleImageFile({ toAdd: file, toRemove: fileList })
    }
    if (inputContainerElement) {
      inputContainerElement.addEventListener('dragenter', handleDragEnter)
      inputContainerElement.addEventListener('dragover', handleDragOver)
      inputContainerElement.addEventListener('dragleave', handleDragLeave)
      inputContainerElement.addEventListener('drop', handleDrop)
      return () => {
        inputContainerElement.removeEventListener('dragenter', handleDragEnter)
        inputContainerElement.removeEventListener('dragover', handleDragOver)
        inputContainerElement.removeEventListener('dragleave', handleDragLeave)
        inputContainerElement.removeEventListener('drop', handleDrop)
      }
    }
  }, [file])

  const restoreImage = () => {
    getImageFile(fileURL)
  }

  useEffect(() => {
    if (fileURL) {
      getImageFile(fileURL)
    }
  }, [])

  return (
    <Grid container direction={file ? 'row' : 'column'} style={{ gap: '1rem' }}>
      {/* DRAG AND DROP ZONE */}
      {!file && (
        <Grid
          item
          className={`${isDragging ? classes.dragging : ''} ${classes.inputContainer}`}
          ref={inputContainer}
          xs>
          <Grid container direction="column" style={{ gap: '1rem', padding: '1.5rem' }}>
            <Grid item className={classes.noInteractive}>
              <Grid container justifyContent="center">
                <Grid item>
                  <CloudUpload color="primary" fontSize="inherit" style={{ fontSize: '48px' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.noInteractive}>
              {!isDragging ? (
                <Typography variant="h6" align="center">
                  Arrastra y suelta un archivo para comenzar a subirlo.
                </Typography>
              ) : (
                <Typography variant="h6">Suelta el archivo</Typography>
              )}
            </Grid>
            <Grid item className={classes.noInteractive}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <Divider />
                </Grid>
                <Grid item>
                  <Typography>O</Typography>
                </Grid>
                <Grid item xs>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={isDragging ? classes.noInteractive : ''}>
              <Grid container justifyContent="center">
                <Grid item>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileInput}
                    ref={inputFile}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ color: 'white', textTransform: 'none' }}>
                      Elige un archivo
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* IMAGE PREVIEW */}
      {Boolean(file) && (
        <Grid item xs>
          <Paper elevation={5} style={{ padding: '1.5rem 1rem', position: 'relative' }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <img
                  src={URL.createObjectURL(file)}
                  style={{
                    maxWidth: '300px',
                    objectFit: 'contain',
                    aspectRatio: '1/1',
                    width: '100%',
                    padding: '1.5rem 0',
                  }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ wordBreak: 'break-all' }}>
                  <strong>Nombre de archivo:</strong> {file.name}
                </Typography>
              </Grid>
              {`${initialFileList.current?.size}${initialFileList.current?.name}` !== `${file?.size}${file?.name}` && (
                <IconButton onClick={restoreImage} style={{ position: 'absolute', top: '0', left: '.5rem' }}>
                  <Restore color="primary" />
                </IconButton>
              )}
              <IconButton onClick={handleRemoveFile} style={{ position: 'absolute', top: '0', right: '.5rem' }}>
                <Close color="primary" />
              </IconButton>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}
