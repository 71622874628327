async function sendEmailOrder(order) {
  const body = {
    orderNote: {
      ...order.orderNote,
      modification_date: new Date(+order.orderNote.modification_date).toISOString(),
      creation_date: new Date(+order.orderNote.creation_date).toISOString(),
      shipping: null,
      discount: null,
    },
    detailOrderNotes: (order.detailOrderNotes ?? order.detail).map((detail) => {
      return {
        ID_detail_order_note: detail.ID_detail_order_note,
        qty: detail.qty,
        unit_price: detail.unit_price,
        sub_total: detail.sub_total,
        comment_by_product: detail.comment_by_product,
        historical_cost: detail.net_historical_cost,
        ID_producer: detail.ID_producer,
        ID_product: detail.ID_product,
        product: {
          ID_product: detail.product.ID_product,
          barcode: null,
          name: detail.product.name,
          sale_format: detail.product.sale_format,
          unit_cost: detail.product.unit_cost,
          image: detail.product.image,
          producer: {
            ID_producer: detail.product.producer.ID_producer,
            brand_name: detail.product.producer.brand_name,
            contact: [
              {
                email: detail.product.producer.contact[0].email,
                phone: detail.product.producer.contact[0].phone,
              },
            ],
          },
        },
        ID_order_note: detail.ID_order_note,
      }
    }),
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_STORE}api/v1/pdf/sendOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    })
    const res = await response.json()
    const status = response.status
    return { message: res.message, status }
  } catch (error) {
    console.error(error)
  }
}
export default sendEmailOrder
