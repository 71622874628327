import { Typography, Paper, Button, Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import CollapsibleTable from '../../components/addOrders/TableAddOrder'
import SpanningTable from '../../components/addOrders/TotalAmountOrder'
import { CheckCircleOutline, Edit } from '@material-ui/icons'
import InputsAddOrder from '../../components/addOrders/InputsAddOrder'
import TransitionsModal from '../../components/modal/ModalContainer'
import NewOrderContext from '../../context/NewOrderState'
import AlertContext from '../../context/AlertContext'
import setDetails from '../../utils/setDetails'
import UserContext from '../../context/UserContext'
import { usePostCreateorderNote } from '../../hooks/usePostOrderNote'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  container: {
    padding: '1.5rem',
  },
  ContainerAddOrder: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '2rem',
    gap: '1rem',
    padding: theme.spacing(0, 15),
  },
}))

export default function AddOrder() {
  const classes = useStyles()
  // Estado que guarda los productos llamados desde la API
  const [products, setProducts] = useState([])
  // Estado que guarda todos los productos disponibles en catalogo
  const [allProducts, setAllProducts] = useState([])
  const [loading, setLoading] = useState(false)
  // Estado para abrir modales
  const [openModal, setOpenModal] = useState({ show: false, component: null })
  // Estado que guarda los productos seleccionados para agregar al pedido
  const [productSelected, setProductSelected] = useState([])
  // Estado que guarda el producto seleccionado
  const [rowSelected, setRowSelected] = useState()
  // productos encontrados en Products
  const [productsFound, setProductsFound] = useState([])
  // productos seleccionados en tabla para agregar Productos (antes de agregar)
  const [selected, setSelected] = useState([])
  // estado si está o no cargando
  const [load, setLoad] = useState(false)
  //parametros ingresados en barra bucadora de productos
  const [paramsSearched, setParamsSearched] = useState([])
  //Estado global
  const { orderStore, setOrderStore } = useContext(NewOrderContext)
  const { setAlert } = useContext(AlertContext)
  const { user } = useContext(UserContext)
  const { createOrderNote } = usePostCreateorderNote()

  const handleConfirm = () => {
    if (!orderStore.name && orderStore.details.length == 0) {
      setAlert({ open: true, message: 'Debes seleccionar una tienda y agregar productos', severity: 'error' })
    } else if (!orderStore.name) {
      setAlert({ open: true, message: 'Debes seleccionar tienda', severity: 'error' })
    } else if (!orderStore.address) {
      setAlert({ open: true, message: 'Debes seleccionar una direccion de despacho', severity: 'error' })
    } else if (orderStore.details.length === 0) {
      setAlert({ open: true, message: 'Debes agregar productos', severity: 'error' })
    } else if (!orderStore.vendor) {
      setAlert({ open: true, message: 'Por favor, ingresa un vendedor', severity: 'error' })
      setLoad(false)
    } else {
      setOpenModal({ show: true, component: 'ModalSummary' })
    }
  }

  const handleDraft = async () => {
    setLoad(true)
    if (!orderStore.name && !orderStore.address) {
      setAlert({ open: true, message: 'Por favor, ingresa una tienda y dirección', severity: 'error' })
      setLoad(false)
    } else if (!orderStore.name) {
      setAlert({ open: true, message: 'Por favor, ingresa una tienda', severity: 'error' })
      setLoad(false)
    } else if (!orderStore.address) {
      setAlert({ open: true, message: 'Por favor, ingresa una dirección', severity: 'error' })
      setLoad(false)
    } else if (!orderStore.vendor) {
      setAlert({ open: true, message: 'Por favor, ingresa un vendedor', severity: 'error' })
      setLoad(false)
    } else {
      let payload = {
        number: null,
        neto: +orderStore.totalNeto.toFixed(0),
        iva: +orderStore.totalTax.toFixed(0),
        total: +orderStore.total.toFixed(0),
        total_order: +orderStore.totalDsc.toFixed(0),
        shipping: +orderStore?.shipping?.toFixed(0),
        type_discount: orderStore.discount !== '0' ? orderStore.discountType : null,
        discount: orderStore.discount !== '0' ? +(+orderStore.discount).toFixed(0) : null,
        comment_by_order: orderStore.comment_by_order,
        vendor: orderStore.vendor.ID_user,
        created_by: user.ID_user,
        ID_store: orderStore.ID_store,
        ID_order_status: '0',
        ID_store_direction: orderStore.address.ID_store_direction ?? '',
        modification_date: new Date().toISOString(),
        creation_date: new Date().toISOString(),
        detail_order_note: {
          create: setDetails(orderStore.details, orderStore.internalOrder) ?? [],
        },
      }
      if (!orderStore.internalOrder) {
        payload = {
          ...payload,
          order_note_producer: {
            ID_producer: orderStore.details[0].producer.ID_producer,
            producer_brand_name: orderStore.details[0].producer.brand_name,
          },
        }
      }
      const { data, loading, error } = await createOrderNote({
        variables: {
          payload,
        },
      })
      if (data) {
        setAlert({ open: true, message: 'Borrador ingresado', severity: 'success' })
        setOrderStore({
          ID_order_status: '0',
          details: [],
          discount: '0',
          discountType: 'percent',
          image: [],
        })
      }
      if (error) {
        setAlert({ open: true, message: 'Ha ocurrido un error.', severity: 'error' })
      }
      setLoad(false)
    }
  }

  useEffect(() => {
    setOrderStore({ ...orderStore, details: productSelected })
  }, [productSelected])

  useEffect(() => {
    const initialState = {
      ID_order_status: '0',
      details: [],
      discount: '0',
      discountType: 'percent',
      image: [],
    }
    if (JSON.stringify(orderStore) === JSON.stringify(initialState)) {
      setProductSelected([])
      setSelected([])
    }
  }, [orderStore])
  return (
    <Paper className={classes.container}>
      <TransitionsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        products={products}
        setProducts={setProducts}
        productsFound={productsFound}
        setProductsFound={setProductsFound}
        setProductSelected={setProductSelected}
        productSelected={productSelected}
        rowSelected={rowSelected}
        selected={selected}
        setSelected={setSelected}
        allProducts={allProducts}
        paramsSearched={paramsSearched}
        setParamsSearched={setParamsSearched}
      />
      {/* -------------TITULO -------------- */}
      <Typography variant="h1" className={classes.h1}>
        Ingresar pedido
      </Typography>
      {/* -------------CONTENEDOR DE INPUTS -------------- */}
      <div className={classes.ContainerAddOrder}>
        <InputsAddOrder openModal={openModal} setOpenModal={setOpenModal} />
      </div>
      {/* -------------CONTENEDOR DE AGREGAR PRODUCTOS (TABLA) -------------- */}
      <div className={classes.ContainerAddOrder}>
        <CollapsibleTable
          openModal={openModal}
          setOpenModal={setOpenModal}
          productSelected={productSelected}
          setRowSelected={setRowSelected}
          setProductSelected={setProductSelected}
        />
      </div>
      {/* -------------CONTENEDOR DE TOTALES $ -------------- */}
      <div className={classes.ContainerAddOrder}>
        <SpanningTable productSelected={productSelected} setOpenModal={setOpenModal} />
      </div>
      {/* -------------CONTENEDOR BOTON CONFIRMAR -------------- */}
      <div className={classes.ContainerAddOrder}>
        <Button
          variant="contained"
          color="primary"
          disabled={load}
          size="large"
          className={classes.button}
          startIcon={<Edit />}
          onClick={handleDraft}>
          <Grid container spacing={2}>
            <Grid item>Guardar borrador</Grid>
            {load && (
              <Grid item>
                <CircularProgress color="primary" size={20} />
              </Grid>
            )}
          </Grid>
        </Button>
        <Button
          disabled={load}
          variant="contained"
          color="secondary"
          size="large"
          className={classes.button}
          startIcon={<CheckCircleOutline />}
          onClick={handleConfirm}>
          Confirmar pedido
        </Button>
      </div>
    </Paper>
  )
}
