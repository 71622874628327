import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from '../utils/getToken'
import { Auth } from 'aws-amplify'

const apiProducer = new HttpLink({
  uri: process.env.REACT_APP_API_PRODUCER + 'graphql',
})
const apiStore = new HttpLink({
  uri: process.env.REACT_APP_API_STORE + 'graphql',
})
export const client = new ApolloClient({
  link: ApolloLink.split((operation) => operation.getContext().clientName === 'apiProducer', apiProducer, apiStore),
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
})

const tokenLocalStorage = localStorage.getItem('TOKEN_DASH')

updateToken(tokenLocalStorage)

export async function updateToken(newToken) {
  let token
  if (newToken) {
    token = newToken
  } else {
    await Auth.currentAuthenticatedUser()
    token = await getToken(Auth?.user?.attributes?.sub, Auth?.user?.attributes?.email)
  }
  await token
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })
  localStorage.setItem('TIME_TOKEN', new Date())
  const tokenLinkApiProducer = authLink.concat(client.link)
  return client.setLink(
    ApolloLink.split((operation) => operation.getContext().clientName === 'apiProducer', tokenLinkApiProducer, apiStore)
  )
}

window.addEventListener('focus', async () => {
  const timeToken = localStorage.getItem('TIME_TOKEN') ? new Date(localStorage.getItem('TIME_TOKEN')) : null
  const timeCurrent = new Date().getTime()
  const timeElapsed = timeToken && Math.round((timeCurrent - timeToken.getTime()) / (1000 * 60))
  if (timeToken && timeElapsed >= 210) {
    updateToken()
  }
})
