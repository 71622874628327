import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetZonesDelivery() {
  const { data, error, loading } = useQuery(QUERY.ZONES_DELIVERY.GET, {
    context: { clientName: 'apiProducer' },
    fetchPolicy: 'cache-and-network',
  })
  return { zones: data?.zones?.zone, error, loading }
}

export function useGetLazyZonesDeliveryByProducer() {
  const [requestZonesDeliveryByProducer, { data, error }] = useLazyQuery(QUERY.ZONES_DELIVERY.GET_BY_PRODUCER, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { requestZonesDeliveryByProducer, data, error }
}
