import { Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { EnhancedTableProduct } from '../../components/product/EnhancedTableProduct'
import * as XSLX from 'xlsx'
import priceFormat from '../../utils/priceFormat'
import { formatMinPurchase } from '../../utils/formatMinPurchase'
import { useGetLazyProducts, useGetProducts } from '../../hooks/useGetProducts'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1rem',
  },
}))

export const Product = () => {
  const [paramsSearched, setParamsSearched] = useState('')
  const query = paramsSearched?.length !== 0 ? paramsSearched.toLowerCase() : ''
  const [skip, setSkip] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [order, setOrder] = useState('ASC')
  const [orderBy, setOrderBy] = useState('NAME')
  const { data, loading: loadingProducts } = useGetProducts({
    take: query.length ? null : rowsPerPage,
    skip,
    all: true,
    field: orderBy.toUpperCase(),
    order: order.toUpperCase(),
    categories: null,
    q: query,
  })
  const [productCount, setProductCount] = useState()
  const [products, setProducts] = useState([])
  const [productsFound, setProductsFound] = useState([])
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { requestProducts } = useGetLazyProducts()

  useEffect(() => {
    if (data) {
      setProducts(data.product)
      setProductCount(query?.length ? data.product.length : data._count)
    }
    if (data && query.length) {
      setProductsFound(data.product)
    }
    setLoading(loadingProducts)
  }, [data, loadingProducts])

  const productsDownload = () => {
    const products = new Promise(async (resolve) => {
      if (!query.length) {
        const { data } = await requestProducts({
          variables: {
            take: null,
            all: true,
          },
        })
        resolve(data.products.product)
      } else {
        resolve(productsFound)
      }
    })
    products.then((productsDownload) => {
      // crear un libro
      const workbook = XSLX.utils.book_new()
      // crear columnas basadas en un arreglo de objetos
      const rows = productsDownload.map((product) => ({
        Producto: product.name,
        Marca: product.producer.brand_name,
        Stock: product.stock.ID_stock === '1' ? 'En stock' : 'Temporalmente sin stock',
        'Formato de venta': 'Caja ' + product.sale_format ? product.sale_format + ' un.' : ' ',
        'Precio unitario neto': priceFormat((product.net_price_package / product.sale_format).toFixed(2)),
        'Precio unitario c/iva': priceFormat(product.wholesale_unit_price),
        'Precio de caja neto': priceFormat(product.net_price_package),
        'Precio de caja c/iva': priceFormat(product.price_package),
        Estado: product.isActive ? 'Activo' : 'Inactivo',
        'Pedido minimo (Productor)': formatMinPurchase(product.producer),
        Categorias: product.product_category.map((item) => item.category_product.category).join(', '),
        'Precio sugerido de venta': priceFormat(product.suggested_sale_price),
        'Margen estimado':
          Math.round(
            (product.suggested_sale_price * 100) /
              (product.offer_price / product.sale_format || product.wholesale_unit_price) -
              100
          ) + '%',
        'Peso de producto': product.weight ? product.weight + ' grs.' : ' ',
        'Enlace a producto': 'https://www.alimentos.comes.cl/' + product.slug,
        'Imagen de producto': product.product_image[0]
          ? `${process.env.REACT_APP_S3_URL}${product.product_image[0]?.file_image}`
          : ' ',
      }))
      // crear la hoja
      const worksheet = XSLX.utils.json_to_sheet(rows)
      //Define el ancho de las columnas
      worksheet['!cols'] = [
        { wch: 30 }, //Producto
        { wch: 30 }, //Marca
        { wch: 10 }, //Stock
        { wch: 15 }, //Formato de venta
        { wch: 17 }, //Precio unitario neto
        { wch: 17 }, //Precio unitario c/iva
        { wch: 17 }, //Precio de caja neto
        { wch: 17 }, //precio de caja c/iva
        { wch: 10 }, //Estado
        { wch: 22 }, //Pedido minimo
        { wch: 25 }, //Categorias
        { wch: 18 }, //Precio sugerido de venta
        { wch: 15 }, //Margen estimado
        { wch: 15 }, //peso de producto
        { wch: 35 }, //Enlace de producto
        { wch: 35 }, //Imagen de producto
      ]
      //unir hoja con el libro
      XSLX.utils.book_append_sheet(workbook, worksheet, 'Productos')
      //generar el archivo
      XSLX.writeFile(workbook, 'listado_de_productos.xlsx')
    })
  }

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Buscar producto o productor"
            type="search"
            variant="outlined"
            onChange={(event) =>
              setTimeout(() => {
                setParamsSearched(event.target.value)
                setSkip(0)
              }, 1500)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <EnhancedTableProduct
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setSkip={setSkip}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            loading={loading}
            products={products}
            productsFound={productsFound}
            setProducts={setProducts}
            productCount={productCount}
            skip={skip}
            setProductCount={setProductCount}
            setLoading={setLoading}
            isSearched={Boolean(paramsSearched)}
            productsDownload={productsDownload}
          />
        </Grid>
      </Grid>
    </>
  )
}
