import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetAuthToken() {
  const [authToken, { data, loading, error }] = useMutation(QUERY.TOKEN.GET, {
    context: { clientName: 'apiProducer' },
  })

  return { authToken, data, loading, error }
}
