import { gql } from '@apollo/client'

const STORES = {
  GET: gql`
    query GetStores {
      stores {
        store {
          ID_store
          name
          bussines_name
          rut
          email
          phone
          web
          contact_name
          work_position
          isActive
          isDeleted
          isCompany
          created_at
          modified_at
          update_by_user
        }
      }
    }
  `,
}
export default STORES
