import { useParams } from 'react-router-dom'
import {
  Paper,
  Typography,
  makeStyles,
  withStyles,
  Grid,
  Button,
  Chip,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import CheckZones from './CheckZones'
import { useContext, useEffect, useState } from 'react'
import { Cancel, LibraryAdd, Loop, Save, Info, Check, ArrowBackIos } from '@material-ui/icons'
import AlertContext from '../../../context/AlertContext'
import { useGetLazyZonesDeliveryByProducer, useGetZonesDelivery } from '../../../hooks/useGetZonesDelivery'
import { usePostAddZonesDelivery, usePostRemoveZoneDelivery } from '../../../hooks/usePostZonesDelivery'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '100rem',
    padding: '2rem',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '2rem',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  iconBack: {
    marginBottom: '1.5rem',
    cursor: 'pointer',
    fontSize: '1.2rem',
  },
}))
const CleanButton = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    float: 'right',
    margin: '0.5rem',
    marginBottom: '1rem',
  },
})(Button)

const ActionButton = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
})(Button)

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[1],
    border: '1px solid #fbd504',
    borderRadius: '12px',
    marginTop: '5px',
  },
}))(Tooltip)
const ChipException = withStyles((theme) => ({
  root: {
    backgroundColor: '#fbd504',
  },
}))(Chip)

export const DeliveryZones = () => {
  const classes = useStyles()
  const { setAlert } = useContext(AlertContext)
  const params = useParams()
  const [state, setState] = useState({
    selected: [],
  })
  const [zonesProducer, setZonesProducer] = useState({
    selected: [],
  })
  const [loading, setLoading] = useState(false)
  const { zones: regionsOptions } = useGetZonesDelivery()
  const { requestZonesDeliveryByProducer } = useGetLazyZonesDeliveryByProducer()
  const { addZonesDelivery } = usePostAddZonesDelivery()
  const { removeZoneDelivery } = usePostRemoveZoneDelivery()

  const cleanSelection = () => {
    setState({
      selected: [],
    })
  }
  const addMunicipality = (e, onlyMunicipality, region) => {
    const { municipality, ...onlyRegion } = region
    if (e.target.checked) {
      const firstAdd = [...state.selected, { ...onlyRegion, municipality: [onlyMunicipality] }]
      if (state.selected.length) {
        const exist = state.selected.some((el) => el.name == region.name)
        const add = state.selected.map((el) =>
          el.name == region.name ? { ...el, municipality: [...el.municipality, onlyMunicipality] } : el
        )
        exist
          ? setState({
              ...state,
              selected: [...add],
            })
          : setState({
              ...state,
              selected: firstAdd,
            })
      } else {
        setState({
          ...state,
          selected: firstAdd,
        })
      }
    } else {
      let remove = state.selected.map((region) => {
        const currentSelected = region.municipality.filter((el) => el.name != onlyMunicipality.name)
        return region.name == onlyRegion.name ? { ...onlyRegion, municipality: [...currentSelected] } : region
      })

      remove = remove.filter((el) => el.municipality.length > 0)

      setState({
        ...state,
        selected: remove,
      })
    }
  }
  const addRegion = (e, region) => {
    if (e.target.checked) {
      setState({ ...state, selected: [...state.selected, region] })
    } else {
      const currentSelected = state.selected.filter((el) => el.name != region.name)
      setState({ ...state, selected: currentSelected })
    }
  }

  const selectAllregions = () => {
    setState({ ...state, selected: regionsOptions })
  }
  const cleanString = (string) => {
    return string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  }

  const selectedRegions = () => {
    const selectedAllMunicipalities = state.selected.map((regionSelected) => {
      const selectedAll = regionsOptions
        .map((region) => {
          if (cleanString(region.name) === cleanString(regionSelected.name)) {
            const isComplete = region.municipality.length == regionSelected.municipality.length
            let exception = []
            region.municipality
              .map((el) => {
                const isSelected = regionSelected.municipality.some(
                  (muni) => cleanString(el.name) === cleanString(muni.name)
                )
                if (!isSelected) {
                  exception = [...exception, el]
                  return exception
                }
              })
              .filter((el) => el !== undefined)[0]
            return { isComplete, exception }
          }
        })
        .filter((el) => el !== undefined)[0]
      return {
        isSelectedFull: selectedAll?.isComplete,
        ...regionSelected,
        exception: selectedAll?.exception,
      }
    })
    const selectedAllZones =
      state.selected.length == regionsOptions.length &&
      !selectedAllMunicipalities.some((el) => el.isSelectedFull == false)

    if (selectedAllZones) {
      return <Chip label={`Todo Chile continental`} icon={<Check />} color="secondary" />
    } else if (state.selected.length == 0) {
      return <Chip label={`No hay regiones seleccionadas`} color="default" />
    } else {
      return selectedAllMunicipalities.map((region) => {
        if (region.isSelectedFull) {
          return <Chip key={region.ID_region} label={`${region.name}`} color="secondary" icon={<Check />} />
        } else {
          return (
            <HtmlTooltip
              key={region.ID_region}
              interactive
              title={
                <>
                  <Typography color="inherit"> Excepción</Typography>- Comunas <b>{'no'}</b> seleccionadas:
                  <ul>
                    {region?.exception?.map((el) => (
                      <li key={el.ID_municipality}> {el.name}</li>
                    ))}
                  </ul>
                </>
              }>
              <ChipException key={region.ID_region} label={`${region.name}`} icon={<Info />} />
            </HtmlTooltip>
          )
        }
      })
    }
  }
  useEffect(() => {
    getZonesProducer()
  }, [])

  useEffect(() => {
    setState(zonesProducer)
  }, [zonesProducer])

  const getZonesProducer = async () => {
    const { data, loading, error } = await requestZonesDeliveryByProducer({
      variables: {
        idProducer: params.id,
      },
    })
    if (data) {
      const value = JSON.parse(data.producerWithProducts.deliveryZones.zones)
      let initialState = []
      for (const key in value) {
        const newMunicipality = value[key].map((muni) => {
          return {
            name: muni.municipality,
            ID_municipality: muni.municipality,
            ID_delivery_zone: muni.ID_delivery_zone,
          }
        })
        initialState.push({ name: key, ID_region: key, municipality: newMunicipality })
      }
      setZonesProducer({ ...zonesProducer, selected: [...initialState] })
    }
    if (error) {
      console.error(error)
      setZonesProducer(null)
    }
  }

  const postDeliveryZone = async () => {
    setLoading(true)

    const newMunicipalitiesSelected = []

    state.selected.map((region) => {
      const isNew = !zonesProducer.selected.some((regionProducer) => regionProducer.name == region.name)
      if (isNew) {
        region.municipality.map((el) => {
          return newMunicipalitiesSelected.push({ region: region.name, municipality: el.name })
        })
      } else {
        const listMunicipalitiesSelected = region.municipality.map((muni) => muni)
        const listMunicipalitiesProducer = zonesProducer.selected
          .map((zonesProducer) =>
            zonesProducer.name == region.name ? [...zonesProducer.municipality.map((muni) => muni)] : null
          )
          .filter((el) => el != null)[0]

        listMunicipalitiesSelected.map((muni) => {
          const isNewMunicipality = !listMunicipalitiesProducer.some((muniSelected) => muniSelected.name == muni.name)
          return isNewMunicipality
            ? newMunicipalitiesSelected.push({ region: region.name, municipality: muni.name })
            : ''
        })
      }
    })

    const deleteMunicipalitiesProducer = []

    zonesProducer.selected.map((regionProducer) => {
      const isDeleteRegion = !state.selected.some((region) => region.name == regionProducer.name)

      if (isDeleteRegion) {
        regionProducer.municipality.map((muni) => {
          return deleteMunicipalitiesProducer.push(muni)
        })
      } else {
        const listMunicipalitiesProducer = regionProducer.municipality.map((muni) => muni)
        const listMunicipalitiesSelected = state.selected
          .map((region) => (region.name == regionProducer.name ? [...region.municipality.map((muni) => muni)] : null))
          .filter((el) => el != null)[0]

        listMunicipalitiesProducer.map((muni) => {
          const isDeleteMunicipality = listMunicipalitiesSelected.some((muniSelected) => muniSelected.name == muni.name)
          if (!isDeleteMunicipality) {
            return deleteMunicipalitiesProducer.push(muni)
          }
        })
      }
    })
    let deleted = []
    let responses = { newZone: [], deleteZone: deleted }
    if (newMunicipalitiesSelected.length) {
      const {
        data: dataAddZonesDelivery,
        loading,
        error: errorAddZoneDelivery,
      } = await addZonesDelivery({
        variables: {
          payload: newMunicipalitiesSelected,
          idProducer: params.id,
        },
      })
      if (errorAddZoneDelivery) {
        setAlert({ open: true, message: 'Ocurrío un error al agregar las zonas de despacho', severity: 'error' })
      }
      if (dataAddZonesDelivery) {
        responses.newZone.push(dataAddZonesDelivery.createDeliveryZone.message)
      }
    }

    if (deleteMunicipalitiesProducer.length) {
      deleted = await Promise.all(
        deleteMunicipalitiesProducer.map(async (municipality) => {
          const statusDelete = []
          const {
            data: dataRemoveDeliveryZone,
            loading,
            error: errorRemoveDeliveryZone,
          } = await removeZoneDelivery({
            variables: {
              idDeliveryZone: municipality.ID_delivery_zone,
            },
          })
          if (errorRemoveDeliveryZone) {
            setAlert({ open: true, message: 'Ocurrío un error al remover una zona de despacho', severity: 'error' })
          }
          if (dataRemoveDeliveryZone) {
            statusDelete.push(dataRemoveDeliveryZone.deleteDeliveryZone.message)
          }
          return statusDelete
        })
      )
    }
    if (responses.newZone.length || deleted.length === deleteMunicipalitiesProducer.length) {
      setAlert({ open: true, message: 'Zonas de despacho actualizadas', severity: 'success' })
    } else {
      setAlert({
        open: true,
        message: 'Ocurrió un error con la actualización de zonas de despacho, inténtalo de nuevo',
        severity: 'error',
      })
    }
    getZonesProducer()
    setLoading(false)
  }

  return (
    <>
      <Paper className={classes.paper}>
        <ArrowBackIos className={classes.iconBack} onClick={() => history.go(-1)} />
        <Typography variant="h5" id="tableTitle" component="div" className={classes.title}>
          <b>Editar zonas de despacho</b>
        </Typography>
        <Typography variant="subtitle1" style={{ marginBottom: '0.725rem' }}>
          Productor: <b>{params.name}</b>
        </Typography>
        <Typography variant="subtitle1">Selecciona las regiones o comunas donde despacha el productor:</Typography>
        {regionsOptions ? (
          <>
            <CleanButton
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<Loop />}
              onClick={() => cleanSelection()}>
              Limpiar
            </CleanButton>
            <CleanButton
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<LibraryAdd />}
              onClick={() => selectAllregions()}>
              Seleccionar todo
            </CleanButton>

            <Grid container wrap="wrap" direction="row" justifyContent="flex-start" alignItems="flex-start">
              {regionsOptions.map((option) => (
                <CheckZones
                  option={option}
                  key={option.ID_region}
                  state={state}
                  setState={setState}
                  addMunicipality={addMunicipality}
                  addRegion={addRegion}
                  cleanString={cleanString}
                />
              ))}
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              xs={12}
              md={12}
              style={{ gap: '1.5rem', margin: '1rem 0' }}>
              <Typography variant="h6" style={{ marginTop: '1rem' }}>
                Zonas seleccionadas:
              </Typography>
              <div className={classes.root}>{selectedRegions()}</div>
            </Grid>
            <Grid container item justifyContent="center" xs={12} md={12} style={{ gap: '3rem', marginTop: '2rem' }}>
              <ActionButton
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<Cancel />}
                onClick={() => {
                  if (state !== zonesProducer) {
                    setState(zonesProducer)
                    setAlert({ open: true, message: 'Edición cancelada', severity: 'success' })
                  }
                }}>
                Cancelar
              </ActionButton>
              <ActionButton
                disabled={loading}
                className={classes.button}
                variant="contained"
                color="secondary"
                startIcon={<Save />}
                onClick={() => postDeliveryZone()}>
                <Grid container spacing={2}>
                  <Grid item>Guardar</Grid>
                  {loading && (
                    <Grid item>
                      <CircularProgress color="primary" size={20} />
                    </Grid>
                  )}
                </Grid>
              </ActionButton>
            </Grid>
          </>
        ) : (
          <LinearProgress style={{ width: '100%', marginTop: '2rem' }} />
        )}
      </Paper>
    </>
  )
}
