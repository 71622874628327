import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { QUERY } from '../services/queries'

const mapCertificate = (certification) => ({
  description: certification.type_certification.description,
  type: certification.type_certification.type,
  valid_until: new Date(+certification.valid_until).toLocaleDateString(),
  ID_certification: certification.ID_certification,
})

const mapResolution = (resolution) => ({
  number: resolution.number,
  verification_code: resolution.verification_code,
  emit_date: new Date(+resolution.emit_date).toLocaleDateString(),
  ID_resolution: resolution.ID_resolution,
})

export const useFormalizationDocuments = (APIProductData, productData) => {
  const APIcertificates = APIProductData?.productById?.product?.product_certification
  const [selectedResolution, setSelectedResolutions] = useState(null)
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const { data } = useQuery(QUERY.PRODUCERS.DOCUMENTS_BY_ID, {
    context: { clientName: 'apiProducer' },
    variables: { idProducer: productData.ID_producer },
    skip: !productData.ID_producer,
    onCompleted: (data) => {
      if (data?.resolutionByIdProducer?.resolution.length) {
        setSelectedResolutions(data?.resolutionByIdProducer?.resolution.map(mapResolution)[0].ID_resolution)
      }

      if (!data?.resolutionByIdProducer?.resolution.length) {
        setSelectedResolutions(null)
      }
    },
  })

  const [updateProductCertificationMutation] = useMutation(QUERY.CERTIFICATION.UPDATE_PRODUCT_CERTIFICATION, {
    context: { clientName: 'apiProducer' },
  })

  const handleSelectedResolutions = (e) => {
    setSelectedResolutions(e.target.value)
  }

  const handleSelectedCertificates = (e) => {
    setSelectedCertificates((prev) => [...new Set([...e.target.value])])
  }

  useEffect(() => {
    if (productData?.resolution) {
      setSelectedResolutions(productData.resolution.ID_resolution)
    }
    if (productData?.product_certification?.length) {
      setSelectedCertificates(
        productData.product_certification.map((certificate) => certificate?.certification?.ID_certification)
      )
    }
  }, [productData])

  const updateProductCertification = async (ID_product) => {
    const uploadPayload = selectedCertificates
      .filter(
        (certificate) =>
          !APIcertificates?.find((APICertificate) => certificate.ID_certification === APICertificate.ID_certification)
      )
      .map((certificate) => ({ ID_product, ID_certification: certificate }))

    const deletePayload =
      APIcertificates?.filter((APICertificate) => {
        return !selectedCertificates.find(
          (certificate) => certificate.ID_certification === APICertificate.ID_certification
        )
      }).map((certificate) => ({ ID_product_certification: certificate.ID_product_certification })) || []

    return await updateProductCertificationMutation({
      variables: {
        idProduct: ID_product,
        uploadPayload,
        deletePayload,
      },
    })
  }

  return {
    updateProductCertification,
    handleSelectedResolutions,
    handleSelectedCertificates,
    selectedResolution,
    selectedCertificates,
    optionsCertificates: data?.certificationByIdProducer?.certification?.map(mapCertificate) || [],
    optionsResolution: data?.resolutionByIdProducer?.resolution?.map(mapResolution) || [],
  }
}
