import { gql } from '@apollo/client'

const DIRECTION = {
  CREATE_FOR_STORE: gql`
    mutation Mutation($payload: createStoreDirection) {
      createStoreDirection(payload: $payload) {
        message
        storeDirection {
          ID_store
          ID_store_direction
          code_country
          country
          isPublic
          label
          latitude
          local_or_office
          longitud
          municipality
          number
          reference
          region
          sub_region
          street
        }
      }
    }
  `,
  GET_BY_ID_STORE: gql`
    query StoreDirectionById($idStore: ID!) {
      storeDirectionById(ID_store: $idStore) {
        storeDirection {
          ID_store_direction
          country
          code_country
          region
          sub_region
          municipality
          street
          number
          label
          local_or_office
          reference
          latitude
          longitud
          isPublic
          ID_store
        }
      }
    }
  `,
}
export default DIRECTION
