import { alpha, Divider, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useEffect, useState } from 'react'
import TableProductSearch from '../addOrders/TableProductSearch'
import { useGetProducts } from '../../hooks/useGetProducts'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  containerAddProduct: {
    width: '65rem',
    height: '32rem',
  },
  divider: {
    margin: theme.spacing(3),
  },
}))

export default function ModalAddProductOrder({
  products,
  setProducts,
  setProductsFound,
  productsFound,
  handleClose,
  setProductSelected,
  productSelected,
  selected,
  setSelected,
  allProducts,
  paramsSearched,
  setParamsSearched,
}) {
  const classes = useStyles()
  const [timerSearch, setTimerSearch] = useState()
  const [skip, setSkip] = useState(0)
  const [loading, setLoading] = useState(false)
  const [productCount, setProductCount] = useState(0)
  const [page, setPage] = useState(0)
  const query = paramsSearched.length !== 0 ? paramsSearched.toLowerCase() : ''
  const initVariables = {
    take: query.length ? null : 5,
    skip,
    all: true,
    field: 'NAME',
    order: 'ASC',
    categories: null,
    q: query,
  }
  const { data: dataProducts, loading: loadingProducts } = useGetProducts(initVariables)

  useEffect(() => {
    setLoading(loadingProducts)
    if (dataProducts) {
      query ? setProductsFound([]) : setProductsFound(dataProducts.product)
      setProductCount(dataProducts._count)
      setProducts([...dataProducts.product])
    }
  }, [dataProducts, loadingProducts])

  useEffect(() => {
    setParamsSearched([])
    setSelected(productSelected)
  }, [])

  useEffect(() => {
    setSkip(0)
    setPage(0)
  }, [paramsSearched])

  return (
    <>
      <div className={classes.containerAddProduct}>
        <h2 id="transition-modal-title">Agregar producto </h2>
        <TextField
          fullWidth
          id="outlined-search"
          label="Buscar producto"
          type="search"
          variant="outlined"
          onChange={(event) =>
            setTimeout(() => {
              setParamsSearched(event.target.value)
              setSkip(0)
            }, 1000)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Divider className={classes.divider} orientation="horizontal" />
        <TableProductSearch
          products={products}
          productsFound={productsFound}
          setProducts={setProducts}
          handleClose={handleClose}
          setProductSelected={setProductSelected}
          productSelected={productSelected}
          selected={selected}
          setSelected={setSelected}
          allProducts={allProducts}
          loading={loading}
          skip={skip}
          setSkip={setSkip}
          productCount={productCount}
          setProductCount={setProductCount}
          page={page}
          setPage={setPage}
          paramsSearched={paramsSearched}
        />
      </div>
    </>
  )
}
