import { toolbarControlTypes } from 'react-mui-draft-wysiwyg'

const config = {
  editor: {
    style: {
      padding: '1rem',
      margin: '0',
      borderRadius: '0 0 .5rem .5rem',
      minHeight: '10vh',
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
    },
  },
  toolbar: {
    style: {
      borderRadius: '.5rem .5rem 0 0',
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      borderBottom: 'none',
    },
    controls: [
      toolbarControlTypes.undo,
      toolbarControlTypes.divider,
      toolbarControlTypes.bold,
      toolbarControlTypes.italic,
      toolbarControlTypes.fontColor,
      toolbarControlTypes.divider,
      toolbarControlTypes.fontSize,
      toolbarControlTypes.divider,
      toolbarControlTypes.unorderedList,
      toolbarControlTypes.orderedList,
    ],
  },
}

export default config
