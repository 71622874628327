import { Button, Grid, makeStyles } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
}))

export default function Modalconfimacion({ handleClose, confirm, location }) {
  const classes = useStyles()
  const history = useHistory()

  function handleConfirm() {
    if (confirm) {
      confirm.current()
    }
    handleClose()
    history.push(location)
  }

  return (
    <>
      <Grid container direction="column" alignItems="center" className={classes.root}>
        <Error fontSize="large" color="primary" />
        <h2 id="transition-modal-title">Estás a punto de salir de la página</h2>
        <p id="transition-modal-description">Se perderán todos los datos escritos</p>
        <div>
          <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={handleConfirm}>
            Continuar
          </Button>
        </div>
      </Grid>
    </>
  )
}
