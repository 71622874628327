import { gql } from '@apollo/client'

const ORDER_NOTES = {
  GET_BY_ID_PRODUCER: gql`
    query OrderNoteByIdProducer($idProducer: ID, $idOrderNote: ID!) {
      orderNoteById(ID_producer: $idProducer, ID_order_note: $idOrderNote) {
        detail {
          ID_detail_order_note
          ID_order_note
          ID_producer
          ID_product
          comment_by_product
          net_historical_cost
          historical_cost
          order_status {
            ID_order_status
            status
          }
          qty
          sub_total
          unit_price
        }
        orderNote {
          ID_order_note
          creation_date
          vendor {
            ID_user
            name
          }
          modification_date
          number
          neto
          iva
          total
          shipping
          type_discount
          discount
          total_order
          comment_by_order
          order_status {
            ID_order_status
            status
          }
          store {
            ID_store
            name
            bussines_name
            rut
            email
            phone
            web
            contact_name
            work_position
            isActive
            isDeleted
            isCompany
            created_at
            modified_at
            update_by_user
          }
          store_direction {
            ID_store_direction
            country
            code_country
            region
            sub_region
            municipality
            street
            number
            label
            local_or_office
            reference
            latitude
            longitud
            isPublic
            ID_store
          }
        }
        productCount
      }
    }
  `,
  GET: gql`
    query OrderNotes(
      $take: Int
      $skip: Int
      $field: FieldOrderType
      $order: String
      $all: Boolean
      $q: String
      $idProducer: ID
    ) {
      orderNotes(take: $take, skip: $skip, field: $field, order: $order, all: $all, q: $q, ID_producer: $idProducer) {
        orderNote {
          detail {
            ID_detail_order_note
            ID_order_note
            ID_producer
            ID_product
            comment_by_product
            net_historical_cost
            historical_cost
            order_status {
              ID_order_status
              status
            }
            qty
            sub_total
            unit_price
          }
          order {
            ID_order_note
            creation_date
            vendor {
              ID_user
              name
            }
            order_note_producer {
              ID_order_note_producer
              ID_producer
              producer_brand_name
              number
            }
            invoice {
              ID_invoice
              amount
              balance
              issue_date
              due_date
              n_invoice
              payment
              status
            }
            modification_date
            number
            neto
            iva
            total
            shipping
            type_discount
            discount
            total_order
            comment_by_order
            order_status {
              ID_order_status
              status
            }
            store {
              ID_store
              name
              bussines_name
              rut
              email
              phone
              web
              contact_name
              work_position
              isActive
              isDeleted
              isCompany
              created_at
              modified_at
              update_by_user
            }
            store_direction {
              ID_store_direction
              country
              code_country
              region
              sub_region
              municipality
              street
              number
              label
              local_or_office
              reference
              latitude
              longitud
              isPublic
              ID_store
            }
          }
        }
        orderNoteCount
        totalOrder
      }
    }
  `,
  CREATE: gql`
    mutation CreateOrder($payload: createOrderNote) {
      createOrder(payload: $payload) {
        message
        order {
          ID_order_note
          creation_date
          vendor {
            ID_user
            name
          }
          modification_date
          number
          neto
          iva
          total
          shipping
          type_discount
          discount
          total_order
          comment_by_order
          order_status {
            ID_order_status
            status
          }
          store {
            ID_store
            name
            bussines_name
            rut
            email
            phone
            web
            contact_name
            work_position
            isActive
            isDeleted
            isCompany
            created_at
            modified_at
            update_by_user
          }
          store_direction {
            ID_store_direction
            country
            code_country
            region
            sub_region
            municipality
            street
            number
            label
            local_or_office
            reference
            latitude
            longitud
            isPublic
            ID_store
          }
          order_note_producer {
            ID_order_note_producer
            ID_producer
            number
            producer_brand_name
          }
        }
      }
    }
  `,
  GET_BY_PRODUCER: gql`
    query Query(
      $take: Int
      $skip: Int
      $field: FieldOrderType
      $order: String
      $all: Boolean
      $q: String
      $idProducer: ID
    ) {
      orderNotes(take: $take, skip: $skip, field: $field, order: $order, all: $all, q: $q, ID_producer: $idProducer) {
        orderNote {
          order {
            ID_order_note
            creation_date
            vendor {
              ID_user
              name
            }
            order_note_producer {
              ID_order_note_producer
              ID_producer
              producer_brand_name
              number
            }
            modification_date
            number
            neto
            iva
            total
            shipping
            type_discount
            discount
            total_order
            comment_by_order
            order_status {
              ID_order_status
              status
            }
            store {
              ID_store
              name
              bussines_name
              rut
              email
              phone
              web
              contact_name
              work_position
              isActive
              isDeleted
              isCompany
              created_at
              modified_at
              update_by_user
            }
            store_direction {
              ID_store_direction
              country
              code_country
              region
              sub_region
              municipality
              street
              number
              label
              local_or_office
              reference
              latitude
              longitud
              isPublic
              ID_store
            }
          }
          detail {
            ID_detail_order_note
            ID_order_note
            ID_producer
            ID_product
            comment_by_product
            net_historical_cost
            historical_cost
            order_status {
              ID_order_status
              status
            }
            qty
            sub_total
            unit_price
          }
        }
        orderNoteCount
        totalOrder
      }
    }
  `,
}
export default ORDER_NOTES
