import { gql } from '@apollo/client'

const ZONES_DELIVERY = {
  GET: gql`
    query Zones {
      zones {
        zone {
          ID_region
          name
          municipality {
            ID_municipality
            name
          }
        }
      }
    }
  `,
  GET_BY_PRODUCER: gql`
    query ProducerWithProducts($idProducer: ID) {
      producerWithProducts(ID_producer: $idProducer) {
        deliveryZones {
          zones
          _count
        }
      }
    }
  `,
  CREATE: gql`
    mutation Mutation($payload: [deliveryZonePayload!]!, $idProducer: ID!) {
      createDeliveryZone(payload: $payload, ID_producer: $idProducer) {
        message
      }
    }
  `,
  REMOVE: gql`
    mutation DeleteDeliveryZone($idDeliveryZone: ID!) {
      deleteDeliveryZone(ID_delivery_zone: $idDeliveryZone) {
        message
      }
    }
  `,
}
export default ZONES_DELIVERY
