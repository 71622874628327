import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetShippingStatus(idOrderNote) {
  const { data, error, loading } = useQuery(QUERY.SHIPPING_STATUS.GET, {
    variables: { idOrderNote },
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { data, error, loading }
}

export function useGetLazyShippingStatus() {
  const [getShippingStatus, { data, error }] = useLazyQuery(QUERY.SHIPPING_STATUS.GET, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { getShippingStatus, data, error }
}

export function useGetCourier() {
  const { data, error, loading } = useQuery(QUERY.SHIPPING_STATUS.GET_COURIER, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { couriers: data?.courier?.couriers, error, loading }
}
