import { Button, Grid, Input, InputAdornment, makeStyles, Typography, MenuItem, TextField } from '@material-ui/core'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NewOrderContext from '../../context/NewOrderState'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '25vw',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
}))

export default function ModalDiscount({ handleClose }) {
  const classes = useStyles()
  const { orderStore, setOrderStore } = useContext(NewOrderContext)
  const [value, setValue] = useState(orderStore.discount)
  const [discountType, setDiscountType] = useState(orderStore.discountType)

  const discountTypes = [
    {
      value: 'percent',
      label: 'Porcentaje',
    },
    { value: 'amount', label: 'Monto' },
  ]

  function handleConfirm() {
    setOrderStore({ ...orderStore, discount: value, discountType: discountType })
    handleClose()
  }

  const handleDiscountType = (value) => {
    setDiscountType(value)
    setValue('0')
  }

  const handleChange = (inputValue) => {
    const inputWoZero = inputValue.replace(/^0+/, '')
    if (inputWoZero == '' || inputWoZero < 0) {
      setValue('0')
    } else if (inputWoZero > 100 && discountType === 1) {
      setValue('100')
    } else {
      setValue(inputWoZero)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2}>
        <h2 id="transition-modal-title">Aplicar descuento</h2>
        <Grid container item alignItems="center" spacing={2}>
          <Grid item md={3}>
            <Typography variant="inherit">Descuento:</Typography>
          </Grid>
          <Grid item md={5}>
            <TextField
              fullWidth
              id="standard-select-currency"
              select
              value={discountType}
              onChange={(e) => handleDiscountType(e.target.value)}
              variant="standard">
              {discountTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4}>
            <Input
              id="discount"
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              endAdornment={discountType == 1 && <InputAdornment position="end">{'%'}</InputAdornment>}
              startAdornment={discountType == 2 && <InputAdornment position="start">{'$'}</InputAdornment>}
              aria-describedby="apply-discount"
              type="number"
              inputProps={{
                'aria-label': 'apply-discount',
              }}></Input>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item>
            <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              onClick={handleConfirm}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
