import { useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetOrderStatus() {
  const { data, error, loading } = useQuery(QUERY.ORDER_STATUS.GET, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { data, error, loading }
}
