import React, { useEffect, useState } from 'react'
import EnhancedTableOrders from '../../components/orderList/EnhancedTableOrders'
import { useGetOrderNotes } from '../../hooks/useGetOrderNotes'
import { Grid, InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const OrderList = () => {
  const [paramsSearched, setParamsSearched] = useState('')
  const query = paramsSearched?.length !== 0 ? paramsSearched.toLowerCase() : ''
  //se guardan los productos obtenidos desde la api en el estado
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderNoteCount, setOrderNoteCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(20)
  const [field, setField] = useState('created_at')
  const [order, setOrder] = useState('desc')
  const variablesInitial = {
    take: query.length ? null : take,
    skip,
    field: field.toUpperCase(),
    idProducer: null,
    order,
    all: true,
    q: query,
  }
  const { orderNotes, error, loading: loadingOrderNotes } = useGetOrderNotes(variablesInitial)
  useEffect(() => {
    if (orderNotes) {
      setOrders(orderNotes.orderNote)
      setOrderNoteCount(orderNotes.orderNoteCount)
    }
    setLoading(loadingOrderNotes)
  }, [orderNotes, loadingOrderNotes])

  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
      <Grid item sm={12}>
        <TextField
          fullWidth
          id="outlined-search"
          label="Buscar pedido"
          type="search"
          variant="outlined"
          onChange={(event) =>
            setTimeout(() => {
              setParamsSearched(event.target.value)
              setSkip(0)
            }, 1500)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <EnhancedTableOrders
          orders={orders}
          setOrders={setOrders}
          loading={loading}
          orderNoteCount={orderNoteCount}
          skip={skip}
          setSkip={setSkip}
          take={query.length ? null : take}
          setTake={setTake}
          setField={setField}
          field={field}
          setOrder={setOrder}
          order={order}
          variablesInitial={variablesInitial}
        />
      </Grid>
    </Grid>
  )
}
