export default function setDetails(details, internalOrder) {
  const newDetails = details.map((detail) => {
    return {
      qty: detail.qty,
      unit_price: detail.net_price_package,
      sub_total: detail.qty * detail.net_price_package,
      ID_product: detail.ID_product,
      net_unit_cost: internalOrder ? detail.net_unit_cost : +(detail.net_price_package * 0.9).toFixed(2),
      ID_producer: detail.producer.ID_producer,
    }
  })
  return newDetails
}
