export const fullOrderNote = async (orderNote, requestProducstByIds) => {
  const ids = orderNote.detail.map((item) => item.ID_product)
  const {
    data: {
      productById: { products },
    },
  } = await requestProducstByIds({ variables: { ids } })
  const fullDetail = orderNote.detail.map((element) => {
    return { ...element, product: products.find((item) => element.ID_product === item.ID_product) }
  })
  return { orderNote: orderNote.orderNote ?? orderNote.order, detail: fullDetail }
}
