import { useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'
import { useParams } from 'react-router-dom'

export function useGetProducersById() {
  const { id } = useParams()

  const { data, error, loading } = useQuery(QUERY.EDITPRODUCER.GETDATA, {
    context: { clientName: 'apiProducer' },
    variables: { idProducer: id, idCategoryType: '1', idCategoryTypeTwo: '2', idCategoryTypeThree: '3' },
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  })

  return {
    certificate_categories: data?.typeCertification.typeCertifications,
    producer: data?.producerWithProducts.producer,
    products: { productCount: data?.producerWithProducts.productCount },
    deliveryZones: data?.producerWithProducts.deliveryZones,
    error,
    stateCategories: [
      ...(data?.categoriesByIdType.categories || []),
      ...(data?.categoriesByIdType_Two.categories || []),
      ...(data?.categoriesByIdType_Three.categories || []),
    ],
    initialCertificationFiles:
      data?.certificationByIdProducer?.certification?.map((certification) => ({
        id: certification.ID_certification,
        file: certification.url ? { name: certification.url, url: certification.url } : null,
        details: {
          Nombre: certification.type_certification.type,
          Descripción: certification.type_certification.description,
          'Válido hasta': +certification.valid_until,
          ID_type_certification: certification.type_certification.ID_type_certification,
        },
        fromApi: true,
      })) || [],
    initialResolutionFiles:
      data?.resolutionByIdProducer?.resolution?.map((resolution) => ({
        id: resolution.ID_resolution,
        file: resolution.url ? { name: resolution.url, url: resolution.url } : null,
        details: {
          Número: resolution.number,
          'Fecha de resolución': +resolution.emit_date,
          'Código de verificación': resolution.verification_code,
        },
        fromApi: true,
      })) || [],
    productsInfo: { ...data?.productInfoByProducer, ...data?.productsInfoByIdProducer.productsInfo[0] },
    loading,
  }
}
