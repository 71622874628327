import Loader from '../../components/Loader'
import Ads from '../../components/pages-setups/stores/Ads'
import Discounts from '../../components/pages-setups/stores/Discounts'
import SuggestedProducts from '../../components/pages-setups/stores/SuggestedProducts'
import { useSetup } from '../../hooks/useSetup'

export default function SetupStore() {

  const { state: {
    ads,
    discounts,
    suggestedProducts,
    products
  },
    initialState,
    modifiers,
    mutation,
    loading,
    updaters
  } = useSetup()

  return (
    <>
      {loading.general ?
        <Loader /> :
        <>
          <Ads
            adCatalogue={ads.adCatalogue}
            setAdCatalogue={modifiers.adCatalogue}
            adHomePage={ads.adHomePage}
            setAdHomePage={modifiers.adHome}
            initialStateHome={initialState.ads.adHome}
            initialStateCatalogue={initialState.ads.adCatalogue}
            saveAdHome={updaters.adHome}
            saveAdCatalogue={updaters.adCatalogue}
            load={loading}
            setLoad={modifiers.loading}
          />
          <Discounts
            saveDiscount={updaters.firstPurchase}
            initialStateDsc={initialState.discounts.firstPurchase}
            dscFirstPurchase={discounts.firstPurchase}
            setDscFirstPurchase={modifiers.firstPurchase}
            load={loading}
          />
          <SuggestedProducts
            suggestedProducts={suggestedProducts}
            initialState={initialState.suggestedProducts}
            setSuggestedProducts={modifiers.suggestedProducts}
            load={loading}
            saveSuggestedProducts={mutation.postFeatured}
            products={products}
            setProducts={modifiers.products}
          />
        </>
      }
    </>
  )
}
