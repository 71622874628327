import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function usePostDeleteCategoryProduct() {
  const [deleteCategoryProduct, { data, loading, error }] = useMutation(QUERY.CATEGORIES.DELETE_PRODUCT, {
    context: { clientName: 'apiProducer' },
  })

  return { deleteCategoryProduct, data, loading, error }
}
export function usePostCreateCategoryProduct() {
  const [createCategoryProduct, { data, loading, error }] = useMutation(QUERY.CATEGORIES.CREATE_CATEGORY_PRODUCT, {
    context: { clientName: 'apiProducer' },
  })

  return { createCategoryProduct, data, loading, error }
}
