import './Loader.css'
export default function Loader({text = 'Cargando', size=''}) {
  return (
    <>
      <div className={`loader ${size}`}>
        {text}
        <div className="circle-container">
          <div className="circle-item"></div>
          <div className="circle-item"></div>
          <div className="circle-item"></div>
        </div>
      </div>
    </>
  )
}
