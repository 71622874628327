export default async function sendWsStore(order) {
  return await fetch(`${process.env.REACT_APP_API_WSP}/whatsapp-messages/send-template`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN_WASAPI}`,
    },
    body: createBody(order),
  })
}

const createBody = (order) => {
  return JSON.stringify({
    recipients: order.orderNote.store.phone.replace('+', ''),
    template_id: `${process.env.REACT_APP_ID_TEMPLATE_ORDER_CONFIRMATION_WASAPI}`,
    phoneSelect: `${process.env.REACT_APP_NUMBER_WASAPI_CLIENT}`,
    contact_type: 'phone',
    chatbot_status: 'disable',
    conversation_status: 'unchanged',
    body_vars: [
      {
        text: '{{1}}',
        val: order.orderNote.store.name,
      },
      {
        text: '{{2}}',
        val: order.orderNote.number,
      },
    ],
  })
}
