import { gql } from '@apollo/client'

const FULL_FRAGMENT = gql`
  fragment FullProduct on Product {
    ID_product
    barcode
    name
    slug
    wholesale_unit_price
    unit_cost
    sale_format
    weight
    description
    duration
    duration_type
    suggested_sale_price
    price_package
    benefit
    conservation
    stock_quantity
    offer_price
    delivery_time
    isActive
    isDeleted
    created_at
    modified_at
    modified_by
    ingredients
    nutritional_info
    packaging
    net_price_package
    net_unit_cost
    stock {
      ID_stock
      status
    }
  }
`
const EXCEL_FRAGMENT = gql`
  fragment ExcelProduct on Product {
    ID_product
    name
    slug
    wholesale_unit_price
    unit_cost
    sale_format
    weight
    suggested_sale_price
    price_package
    offer_price
    delivery_time
    isActive
    packaging
    net_price_package
    net_unit_cost
    stock {
      ID_stock
      status
    }
  }
`

const CATEGORY_FRAGMENT = gql`
  fragment ProductCategory on Product {
    product_category {
      ID_product_category
      ID_product
      ID_category_product
      category_product {
        ID_category_product
        category
      }
    }
  }
`
const IMAGE_FRAGMENT = gql`
  fragment ProductImage on Product {
    product_image {
      ID_product_image
      file_image
      name_image
      alt
      isMain
      ID_product
    }
  }
`
const PRODUCT = {
  EXCEL_FRAGMENT,
  CATEGORY_FRAGMENT,
  FULL_FRAGMENT,
  IMAGE_FRAGMENT,
}
export default PRODUCT
