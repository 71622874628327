import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetStores() {
  const { data, error, loading } = useQuery(QUERY.STORES.GET, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { stores: data?.stores?.store, error, loading }
}
