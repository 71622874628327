import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetOrderNotes({
  take = 20,
  skip = 0,
  field = 'CREATED_AT',
  order = 'desc',
  all = true,
  q = '',
  idProducer = null,
}) {
  const { data, error, loading } = useQuery(QUERY.ORDER_NOTES.GET, {
    variables: {
      take,
      skip,
      field,
      order,
      all,
      q,
      idProducer,
    },
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { orderNotes: data?.orderNotes, error, loading }
}
export function useGetLazyOrderNotes() {
  const [requestGetOrdersNotes, error, loading] = useLazyQuery(QUERY.ORDER_NOTES.GET, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { requestGetOrdersNotes, error, loading }
}
export function useGetLazyOrderNoteByProducer() {
  const [requestOrderNoteByProducer, { data, error }] = useLazyQuery(QUERY.ORDER_NOTES.GET_BY_ID_PRODUCER, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { requestOrderNoteByProducer, data, error }
}

export function useGetLazyOrderNotesByProducer() {
  const [requestOrderNotesByProducer, { data, error }] = useLazyQuery(QUERY.ORDER_NOTES.GET_BY_PRODUCER, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { requestOrderNotesByProducer, data, error }
}
