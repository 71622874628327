import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../context/AlertContext'
import { useGetProducersById } from './useProducerById'
import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

const initialInputsFile = {
  resolution: {
    Número: '',
    'Fecha de resolución': new Date().toISOString().replace(/T.{1,}/, ''),
    'Código de verificación': '',
  },
  certification: {
    Nombre: '',
    Descripción: '',
    'Válido hasta': new Date().toISOString().replace(/T.{1,}/, ''),
  },
}

const requiredFields = {
  resolution: ['Número', 'Fecha de resolución'],
  certification: ['ID_type_certification'],
}

const requiredFilled = (objeto, array) => {
  return array.every((requiredField) => objeto[requiredField] !== '')
}

const sortQueryPayload = (objFormData, values) => {
  return values.reduce((prev, acc) => {
    if (Array.isArray(acc)) {
      const value = acc.reduce(
        (aprev, aacc, index) => {
          if (index !== 0) {
            return { [acc[0]]: { ...aprev[acc[0]], [aacc]: objFormData[`${acc[0]}_${aacc}`] || null } }
          }

          return aprev
        },
        { [acc[0]]: acc.length > 1 ? {} : null }
      )
      return { ...prev, ...value }
    }
    if (typeof acc === 'object' && (objFormData[acc.key] !== null || acc.type === 'String')) {
      if (acc.type === 'Number') return { ...prev, [acc.key]: +objFormData[acc.key] }
      if (acc.type === 'String') return { ...prev, [acc.key]: objFormData[acc.key]?.toString() || null }
      if (acc.type === 'Boolean') return { ...prev, [acc.key]: objFormData[acc.key] === 'true' }
    }

    return { ...prev, [acc]: objFormData[acc] || null }
  }, {})
}

const updateProducer_fields = [
  'additional_info',
  'brand_name',
  'business_name',
  'comment',
  'delivery_time',
  'history',
  { key: 'isActive', type: 'Boolean' },
  { key: 'isPublished', type: 'Boolean' },
  { key: 'min_purchase', type: 'Number' },
  'rut',
  'slogan',
  'web',
  ['formalization', 'health_resolution', 'start_activities'],
  ['bank_account', 'bank', 'holder', 'number', 'rut', 'type'],
  ['type_sale', 'ID_type_sale'],
]
const upsertContact_fields = ['ID_producer', 'contact_email', 'contact_name', 'contact_phone']
const upsertDirection_fields = [
  'ID_producer',
  'code_country',
  'country',
  { key: 'latitud', type: 'String' },
  { key: 'longitud', type: 'String' },
  'label',
  'local_or_office',
  'municipality',
  'number',
  'reference',
  'region',
  'street',
  'sub_region',
]
const upsertProductsInfo_fields = [
  'ID_producer',
  { key: 'hasCertification', type: 'Boolean' },
  { key: 'hasNutritional_info', type: 'Boolean' },
  { key: 'hasIngredient_sheet', type: 'Boolean' },
  { key: 'hasExpiration_date', type: 'Boolean' },
]

const mutationIDs = ['ID_producer', 'ID_direction', 'ID_contact', 'ID_products_info']

export const useEditProducers = () => {
  const producerForm = useRef()
  const {
    producer,
    products,
    deliveryZones,
    loading,
    stateCategories,
    productsInfo,
    initialCertificationFiles,
    initialResolutionFiles,
    certificate_categories,
  } = useGetProducersById()

  const [updateProducerMutation, { error: errorUpdateProducer }] = useMutation(QUERY.EDITPRODUCER.UPDATE_PRODUCER, {
    context: { clientName: 'apiProducer' },
  })
  const [deleteDocsMutation, { error: errorDeleteDocs }] = useMutation(QUERY.EDITPRODUCER.DELETE_DOCS, {
    context: { clientName: 'apiProducer' },
  })

  const [certificationFiles, setCertificationFiles] = useState([])
  const [resolutionFiles, setResolutionFiles] = useState([])
  const [inputsFile, setInputsFile] = useState(initialInputsFile)
  const [comment, setComment] = useState('')
  const [history, setHistory] = useState('')
  const { setAlert } = useContext(AlertContext)
  const [selectedCategoryType, setSelectedCategoryType] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [address, setAddress] = useState({})
  const [imageFile, setImageFile] = useState(null)

  const uploadCertification = (file) => {
    if (requiredFilled(inputsFile.certification, requiredFields.certification)) {
      setCertificationFiles((certificationFiles) => [
        ...certificationFiles,
        {
          details: {
            ...inputsFile.certification,
            Nombre: certificate_categories.find(
              (category) => category.ID_type_certification === inputsFile.certification.ID_type_certification
            ).type,
          },
          file,
          fromApi: false,
          id: crypto.randomUUID(),
        },
      ])
      setInputsFile((prevState) => ({ ...prevState, certification: initialInputsFile.certification }))
    } else {
      setAlert({ open: true, message: 'Rellena todos los campos requeridos certificados', severity: 'error' })
    }
  }

  const uploadResolution = (file) => {
    if (requiredFilled(inputsFile.resolution, requiredFields.resolution)) {
      const resolutionsToDelete = structuredClone(
        resolutionFiles.map((resolution) => {
          if (resolution.fromApi) resolution.toDelete = true
          return resolution
        })
      )
      setResolutionFiles([
        ...resolutionsToDelete,
        { details: inputsFile.resolution, file, fromApi: false, id: crypto.randomUUID() },
      ])
      setInputsFile((prevState) => ({ ...prevState, resolution: initialInputsFile.resolution }))
    } else {
      setAlert({ open: true, message: 'Rellena todos los campos requeridos resolucion', severity: 'error' })
    }
  }
  const clearPrefixes = (obj, prefix) => {
    //-Convertimos las claves del objeto a minusculas
    let key,
      keys = Object.keys(obj)
    let n = keys.length
    let newObj = {}
    while (n--) {
      key = keys[n]
      newObj[key.replace(prefix, '')] = obj[key]
    }
    return newObj
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    imageFile?.toAdd && (await uploadImage(imageFile.toAdd))
    imageFile?.toRemove && (await removeImage(imageFile.toRemove.file_name))
    const formElement = producerForm.current
    const objFormData = Object.fromEntries(new FormData(formElement))
    const { bank_account, ...rest } = sortQueryPayload(objFormData, updateProducer_fields)
    const bankAccountValid = Object.values(bank_account).every((value) => value)
    const producerPayload = bankAccountValid ? { bank_account, ...rest } : { ...rest }
    const contactPayload = sortQueryPayload(objFormData, upsertContact_fields)
    const directionPayload = sortQueryPayload({ ...objFormData, ...address }, upsertDirection_fields)
    const productsInfoPayload = sortQueryPayload(objFormData, upsertProductsInfo_fields)
    const ID_variables = sortQueryPayload(objFormData, mutationIDs)
    const createProducerCategoryPayload = selectedCategories
      .filter(
        (category) =>
          !producer.producer_category.some((apiCategory) => apiCategory.category.ID_category === category.ID_category)
      )
      .map((category) => ({ ID_category: category.ID_category }))
    const deleteProducerCategoryByIdPayload = producer.producer_category
      .filter(
        (apiCategory) =>
          !selectedCategories.find((category) => apiCategory.category.ID_category === category.ID_category)
      )
      .map((category) => ({ ID_category: category.category.ID_category }))

    const createCertificationPayload = await uploadCertificatesFile()
    const deleteCertificatesPayload =
      certificationFiles.filter((certificate) => certificate.toDelete)?.map((certificate) => certificate.id) || null
    const variablesDocsCertificates = {
      payload: deleteCertificatesPayload,
      origen: 'CERTIFICATION',
    }
    if (deleteCertificatesPayload.length) {
      await deleteDocsMutation({
        variables: variablesDocsCertificates,
      })
    }

    const createResolutionPayload = await uploadResolutionFile()
    const deleteResolutionPayload =
      resolutionFiles.filter((resolution) => resolution.toDelete)?.map((resolution) => resolution.id) || null
    const variablesDocsResolution = {
      payload: deleteResolutionPayload,
      origen: 'RESOLUTION',
    }
    if (deleteResolutionPayload.length) {
      await deleteDocsMutation({
        variables: variablesDocsResolution,
      })
    }

    const variables = {
      ...ID_variables,
      producerPayload,
      contactPayload: clearPrefixes(contactPayload, 'contact_'),
      directionPayload,
      productsInfoPayload,
      createProducerCategoryPayload,
      deleteProducerCategoryByIdPayload,
      createCertificationPayload,
      createResolutionPayload,
    }
    await updateProducerMutation({
      variables,
    })
    if (errorUpdateProducer || errorDeleteDocs) {
      setAlert({ open: true, message: 'Ocurrío un error al actualizar los datos del productor', severity: 'error' })
    } else {
      setAlert({ open: true, message: 'Datos del productor actualizados', severity: 'success' })
    }
  }
  const uploadCertificatesFile = async () => {
    const certificatesWithFile = certificationFiles.filter((certificate) => !certificate.fromApi && certificate.file)
    const filesToUpload = new FormData()
    certificatesWithFile?.forEach((certificate) =>
      filesToUpload.append('files', certificate.file, certificate.file.name)
    )
    const res =
      certificatesWithFile.length &&
      (await fetch(
        `${process.env.REACT_APP_API_PRODUCER}api/v1/upload-certification/file?rut=${producer.rut}&dir=certification`,
        {
          method: 'POST',
          body: filesToUpload,
        }
      ))
    const resCertification = certificatesWithFile.length ? await res.json() : null
    const addCertificates = certificationFiles.filter((certificate) => !certificate.fromApi)
    const createCertificationPayload = addCertificates.map((certificate) => {
      return {
        url: resCertification?.results?.find((item) => item.key.includes(certificate.file?.name))?.key || null,
        valid_until: new Date(certificate.details['Válido hasta']).toISOString(),
        ID_type_certification: certificate.details.ID_type_certification,
      }
    })
    return createCertificationPayload.length ? createCertificationPayload : null
  }

  const uploadResolutionFile = async () => {
    const newResolutionFile = resolutionFiles.filter((resolution) => resolution.file && !resolution.fromApi)

    const fileToUpload = new FormData()
    const resolutionToUpload = resolutionFiles?.find((resolution) => !resolution.fromApi)
    resolutionToUpload?.file && fileToUpload.append('files', resolutionToUpload?.file, resolutionToUpload?.file?.name)
    const res =
      newResolutionFile.length &&
      (await fetch(
        `${process.env.REACT_APP_API_PRODUCER}api/v1/upload-certification/file?rut=${producer.rut}&dir=resolution`,
        {
          method: 'POST',
          body: fileToUpload,
        }
      ))

    const resUploadResolution = newResolutionFile.length ? await res.json() : null
    const addResolution = resolutionFiles.filter((resolution) => !resolution.fromApi)
    const createResolutionPayload = addResolution.map((resolution) => {
      return {
        emit_date: new Date(resolution.details['Fecha de resolución']).toISOString(),
        url: resUploadResolution?.results?.find((item) => item.key.includes(resolution.file?.name))?.key || null,
        number: resolution.details['Número'],
        verification_code: resolution.details['Código de verificación'],
        ID_producer: producer.ID_producer,
      }
    })
    return createResolutionPayload[0] || null
  }

  const onChangeInputFile = (event, group) => {
    const inputName = event.target.name
    const inputValue = event.target.type === 'date' ? +new Date(event.target.value) + 10800000 : event.target.value
    const inputGroup = event.target?.dataset?.group || group
    if (inputName === 'ID_type_certification') {
      return setInputsFile((prevState) => ({
        ...prevState,
        [inputGroup]: {
          ...prevState[inputGroup],
          [inputName]: inputValue,
          Descripción: certificate_categories.find((category) => category.ID_type_certification === inputValue)
            .description,
        },
      }))
    }
    setInputsFile((prevState) => ({
      ...prevState,
      [inputGroup]: { ...prevState[inputGroup], [inputName]: inputValue },
    }))
    event.target.type === 'date' && setTimeout(() => event.target.click(), 0)
  }

  const handleCategoryTypeChange = (e) => {
    const categoryType = e.target.value
    const isChecked = e.target.checked

    if (isChecked) {
      setSelectedCategoryType((prev) => {
        return [...prev, categoryType]
      })
    } else {
      setSelectedCategoryType((prev) => {
        return [...prev.filter((type) => type != categoryType)]
      })
      setSelectedCategories((prev) => {
        return prev.filter(
          (prevCat) =>
            prevCat.category_type.ID_category_type != categoryType || prevCat.category_type.ID_category_type == '3'
        )
      })
    }
  }

  const handleDeleteResolution = (id) => {
    const itemIndex = resolutionFiles.findIndex((item) => item.id === id)
    const item = resolutionFiles[itemIndex]
    if (item.fromApi) {
      item.toDelete = true
      const newResolutionFiles = structuredClone(resolutionFiles)
      newResolutionFiles[itemIndex] = item
      setResolutionFiles(() => newResolutionFiles)
    } else {
      const newResolutionFiles = resolutionFiles.filter((item) => item.id !== id)
      setResolutionFiles(() => newResolutionFiles)
    }
  }
  const handleDeleteCertification = (id) => {
    const itemIndex = certificationFiles.findIndex((item) => item.id === id)
    const item = certificationFiles[itemIndex]
    if (item.fromApi) {
      item.toDelete = true
      const newCertificationFiles = structuredClone(certificationFiles)
      newCertificationFiles[itemIndex] = item
      setCertificationFiles(() => newCertificationFiles)
    } else {
      const newCertification = certificationFiles.filter((certificate) => certificate.id !== id)
      setCertificationFiles(() => newCertification)
    }
  }

  const handleChangeComment = (event) => {
    setComment(event.target.value)
  }

  const handleCopyAdditionalInfo = () => {
    const newHistory = producer.additional_info
    setHistory(newHistory)
  }

  const handleChangeHistory = (event) => {
    const newHistory = event.target.value
    setHistory(newHistory)
  }

  const handleSelectedCategories = (event) => {
    const newSelected = event.target.value
    if (event.target.name === 'lifeStyles') {
      return setSelectedCategories((prev) => {
        const categories = prev.filter((cat) => cat.category_type.ID_category_type != '3')
        return [...categories, ...newSelected]
      })
    }
    return setSelectedCategories((prev) => {
      const lifeStyles = prev.filter((cat) => cat.category_type.ID_category_type == '3')
      return [...lifeStyles, ...newSelected]
    })
  }

  const handleChangeAddress = (payload_address) => {
    if (payload_address.hasOwnProperty('Geometry')) {
      //-Convertimos las claves del objeto a minusculas
      let key,
        keys = Object.keys(payload_address)
      let n = keys.length
      let newPayload = {}
      while (n--) {
        key = keys[n]
        newPayload[key.toLowerCase()] = payload_address[key]
      }
      //-----------------------------------------------
      const { geometry, ...rest } = newPayload
      const [longitud, latitud] = geometry.Point
      setAddress({ ...rest, longitud, latitud })
    } else if (payload_address.hasOwnProperty('longitud')) {
      setAddress(payload_address)
    }
  }

  const restoreDefaultAddress = () => {
    setAddress(producer.direction[0])
  }
  const handleImageFile = (payload) => {
    setImageFile(payload)
  }
  const uploadImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const res = await fetch(
      `${process.env.REACT_APP_API_PRODUCER}api/v1/upload/file?dir=register_files&ID_producer=${producer.ID_producer}`,
      {
        method: 'POST',
        body: formData,
      }
    )
    return res
  }
  const removeImage = async (fileName) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_PRODUCER}api/v1/delete/file?ID_producer=${producer.ID_producer}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName }),
      }
    )
    return res
  }

  // @TODO si es necesario
  const handleAddressNumber = () => {}
  // ---------------------

  useEffect(() => {
    if (stateCategories.length && !loading) {
      const id_categories = producer.producer_category.map((el) => el.category.ID_category)
      const newSelectedCategories = stateCategories.filter((stateCategory) =>
        id_categories.some((id) => id === stateCategory.ID_category)
      )
      setSelectedCategories((prev) => {
        return newSelectedCategories
      })
    }
    if (!loading && !selectedCategoryType.length) {
      setSelectedCategoryType((prev) => [
        ...new Set(producer.producer_category.map(({ category }) => category.category_type.ID_category_type)),
      ])
    }
    if (!loading) {
      setAddress(producer.direction.length ? producer.direction[0] : {})
      setCertificationFiles(initialCertificationFiles)
      setResolutionFiles(initialResolutionFiles)
      setHistory(producer.history)
      setComment(producer.comment)
    }
  }, [loading])

  return {
    producer,
    products,
    loading,
    deliveryZones,
    producerForm,
    certificationFiles,
    resolutionFiles,
    uploadCertification,
    uploadResolution,
    handleSubmit,
    onChangeInputFile,
    handleCategoryTypeChange,
    handleDeleteCertification,
    handleDeleteResolution,
    inputsFile,
    comment,
    history,
    handleChangeComment,
    handleCopyAdditionalInfo,
    handleChangeHistory,
    categories: stateCategories.filter((category) =>
      selectedCategoryType?.some((selected) => selected === category?.category_type?.ID_category_type)
    ),
    selectedCategoryType,
    selectedCategories: selectedCategories.filter((category) => category?.category_type?.ID_category_type !== '3'),
    handleSelectedCategories,
    lifeStyles: stateCategories.filter((category) => category?.category_type?.ID_category_type === '3'),
    selectedLifeStyles: selectedCategories.filter((category) => category?.category_type?.ID_category_type === '3'),
    productsInfo,
    handleAddressNumber,
    handleChangeAddress,
    restoreDefaultAddress,
    address,
    certificate_categories,
    handleImageFile,
  }
}
