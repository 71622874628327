import { Button, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/styles'
import { useGetLazyProductsByIds } from '../../hooks/useGetProducts'
import * as XSLX from 'xlsx'
import priceFormat from '../../utils/priceFormat'
import { formatMinPurchase } from '../../utils/formatMinPurchase'
import AlertContext from '../../context/AlertContext'
import { useContext } from 'react'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem',
    gap: '0.5rem',
  },
  containerBtns: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0.5rem',
    marginTop: '1rem',
    justifyContent: 'space-around',
  },
  ul: {
    margin: '0 1.5rem',
  },
  li: {
    margin: ' 0.5rem 0',
  },

  containerProducts: {
    minHeight: '2rem',
    maxHeight: '12rem',
    width: 'auto',
    overflowY: 'auto',
  },
}))
const ConfirmButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '0.875rem',
    height: '3.5rem',
    margin: '1.5rem',
    color: 'white',
    padding: (4, 25),
    fontWeight: 'bold',
    borderRadius: '10px',
  },
})(Button)

export default function ModalDownloadProducts({ selected, handleClose, selectedId }) {
  const classes = useStyles()
  const { requestProducstByIds } = useGetLazyProductsByIds()
  const { setAlert } = useContext(AlertContext)

  const downloadExcelProducts = async () => {
    try {
      const ids = selectedId.map((item) => item.ID_product)
      const {
        data: {
          productById: { products },
        },
      } = await requestProducstByIds({ variables: { ids } })

      // crear un libro
      const workbook = XSLX.utils.book_new()
      // crear columnas basadas en un arreglo de objetos
      const rows = products.map((product) => ({
        Producto: product.name,
        Marca: product.producer.brand_name,
        Stock: product.stock.ID_stock === '1' ? 'En stock' : 'Temporalmente sin stock',
        'Formato de venta': 'Caja ' + product.sale_format ? product.sale_format + ' un.' : ' ',
        'Precio unitario neto': priceFormat((product.net_price_package / product.sale_format).toFixed(2)),
        'Precio unitario c/iva': priceFormat(product.wholesale_unit_price),
        'Precio de caja neto': priceFormat(product.net_price_package),
        'Precio de caja c/iva': priceFormat(product.price_package),
        Estado: product.isActive ? 'Activo' : 'Inactivo',
        'Pedido minimo (Productor)': formatMinPurchase(product.producer),
        Categorias: product.product_category.map((item) => item.category_product.category).join(', '),
        'Precio sugerido de venta': priceFormat(product.suggested_sale_price),
        'Margen estimado':
          Math.round(
            (product.suggested_sale_price * 100) /
              (product.offer_price / product.sale_format || product.wholesale_unit_price) -
              100
          ) + '%',
        'Peso de producto': product.weight ? product.weight + ' grs.' : ' ',
        'Enlace a producto': 'https://www.alimentos.comes.cl/' + product.slug,
        'Imagen de producto': product.product_image[0]
          ? `${process.env.REACT_APP_S3_URL}${product.product_image[0]?.file_image}`
          : ' ',
      }))
      // crear la hoja
      const worksheet = XSLX.utils.json_to_sheet(rows)
      //Define el ancho de las columnas
      worksheet['!cols'] = [
        { wch: 30 }, //Producto
        { wch: 30 }, //Marca
        { wch: 10 }, //Stock
        { wch: 15 }, //Formato de venta
        { wch: 17 }, //Precio unitario neto
        { wch: 17 }, //Precio unitario c/iva
        { wch: 17 }, //Precio de caja neto
        { wch: 17 }, //precio de caja c/iva
        { wch: 10 }, //Estado
        { wch: 22 }, //Pedido minimo
        { wch: 25 }, //Categorias
        { wch: 18 }, //Precio sugerido de venta
        { wch: 15 }, //Margen estimado
        { wch: 15 }, //peso de producto
        { wch: 35 }, //Enlace de producto
        { wch: 35 }, //Imagen de producto
      ]
      //unir hoja con el libro
      XSLX.utils.book_append_sheet(workbook, worksheet, 'Productos')
      //generar el archivo
      XSLX.writeFile(workbook, 'listado_de_productos.xlsx')
      setAlert({ open: true, message: 'Archivo descargado', severity: 'success' })
      handleClose()
    } catch (error) {
      console.error(error)
      setAlert({ open: true, message: 'Ocurrió un error al descargar el archivo - ' + error, severity: 'error' })
    }
  }
  return (
    <div className={classes.container}>
      <InfoIcon fontSize="large" color="secondary" style={{ fontSize: 70 }} />
      <h1 style={{ fontSize: '1.5rem' }}> Productos seleccionados:</h1>
      <div className={classes.containerProducts}>
        <ol className={classes.ul}>
          {selected.map((product, index) => (
            <li key={product + index} className={classes.li}>
              {product}
            </li>
          ))}
        </ol>
      </div>
      <div className={classes.containerBtns}>
        <ConfirmButton style={{ backgroundColor: '#f69223' }} onClick={() => handleClose()}>
          Cancelar
        </ConfirmButton>
        <ConfirmButton style={{ backgroundColor: '#8ac541' }} onClick={() => downloadExcelProducts()}>
          Descargar Excel
        </ConfirmButton>
      </div>
    </div>
  )
}
