import Location from 'aws-sdk/clients/location'
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from '../../src/aws-exports'
import { useEffect, useState } from 'react'
import Input from './Input'

Amplify.configure(awsconfig)

const indexName = 'dashboardPlacesIndex'

export const InputGeo = ({ setAddress, setHasNumber, defaultValue, required }) => {
  const [client, setClient] = useState(null)
  const [listLabel, setListLabel] = useState([])
  const [place, setPlace] = useState('')
  const [selectedAddress, setSelectedAddress] = useState({})
  const [showList, setShowList] = useState(false)

  useEffect(() => {
    const fetchCredentials = async () => await Auth.currentUserCredentials()

    fetchCredentials()

    const createClient = async () => {
      const credentials = await Auth.currentCredentials()
      const client = new Location({
        credentials,
        region: awsconfig.aws_project_region,
      })
      setClient(client)
    }

    createClient()
  }, [])

  useEffect(() => {
    setAddress(selectedAddress)
  }, [selectedAddress])

  useEffect(() => {
    defaultValue && setPlace(defaultValue.label || '')
    defaultValue && setSelectedAddress(defaultValue || {})
  }, [defaultValue])

  const createPlace = ({ Street, SubRegion, AddressNumber, Municipality, Region, Country }) => {
    const street = Street ? Street + ' ' : ''
    const addressNumber = AddressNumber ? AddressNumber + ',' : ''
    const municipality = Municipality ? Municipality + ',' : ''
    const subRegion = SubRegion ? SubRegion + ', ' : ''
    const region = Region ? Region + ',' : ''
    const country = Country ? Country : ''

    return `${street} ${addressNumber} ${municipality} ${subRegion} ${region} ${country}`.trim()
  }

  const handleChange = (event) => {
    if (event.target.value === '') {
      setListLabel([])
      setShowList(false)
    }
    setPlace(event.target.value)

    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      setSelectedAddress({})
      setHasNumber(true)
    }

    const params = {
      IndexName: indexName,
      MaxResults: 5,
      Text: ' ',
    }

    params.Text = event.target.value || ' '

    client.searchPlaceIndexForText(params, (err, data) => {
      if (err) console.error(err)
      if (data) {
        setShowList(true)
        setListLabel(data.Results.map((result) => result.Place))
      }
    })
  }

  const handleClick = (label) => {
    setPlace(createPlace(label))
    setHasNumber(label.hasOwnProperty('AddressNumber'))
    const { Interpolated, ...rest } = label
    setSelectedAddress({ ...rest, number: rest.AddressNumber })
    setShowList(false)
  }

  return (
    <div className="container">
      <Input
        list="browsers"
        type="text"
        label="Ingresa tu dirección"
        aria-label="Place"
        aria-describedby="basic-addon2"
        name="browser"
        id="browser"
        value={place}
        onChange={handleChange}
        onFocus={() => {
          place === '' && setListLabel([])
        }}
        onBlur={() => {
          setTimeout(() => {
            setListLabel([])
          }, 200)
        }}
        autoComplete="off"
        required={required ? !selectedAddress.Country : false}
      />
      <ul id="browsers" className="browsers">
        {listLabel.map((label, index) => (
          <li key={index} onClick={() => handleClick(label)}>
            {createPlace(label)}
          </li>
        ))}
      </ul>
      <style>
        {`
          .search-direction {
            width: 100%;
            height: 2, 5rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid var(--gray);
            outline: none;
            font-size: 1rem;
          }
          .container {
            position: relative;
          }
          .browsers {
            ${!showList ? 'display:none;' : ''}
            position: absolute;
            width: 100%;
            top: 3rem;
            margin: 0;
            list-style: none;
            padding: 0;
            height: fit-content;
            border: none;
            box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.75);
            border-radius: 0.5rem;
            overflow: hidden;
            z-index: 100;
          }

          .browsers li {
            padding: 0.5rem;
            background: #fefefe;
            user-select: none;
          }
          .browsers li:hover {
            cursor: pointer;
            background: #f0f0f0;
          }
        `}
      </style>
    </div>
  )
}
