import {
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  ButtonGroup,
  Checkbox,
} from '@material-ui/core'
import { Edit, Email, Person, PictureAsPdf, Send } from '@material-ui/icons'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { useContext } from 'react'
import { totalCostByProducer, totalPriceByProducer } from '../../utils/totalOrderByProducer'
import sendEmailOrder from '../../utils/sendEmailOrder'
import TransitionsModal from '../modal/ModalContainer'
import { EnhancedTableHead } from './EnhancedTableHead'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import AlertContext from '../../context/AlertContext'
import { useGetLazyProductsByIds } from '../../hooks/useGetProducts'
import { fullOrderNote } from '../../utils/fullOrderNote'
import heart from '../../heart_comes.svg'

function createData(
  ID_order_note,
  dateOrder,
  numberOrder,
  storeName,
  store_region,
  city,
  statusOrder,
  sent,
  editShipping,
  totalAmount,
  vendor,
  row
) {
  return {
    ID_order_note,
    dateOrder,
    numberOrder,
    storeName,
    store_region,
    city,
    statusOrder,
    sent,
    editShipping,
    totalAmount,
    vendor,
    row,
  }
}

const rows = (orders) => {
  if (orders) {
    const dataList = orders.map((order) => {
      return createData(
        order?.orderNote?.ID_order_note,
        new Date(+order?.orderNote?.creation_date).toLocaleDateString(),
        order?.orderNote?.number,
        order?.orderNote?.store.name,
        order?.orderNote?.store_direction.street
          .concat(' ')
          .concat(order?.orderNote?.store_direction.number)
          .concat(
            order?.orderNote?.store_direction.local_or_office
              ? ', ' + order?.orderNote?.store_direction.local_or_office
              : ''
          ),
        order?.orderNote?.store_direction.municipality
          ? order?.orderNote?.store_direction.municipality
          : order?.orderNote?.store_direction.sub_region,
        order?.orderNote?.order_status.status,
        order?.detail?.every((orderNote) => {
          return orderNote?.order_status?.ID_order_status == '5' || orderNote?.order_status?.ID_order_status == '3'
        }),
        order.detail.every((orderNote) => {
          return orderNote?.order_status?.ID_order_status == '5'
        }),
        +order?.orderNote?.total_order,
        order?.orderNote?.vendor ? order?.orderNote?.vendor.name : 'Sin información',
        order
      )
    })
    return dataList
  }
  return []
}
function descendingComparator(a, b, field) {
  if (b[field] < a[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }
  return 0
}

function getComparator(order, field) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, field) : (a, b) => -descendingComparator(a, b, field)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: '#8ac541',
    },
    '&.Mui-disabled': {
      color: 'rgb(133, 133, 133)',
    },
  },
}))

const query = `mutation ChangeStatusDetail($idOrderNote: ID!, $idProducer: ID!) {
    changeStatusDetail(ID_order_note: $idOrderNote, ID_producer: $idProducer) {
      codes
      message
    }
  }`

export default function EnhancedTableOrdersProducer({
  orders,
  loading,
  setOrders,
  producerSelected,
  errorOrders,
  skip,
  setSkip,
  take,
  setTake,
  orderNoteCount,
  field,
  setField,
  order,
  setOrder,
  getData,
}) {
  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [openModal, setOpenModal] = React.useState({ show: false, component: null })
  const [orderSelected, setOrderSelected] = React.useState()
  const [selectedID, setSelectedID] = React.useState([])
  const { setAlert } = useContext(AlertContext)
  const { requestProducstByIds } = useGetLazyProductsByIds()

  const handleSentStatus = (idOrderNote, row) => {
    setOrderSelected(orders.find((order) => idOrderNote === order.orderNote.ID_order_note))
    if (!row.sent) {
      setOpenModal({ component: 'ModalInfoShipping', show: true })
    }
  }

  const handleEdit = (idOrderNote) => {
    setOrderSelected(orders.find((order) => idOrderNote === order.orderNote.ID_order_note))
    setOpenModal({ component: 'ModalInfoShipping', show: true })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = field === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setField(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSkip(take * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setTake(parseInt(event.target.value, 10))
    setPage(0)
    setSkip(0)
  }

  const isSelected = (nameProduct) => selected.indexOf(nameProduct) !== -1

  async function handlePDF(row, id) {
    const fullOrder = await fullOrderNote(row, requestProducstByIds)
    const newOrder = await totalCostByProducer(fullOrder)
    const newFullOrder = await changeUnitPrice(newOrder)
    const response = await fetch(`${process.env.REACT_APP_API_STORE}api/v1/pdf/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer-when-downgrade',
      },
      body: JSON.stringify({ orderNote: newFullOrder.orderNote, detailOrderNotes: newFullOrder.detail }),
    })
    const file = await response.blob()
    const ObjURL = URL.createObjectURL(file)
    const a = document.getElementById(`sheet-${id}`)
    a.href = ObjURL
    a.download = `pedido_${
      newFullOrder.orderNote.number
        ? 'N°' + newFullOrder.orderNote.number
        : 'Borrador-' + new Date(+newFullOrder.orderNote?.creation_date).toLocaleDateString()
    }_${newFullOrder.detail[0].product.producer.brand_name}.pdf`
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  }

  const handleViewOrder = async (row, id) => {
    const fullOrder = await fullOrderNote(row, requestProducstByIds)
    const newTotal = await totalCostByProducer(fullOrder)
    const productCost = await changeUnitPrice(newTotal)
    setOrderSelected(productCost)

    setOpenModal({ show: !openModal.show, component: 'ModalOrderdetail', viewProducer: true })
  }

  const sendEmail = async (row, id) => {
    const fullOrder = await fullOrderNote(row, requestProducstByIds)
    const newOrder = await totalPriceByProducer(fullOrder)
    sendEmailOrder(newOrder).then((res) => {
      if (res.status == 200) {
        setAlert({ open: true, message: res.message, severity: 'success' })
      } else {
        setAlert({ open: true, message: res.message ? res.message : 'Ocurrió un error', severity: 'error' })
      }
    })
  }

  const changeUnitPrice = (order) => {
    order.detail.map((product) => {
      product.unit_price = product.net_historical_cost / product.qty
      product.sub_total = product.net_historical_cost
    })
    return order
  }
  return (
    <div className={classes.root}>
      <TransitionsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        orderSelected={orderSelected}
        setOrders={setOrders}
        orders={orders}
        getData={getData}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} producerSelected={producerSelected} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              field={field}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={orders ? orders.length : 0}
            />
            <TableBody>
              {orders && orders.length !== 0 ? (
                <>
                  {stableSort(rows(orders), getComparator(order, field)).map((row, index) => {
                    const isItemSelected = isSelected(row.nameProduct)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.numberOrder}-${row.nameProduct}.${index}`}>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.dateOrder}
                        </TableCell>

                        <TableCell align="center">
                          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.35rem' }}>
                            {row?.row?.orderNote?.order_note_producer?.ID_order_note_producer ? (
                              <Tooltip title="Productor">
                                <Person />
                              </Tooltip>
                            ) : (
                              <Tooltip title="ComeS">
                                <img src={heart} width={'20px'} />
                              </Tooltip>
                            )}
                            {row.numberOrder}
                          </div>
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Tooltip title="Ver detalle">
                            <IconButton
                              aria-label="Ver detalle"
                              onClick={() => {
                                handleViewOrder(row.row, row.ID_order_note)
                              }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">{row.storeName}</TableCell>
                        <TableCell align="center">{row.store_region}</TableCell>
                        <TableCell align="center">{row.city}</TableCell>
                        <TableCell align="center">{row.statusOrder}</TableCell>
                        <TableCell align="center">
                          <Checkbox
                            className={classes.checkbox}
                            checked={row.sent}
                            onChange={(e) => handleSentStatus(row.ID_order_note, row)}
                            disabled={
                              row.statusOrder === 'Borrador' ||
                              row.statusOrder === 'Recibido' ||
                              row.statusOrder === 'Cancelado'
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title="Editar datos de envío"
                            disabled={!row.editShipping}
                            onClick={() => handleEdit(row.ID_order_note)}>
                            <IconButton aria-label="Editar datos de envío">
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          {'$' + new Intl.NumberFormat('de-DE').format(row.totalAmount)}
                        </TableCell>
                        <TableCell align="center"> {row.vendor}</TableCell>
                        <TableCell align="center" padding="none">
                          <ButtonGroup style={{ borderRadius: '0px' }}>
                            <Tooltip
                              data-id={row.ID_order_note}
                              title="Enviar nota de pedido"
                              onClick={() => sendEmail(row.row, row.ID_order_note)}>
                              <IconButton aria-label="Enviar nota de pedido">
                                <Email />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              data-id={row.ID_order_note}
                              title="Descargar PDF"
                              onClick={async () => handlePDF(row.row, row.ID_order_note)}>
                              <IconButton aria-label="Descargar PDF">
                                <PictureAsPdf />
                              </IconButton>
                            </Tooltip>
                            <a id={`sheet-${row.ID_order_note}`} className="anchor-btn"></a>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <h3>
                      {loading
                        ? 'Cargando...'
                        : Boolean(producerSelected.ID_producer)
                        ? !Boolean(orderNoteCount) && 'El productor seleccionado no tiene pedidos ingresados'
                        : 'Debes seleccionar un productor'}
                    </h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={orderNoteCount}
          rowsPerPage={take}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
