import { createContext, useState } from 'react'

const Context = createContext({})

export function AlertContext({ children }) {
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' })
  return <Context.Provider value={{ alert, setAlert }}>{children}</Context.Provider>
}

export default Context
