import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { InsertComment, Minimize } from '@material-ui/icons'
import { useState } from 'react'

export const CommentFloatButton = ({ state, onChange }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ color: 'white', borderRadius: '5rem', textTransform: 'none' }}
        startIcon={<InsertComment />}
        onClick={() => setOpen(!open)}>
        Añadir comentario
      </Button>
      {open && (
        <Paper
          elevation={10}
          style={{
            position: 'fixed',
            bottom: '5.5rem',
            right: '2rem',
            padding: '2rem',
            paddingTop: '1.5rem',
            border: '1px solid lightgray',
            width: match ? '90%' : '50%',
          }}>
          <Grid container spacing={2} direction="column" alignItems="stretch">
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item xs>
                  <Typography variant="h6">Escribe tu comentario</Typography>
                </Grid>
                <Grid item style={{ position: 'absolute', top: '.5rem', right: '1rem' }}>
                  <IconButton onClick={() => setOpen(!open)}>
                    <Tooltip title="Minimizar" arrow placement="left-start">
                      <Minimize />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField value={state} onChange={onChange} multiline fullWidth autoFocus />
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  )
}
