import { gql } from '@apollo/client'
import { FRAGMENT } from '../fragments'

const PRODUCTS = {
  CREATE: gql`
    mutation CreateProduct($productPayload: productPayload, $imagePayload: [imagePayload]) {
      createProduct(productPayload: $productPayload, imagePayload: $imagePayload) {
        message
        ID_product
      }
    }
  `,
  UPDATE: gql`
    mutation Mutation(
      $imagePayload: [imageUpdPayload]
      $idProduct: ID
      $productPayload: productUpdPayload
      $imageDelete: [imageDelete]
      $categoryProduct: [categoryProduct]
    ) {
      updateProduct(
        imagePayload: $imagePayload
        ID_product: $idProduct
        productPayload: $productPayload
        imageDelete: $imageDelete
        categoryProduct: $categoryProduct
      ) {
        message
        amountDeletedImage
        ID_product
      }
    }
  `,
  BY_IDS: gql`
    query ProductById($ids: [ID]) {
      productById(ids: $ids) {
        ... on ProductByIds {
          products {
            ...FullProduct
            producer {
              ...DetailsProducer
            }
            ...ProductImage
            ...ProductCategory
          }
        }
      }
    }
    ${FRAGMENT.PRODUCT.CATEGORY_FRAGMENT}
    ${FRAGMENT.PRODUCT.IMAGE_FRAGMENT}
    ${FRAGMENT.PRODUCT.FULL_FRAGMENT}
    ${FRAGMENT.PRODUCER.DETAILS_FRAGMENT}
  `,
  BY_ID: gql`
    query ProductById($idProduct: ID!) {
      productById(ID_product: $idProduct) {
        ... on ProductById {
          product {
            ...FullProduct
            product_certification {
              certification {
                valid_until
                url
                type_certification {
                  type
                  description
                  ID_type_certification
                }
                modificated_at
                created_at
                ID_producer
                ID_certification
              }
              ID_product_certification
            }
            resolution {
              ID_resolution
              emit_date
              number
              verification_code
            }
            producer {
              ...DetailsProducer
            }
            ...ProductCategory
            ...ProductImage
          }
        }
      }
    }
    ${FRAGMENT.PRODUCT.FULL_FRAGMENT}
    ${FRAGMENT.PRODUCT.CATEGORY_FRAGMENT}
    ${FRAGMENT.PRODUCT.IMAGE_FRAGMENT}
    ${FRAGMENT.PRODUCER.DETAILS_FRAGMENT}
  `,
  FULL: gql`
    query Products(
      $take: Int
      $skip: Int
      $all: Boolean
      $field: FieldOrderProduct
      $order: Order
      $categories: String
      $q: String
    ) {
      products(take: $take, skip: $skip, all: $all, field: $field, order: $order, categories: $categories, q: $q) {
        _count
        skip
        take
        product {
          ...FullProduct
          producer {
            ...DetailsProducer
          }
          ...ProductCategory
          ...ProductImage
        }
      }
    }
    ${FRAGMENT.PRODUCT.FULL_FRAGMENT}
    ${FRAGMENT.PRODUCT.CATEGORY_FRAGMENT}
    ${FRAGMENT.PRODUCT.IMAGE_FRAGMENT}
    ${FRAGMENT.PRODUCER.DETAILS_FRAGMENT}
  `,
  EXCEL: gql`
    query Products(
      $take: Int
      $skip: Int
      $all: Boolean
      $field: FieldOrderProduct
      $order: Order
      $categories: String
      $q: String
    ) {
      products(take: $take, skip: $skip, all: $all, field: $field, order: $order, categories: $categories, q: $q) {
        product {
          ...ExcelProduct
          producer {
            ...ExcelProductProducer
          }
          ...ProductCategory
          ...ProductImage
        }
      }
    }
    ${FRAGMENT.PRODUCT.EXCEL_FRAGMENT}
    ${FRAGMENT.PRODUCT.CATEGORY_FRAGMENT}
    ${FRAGMENT.PRODUCT.IMAGE_FRAGMENT}
    ${FRAGMENT.PRODUCER.EXCEL_PRODUCT_FRAGMENT}
  `,
  DELETE: gql`
    mutation DeleteProducts($productIds: [ID!]!) {
      deleteProducts(productIds: $productIds) {
        affectedRows
        message
      }
    }
  `,
  FEATURED: gql`
    query FeaturedProduct {
      featuredProduct {
        product {
          ID_product
          name
          sale_format
          price_package
          producer {
            brand_name
          }
          product_image {
            file_image
            name_image
          }
        }
      }
    }
  `,
  NEW_FEATURED: gql`
    mutation NewFeaturedProduduct($productIds: [ID!]!, $isActive: Boolean) {
      newFeaturedProduduct(productIds: $productIds, isActive: $isActive) {
        product_ids
      }
    }
  `,
  ALL_SETUP: gql`
    query Products($take: Int, $all: Boolean) {
      products(take: $take, all: $all) {
        product {
          name
          ID_product
          sale_format
          price_package
          producer {
            brand_name
          }
          product_image {
            file_image
            name_image
          }
        }
      }
    }
  `,
}
export default PRODUCTS
