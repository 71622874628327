import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h2: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}))

export const Section = ({ title, producer = null, children }) => {
  const classes = useStyles()
  return (
    <Grid item xs>
      <Grid container spacing={3} direction="column">
        <Grid item xs>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" className={classes.h2}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
