import { Button, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '50rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    gap: '1.5rem',
  },
  containerBtns: {
    width: '30rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '1',
    justifyContent: 'space-around',
  },
}))
const ConfirmButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '1rem',
    height: '3rem',
    margin: '1rem',
    color: 'white',
    padding: (2, 28),
    fontWeight: 'bold',
    borderRadius: '10px',
  },
})(Button)
export default function ModalDeleteProductOrder({
  selected,
  setSelected,
  rowSelected,
  handleClose,
  productSelected,
  setProductSelected,
}) {
  const classes = useStyles()
  const deleteProduct = () => {
    setProductSelected(productSelected.filter((product) => product.ID_product !== rowSelected.ID_product))
    setSelected(selected.filter((product) => product.ID_product !== rowSelected.ID_product))
    handleClose()
  }
  return (
    <div className={classes.container}>
      <InfoIcon fontSize="large" color="primary" style={{ fontSize: 120 }} />
      <h1>
        {' '}
        Deseas eliminar de la lista el producto: <br />- {rowSelected.name}{' '}
      </h1>
      <div className={classes.containerBtns}>
        <ConfirmButton style={{ backgroundColor: '#f69223' }} onClick={() => handleClose()}>
          Cancelar
        </ConfirmButton>
        <ConfirmButton style={{ backgroundColor: '#8ac541' }} onClick={() => deleteProduct()}>
          Confirmar
        </ConfirmButton>
      </div>
    </div>
  )
}
