import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useCreateShippingStatus() {
  const [createShippingStatus, { data, loading, error }] = useMutation(QUERY.SHIPPING_STATUS.CREATE, {
    context: { clientName: 'apiStore' },
  })

  return { createShippingStatus, data, loading, error }
}

export function useUpdateShippingStatus() {
  const [updateShippingStatus, { data, loading, error }] = useMutation(QUERY.SHIPPING_STATUS.UPDATE, {
    context: { clientName: 'apiStore' },
  })

  return { updateShippingStatus, data, loading, error }
}
