import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, Toolbar, Typography, makeStyles, withStyles, Button } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
}))
const HelpButton = withStyles({
  root: {
    textTransform: 'none',
    width: '20%',
    alignSelf: 'center',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'navajowhite',
    },
  },
})(Button)
export const EnhancedTableToolbar = ({ productsDownload }) => {
  const classes = useToolbarStyles()
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Editor de precios
      </Typography>
      <HelpButton variant="text" color="primary" onClick={() => productsDownload()} title="Descargar excel">
        <GetApp /> <span style={{ paddingLeft: '1rem', fontSize: '14px' }}>Descargar excel</span>
      </HelpButton>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}
