import { gql } from '@apollo/client'

const QUOTER = {
  ADD: gql`
    mutation AddQuote($payload: createQuote) {
      addQuote(payload: $payload) {
        message
      }
    }
  `
}
export default QUOTER
