import { Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import { Close, CloudUpload } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import { FileAttached } from './producerEditor/FileAttached'
import { FileAttachedDetail } from './producerEditor/FileAttachedDetail'
import { useAutoAnimate } from '@formkit/auto-animate/react'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    borderRadius: '8px',
    transition: 'all ease 0.5s',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23ABAAB0' stroke-width='1' stroke-dasharray='24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  noInteractive: {
    pointerEvents: 'none',
  },
  dragging: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23e9e9e9' rx='8' ry='8' stroke='%23ABAAB0' stroke-width='4' stroke-dasharray='24' stroke-dashoffset='500' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
}))

export const InputUploadFile = ({ fileList, handleUpload, handleDelete, buttonLabel = 'Subir archivo' }) => {
  const [parent] = useAutoAnimate()
  const classes = useStyles()
  const inputContainer = useRef()
  const inputFile = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [file, setFile] = useState(null)

  const handleDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }
  const handleDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
  }
  const handleDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const inputFileElement = inputFile.current
    inputFileElement.files = null
    inputFileElement.value = ''
    const files = event.dataTransfer.files
    if (files.length > 1) {
      return alert('Sólo puedes poner un archivo')
    }
    setFile(files[0])
    setIsDragging(false)
  }

  const handleFileInput = (event) => {
    setFile(event.target.files[0])
  }

  const handleRemoveFile = () => {
    setFile(null)
  }

  const handleClick = (file) => {
    handleUpload(file)
    setFile(null)
  }

  useEffect(() => {
    const inputContainerElement = inputContainer.current

    inputContainerElement.addEventListener('dragenter', handleDragEnter)
    inputContainerElement.addEventListener('dragover', handleDragOver)
    inputContainerElement.addEventListener('dragleave', handleDragLeave)
    inputContainerElement.addEventListener('drop', handleDrop)
    return () => {
      inputContainerElement.removeEventListener('dragenter', handleDragEnter)
      inputContainerElement.removeEventListener('dragover', handleDragOver)
      inputContainerElement.removeEventListener('dragleave', handleDragLeave)
      inputContainerElement.removeEventListener('drop', handleDrop)
    }
  }, [])

  return (
    <Grid container direction="column" style={{ gap: '1rem' }}>
      {/* DRAG AND DROP ZONE */}
      <Grid item className={`${isDragging ? classes.dragging : ''} ${classes.inputContainer}`} ref={inputContainer} xs>
        <Grid container alignItems="center" direction="column" style={{ gap: '1rem', padding: '1.5rem' }}>
          <Grid item className={classes.noInteractive}>
            <CloudUpload color="primary" fontSize="inherit" style={{ fontSize: '48px' }} />
          </Grid>
          <Grid item className={classes.noInteractive}>
            {!isDragging ? (
              <Typography variant="h6" align="center">
                Arrastra y suelta un archivo para comenzar a subirlo.
              </Typography>
            ) : (
              <Typography variant="h6">Suelta el archivo</Typography>
            )}
          </Grid>
          <Grid item style={{ minWidth: '320px' }} className={classes.noInteractive}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Divider />
              </Grid>
              <Grid item>
                <Typography>O</Typography>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={isDragging ? classes.noInteractive : ''}>
            <input
              accept="*"
              style={{ display: 'none' }}
              id={`contained-button-file-${buttonLabel.replaceAll(' ', '')}`}
              type="file"
              onInput={handleFileInput}
              ref={inputFile}
            />
            <label htmlFor={`contained-button-file-${buttonLabel.replaceAll(' ', '')}`}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={{ color: 'white', textTransform: 'none' }}>
                Elige un archivo
              </Button>
            </label>
          </Grid>
        </Grid>
      </Grid>
      {/* STATE FILE */}
      {Boolean(file) && (
        <Grid item>
          <FileAttached file={file} handleRemove={handleRemoveFile} />
        </Grid>
      )}
      {/* BUTTON */}
      <Grid item style={{ alignSelf: 'end' }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ color: 'white', fontWeight: 'bold' }}
          onClick={() => handleClick(file)}>
          {buttonLabel}
        </Button>
      </Grid>
      {/* API FILES */}

      <Grid item xs>
        <Grid container innerRef={parent} style={{ display: 'grid', gap: '1rem' }}>
          {fileList.map(
            (item) =>
              !item?.toDelete && (
                <Grid item key={item.id}>
                  <FileAttachedDetail item={item} handleDelete={handleDelete} />
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
