import { Grid, MenuItem, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  container: {
    padding: '1.5rem',
  },
  containerBtns: {
    padding: '1.5rem 2.5rem !important',
  },
  chip: {
    background: theme.palette.secondary.main,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  menuSelected: {
    background: 'red!important',
    '& > .item': {
      background: 'orange!important',
      color: 'white',
    },
  },
}))

export const OptionSelect = ({ data, keysMap, key }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={1}
      className="item"
      style={{ padding: '0.5rem 1rem', border: '1px solid orange', borderRadius: '0.5rem' }}>
      {Object.entries(keysMap).map((el) => (
        <Grid key={`option-item-${el[0]}${el[1]}`} item xs={12} md>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              {el[1]}
            </Grid>
            <Grid item>{data[el[0]]}</Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
