import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function usePostUpdateStatus() {
  const [updateStatusOrder, { data, loading, error }] = useMutation(QUERY.ORDER_STATUS.UPDATE, {
    context: { clientName: 'apiStore' },
  })

  return { updateStatusOrder, data, loading, error }
}
