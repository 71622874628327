export default async function sendWsSurveyStore(nameStore, phoneStore) {
  const res = await fetch(`${process.env.REACT_APP_API_WSP}/whatsapp-messages/send-template`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN_WASAPI}`,
    },
    body: createBody(nameStore, phoneStore),
  })
  const data = await res.json()
  return data
}

const createBody = (nameStore, phoneStore) => {
  return JSON.stringify({
    recipients: phoneStore.replace('+', ''),
    template_id: `${process.env.REACT_APP_ID_TEMPLATE_FIRST_PURCHASE_SURVEY_WASAPI}`,
    phoneSelect: `${process.env.REACT_APP_NUMBER_WASAPI}`,
    contact_type: 'phone',
    chatbot_status: 'disable',
    conversation_status: 'unchanged',
    header_var: [
      {
        text: '{{1}}',
        val: nameStore,
      },
    ],
  })
}
