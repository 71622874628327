import { gql } from '@apollo/client'

const CATEGORIES = {
  CREATE_CATEGORY_PRODUCT: gql`
    mutation Mutation($categoryPayload: [categoryPayload]) {
      createCategory(categoryPayload: $categoryPayload) {
        newCategories {
          category
          ID_category_product
        }
        message
        existingCategories
        categories {
          count
        }
      }
    }
  `,
  DELETE_PRODUCT: gql`
    mutation DeleteProductCategoryById($idProduct: ID!, $categories: [String!]!) {
      deleteProductCategoryById(ID_product: $idProduct, categories: $categories) {
        message
      }
    }
  `,
  PRODUCTS: gql`
    query CategoriesWithProducts($all: Boolean) {
      categoriesWithProducts(all: $all) {
        categories {
          ID_category_product
          category
        }
      }
    }
  `,
  PRODUCERS: gql`
    query Categories($idCategoryType: ID!) {
      categoriesByIdType(ID_category_type: $idCategoryType) {
        categories {
          category
          ID_category
          category_type {
            ID_category_type
            type
          }
        }
      }
    }
  `,
}
export default CATEGORIES
