import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetUser() {
  const [requestGetUser, { data, error }] = useLazyQuery(QUERY.USER.GET, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
  })
  return { requestGetUser, data, error }
}

export function useGetUsers() {
  const { data, error, loading } = useQuery(QUERY.USER.GET_USERS, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { vendors: data?.users?.user, error, loading }
}
