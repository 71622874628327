import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetProducersList({
  skip = 0,
  take = null,
  field = 'BRAND_NAME',
  order = 'ASC',
  isActive = true,
  isDeleted = false,
}) {
  const { data, error, loading } = useQuery(QUERY.PRODUCERS.LIST, {
    variables: {
      skip,
      take,
      field,
      order,
      isActive,
      isDeleted,
    },
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { producers: data?.producers, error, loading }
}

export function useGetProducers({
  skip = 0,
  take = 12,
  order = 'ASC',
  field = null,
  isActive = null,
  isDeleted = false,
  q = '',
}) {
  const { data, error, loading } = useQuery(QUERY.PRODUCERS.GET, {
    variables: {
      skip,
      take,
      order,
      field,
      isActive,
      isDeleted,
      q,
    },
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { producers: data?.producers, error, loading }
}

export function useGetLazyProducers() {
  const [requestProducers, { data, error }] = useLazyQuery(QUERY.PRODUCERS.GET, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { requestProducers, data, error }
}
