import { gql } from '@apollo/client'
import { FRAGMENT } from '../fragments'

const PRODUCERS = {
  GET: gql`
    query GetProducers(
      $skip: Int
      $take: Int
      $order: Order
      $field: FieldOrderProducer
      $isActive: Boolean
      $isDeleted: Boolean
      $q: String
    ) {
      producers(
        skip: $skip
        take: $take
        order: $order
        field: $field
        isActive: $isActive
        isDeleted: $isDeleted
        q: $q
      ) {
        message
        producer {
          ID_producer
          rut
          brand_name
          additional_info
          web
          isActive
          isDeleted
          created_at
          modified_at
          isPublished
          slug
          slogan
          history
          min_purchase
          business_name
          modified_by
          delivery_time
          contrat_start
          type_sale {
            ID_type_sale
            type
          }
          formalization {
            ID_formalization
            health_resolution
            start_activities
            created_at
            modified_at
          }
          bank_account {
            ID_bank_account
            rut
            holder
            bank
            type
            number
          }
          files {
            ID_files
            file_name
            ID_producer
          }
          direction {
            ID_direction
            street
            number
            reference
            ID_producer
            code_country
            country
            label
            latitud
            longitud
            municipality
            region
            sub_region
            local_or_office
          }
          producer_category {
            ID_producer_category
            category {
              ID_category
              category
              category_type {
                ID_category_type
                type
              }
            }
          }
          contact {
            ID_contact
            auth_id
            email
            name
            phone
            isMain
          }
        }
        _count
        skip
        take
      }
    }
  `,
  LIST: gql`
    query GetProducers(
      $skip: Int
      $take: Int
      $order: Order
      $field: FieldOrderProducer
      $isActive: Boolean
      $isDeleted: Boolean
    ) {
      producers(skip: $skip, take: $take, order: $order, field: $field, isActive: $isActive, isDeleted: $isDeleted) {
        _count
        producer {
          ...ExcelProductProducer
        }
      }
    }
    ${FRAGMENT.PRODUCER.EXCEL_PRODUCT_FRAGMENT}
  `,
  PRODUCER_BY_CATEGORY_TYPE: gql`
    query SearchProducerByCategoryType($idCategoryType: [ID!]!, $region: [ID], $q: String) {
      searchProducerByCategoryType(ID_category_type: $idCategoryType, region: $region, q: $q) {
        _totalFound
        _totalProducers
        producers {
          ID_producer
          brand_name
          contact {
            phone
          }
        }
      }
    }
  `,
  PRODUCER_BY_ID: gql`
    query ProducerWithProducts($idProducer: ID) {
      producerWithProducts(ID_producer: $idProducer) {
        productCount
        deliveryZones {
          zones
          _count
        }
        producer {
          ID_producer
          additional_info
          bank_account {
            type
            rut
            number
            holder
            bank
            ID_bank_account
          }
          brand_name
          business_name
          contact {
            ID_contact
            email
            name
            phone
          }
          delivery_time
          direction {
            label
          }
          files {
            file_name
          }
          formalization {
            health_resolution
            start_activities
          }
          history
          isActive
          isDeleted
          isPublished
          min_purchase
          producer_category {
            category {
              category_type {
                type
                ID_category_type
              }
              ID_category
              category
            }
          }
          rut
          slug
          type_sale {
            type
            ID_type_sale
          }
          web
          products_info {
            hasCertification
          }
        }
      }
    }
  `,
  DOCUMENTS_BY_ID: gql`
    query Documents_by_id($idProducer: ID!) {
      certificationByIdProducer(ID_producer: $idProducer) {
        certification {
          ID_certification
          type_certification {
            ID_type_certification
            type
            description
          }
          valid_until
        }
      }
      resolutionByIdProducer(ID_producer: $idProducer) {
        resolution {
          ID_resolution
          emit_date
          number
          verification_code
        }
      }
    }
  `,
}
export default PRODUCERS
