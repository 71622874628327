import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

// titulos de columnas
const headCells = [
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Fecha de ingreso', name: true },
  { id: 'number', numeric: true, disablePadding: false, label: 'N° Pedido' },
  { id: 'detail', numeric: false, disablePadding: false, label: 'Ver detalle', edit: true },
  { id: 'store', numeric: false, disablePadding: false, label: 'Cliente (Tienda)' },
  { id: 'store_region', numeric: false, disablePadding: false, label: 'Dirección de despacho' },
  { id: 'store_municipality', numeric: false, disablePadding: false, label: 'Comuna / Ciudad' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
  { id: 'sent', numeric: false, disablePadding: true, label: 'Enviado' },
  { id: 'editShipping', numeric: false, disablePadding: true, label: 'Editar envío' },
  { id: 'total_order', numeric: true, disablePadding: false, label: 'Costo total' },
  { id: 'vendor', numeric: false, disablePadding: true, label: 'Vendedor' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Acciones', edit: true },
]

export function EnhancedTableHead(props) {
  // props.orders props.loading
  const { classes, onSelectAllClick, order, field, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.name ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={field === headCell.id ? order : false}>
            <b>
              {!headCell.edit ? (
                <TableSortLabel
                  active={field === headCell.id}
                  direction={field === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {field === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span aling="right">{headCell.label}</span>
              )}
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  field: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}
