import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import { Add } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import NewOrderContext from '../../context/NewOrderState'

const HelpButton = withStyles({
  root: {
    textTransform: 'none',
    width: '13rem',
  },
})(Button)

function createData(ID_product, nameProduct, producer, active, format, price, product) {
  return { ID_product, nameProduct, producer, active, format, price, product }
}

const rows = (products) => {
  const dataList = products.map((product) =>
    createData(
      product.ID_product,
      product.name,
      product.producer.brand_name,
      product.isActive,
      product.sale_format,
      product.price_package,
      product
    )
  )
  return dataList
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
// titulos de columnas
const headCells = [
  { id: 'nameProduct', numeric: false, disablePadding: true, label: 'Productos', name: true },
  { id: 'producer', numeric: false, disablePadding: false, label: 'Productor' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Estado' },
  { id: 'format', numeric: false, disablePadding: false, label: 'Formato' },
  { id: 'price', numeric: true, disablePadding: false, label: 'Precio iva incl.' },
]

function EnhancedTableHead(props) {
  // props.products props.loading
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.name ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <b>
              {headCell.id !== 'edit' ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span aling="right">{headCell.label}</span>
              )}
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
}))

const EnhancedTableToolbar = (props) => {
  const { orderStore, setOrderStore } = useContext(NewOrderContext)

  const classes = useToolbarStyles()
  const { numSelected, handleClose, selected, setProductSelected, productSelected, allProducts } = props

  function productAddSelected(selected) {
    const currentProducts = []
    selected.forEach((product) => {
      const alreadySelected = productSelected.find((item) => item.ID_product === product.ID_product)
      return currentProducts.push({ ...product, qty: alreadySelected ? alreadySelected.qty : 1 })
    })
    setProductSelected(currentProducts)
    setOrderStore({ ...orderStore, details: currentProducts })
    handleClose()
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} {numSelected === 1 ? 'Producto seleccionado' : ' Productos seleccionados'}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Productos en Catálogo
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <HelpButton variant="text" color="primary" onClick={() => productAddSelected(selected)}>
            <Add /> <p>Agregar productos</p>
          </HelpButton>
        </>
      ) : (
        ''
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export default function TableProductSearch({
  handleClose,
  productSelected,
  setProductSelected,
  selected,
  setSelected,
  allProducts,
  loading,
  setSkip,
  productCount,
  products,
  page,
  setPage,
  paramsSearched,
}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('stockQty')
  const dense = true
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows(products).map((n) => n.ID_product)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, product) => {
    const selectedIndex = selected.some((item) => item.ID_product === product.ID_product)

    if (selectedIndex) {
      setSelected(selected.filter((item) => item.ID_product !== product.ID_product))
    } else {
      setSelected([...selected, product])
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSkip(rowsPerPage * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (product) => selected.some((item) => item.ID_product === product.ID_product)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleClose={handleClose}
          selected={selected}
          setProductSelected={setProductSelected}
          productSelected={productSelected}
          allProducts={allProducts}
        />
        <TableContainer>
          {loading ? <LinearProgress style={{ width: '100%' }} /> : ''}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={products.length}
            />
            <TableBody>
              {stableSort(rows(products), getComparator(order, orderBy))
                .slice(0, rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.product)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.ID_product}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.product)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          selected={isItemSelected}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.nameProduct}
                      </TableCell>
                      <TableCell align="center">{row.producer}</TableCell>
                      <TableCell align="center">{row.active ? 'Activo' : 'Inactivo'}</TableCell>
                      <TableCell align="center">{row.format + ' un.'}</TableCell>
                      <TableCell align="right">{'$' + new Intl.NumberFormat('de-DE').format(row.price)}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={paramsSearched ? products.length : productCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
