const totalChange = (data, net) => {
  const TAX_RATE = 0.19
  const neto = (+net).toFixed(2)
  const total = (neto * (TAX_RATE + 1)).toFixed(0)
  const tax = (total - neto).toFixed(0)
  return {
    detail: data.detail,
    orderNote: {
      ...data?.orderNote,
      ...data?.order,
      total,
      total_order: total,
      discount: null,
      neto,
      iva: tax,
      comment_by_order: null,
    },
  }
}
export const totalCostByProducer = async (data, allOrdersByProducer = false) => {
  if (allOrdersByProducer) {
    let newTotals = []
    data.map((order) => {
      const neto = order.detail.reduce((a, cur) => a + cur.net_historical_cost, 0).toFixed(0)
      newTotals.push(totalChange(order, neto))
    })
    return newTotals
  } else {
    const total = data.detail.reduce((a, cur) => a + cur.net_historical_cost, 0).toFixed(0)
    return totalChange(data, total)
  }
}

export const totalPriceByProducer = async (data, allOrdersByProducer = false) => {
  if (allOrdersByProducer) {
    let newTotals = []
    data.map((order) => {
      const total = order.detail.reduce((a, cur) => a + cur.sub_total, 0).toFixed(0)
      newTotals.push(totalChange(order, total))
    })
    return newTotals
  } else {
    const total = data.detail.reduce((a, cur) => a + cur.sub_total, 0).toFixed(0)
    return totalChange(data, total)
  }
}
