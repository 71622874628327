import { Button, Divider, makeStyles, Grid, Input, FormControl, InputLabel } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../context/AlertContext'
import NewOrderContext from '../../context/NewOrderState'
import { InputGeo } from '../InputGeo'
import { useCreateStoreDirection } from '../../hooks/usePostDirection'
import { QUERY } from '../../services/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: '1',
    width: '50vw',
  },
  textField: {
    width: '8rem',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 4, 3),
  },
  divButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  TextField: {
    margin: '10',
  },
}))
export default function ModalAddAddres({ setDirections, directions }) {
  const classes = useStyles()
  const [address, setAddress] = useState({})
  const [hasNumber, setHasNumber] = useState(true)
  const [localOffice, setLocalOffice] = useState('')
  const [reference, setReference] = useState('')
  const [stateDirections, setStateDirections] = useState(directions)
  const [AddressNumberState, setAddressNumberState] = useState('')
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
  const { orderStore } = useContext(NewOrderContext)
  const { setAlert } = useContext(AlertContext)
  const { createStoreDirection } = useCreateStoreDirection()

  const postAddress = async () => {
    const { ID_store } = orderStore
    const { Country, Region, SubRegion, Municipality, Street, AddressNumber, Label, Geometry } = address

    if (!Street) {
      setAlert({ open: true, message: 'Selecciona una ubicación con calle', severity: 'error' })
    } else {
      const newAddress = {
        code_country: Country,
        region: Region,
        sub_region: SubRegion,
        street: Street,
        municipality: Municipality,
        number: AddressNumber ? AddressNumber : AddressNumberState,
        local_or_office: localOffice,
        label: Label,
        reference: reference,
        latitude: Geometry.Point[0].toString(),
        longitude: Geometry.Point[1].toString(),
        ID_store,
      }

      try {
        const { data, loading, error } = await createStoreDirection({
          variables: { payload: newAddress },
          refetchQueries: [
            {
              query: QUERY.DIRECTION.GET_BY_ID_STORE,
              variables: {
                idStore: ID_store,
              },
              fetchPolicy: 'cache-and-network',
              context: { clientName: 'apiStore' },
            },
          ],
        })
        if (error) {
          setAlert({ open: true, message: res.message, severity: 'error' })
        } else {
          setAlert({ open: true, message: 'Dirección agregada', severity: 'success' })
          setStateDirections([...stateDirections, data.createStoreDirection.storeDirection])
        }
      } catch (error) {
        setAlert({ open: true, message: 'Ha ocurrido un error, inténtalo de nuevo', severity: 'error' })
      }
    }
  }

  useEffect(() => {
    setDirections(stateDirections)
  }, [stateDirections])

  return (
    <div className={classes.root}>
      <h2 id="transition-modal-title">Añadir otra dirección </h2>
      <p id="transition-modal-description">Tienda : {orderStore.name}</p>
      <div className={classes.inputsAddAddress}>
        <Grid container direction="column" style={{ marginBottom: '1rem' }} spacing={3}>
          <Grid container item spacing={2}>
            <Grid item sm={hasNumber ? 12 : 8}>
              <InputGeo setAddress={setAddress} setHasNumber={setHasNumber} required />
            </Grid>
            {!hasNumber && (
              <Grid item sm={4}>
                <FormControl variant="standard" fullWidth color="secondary">
                  <InputLabel htmlFor="Número">Número</InputLabel>
                  <Input
                    type="text"
                    id="Número"
                    value={AddressNumberState}
                    onChange={(e) => setAddressNumberState(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={4}>
              <FormControl variant="standard" fullWidth color="secondary">
                <InputLabel htmlFor="Número">Local/Oficina</InputLabel>
                <Input type="text" id="Número" value={localOffice} onChange={(e) => setLocalOffice(e.target.value)} />
              </FormControl>
            </Grid>
            <Grid item sm={8}>
              <FormControl variant="standard" fullWidth color="secondary">
                <InputLabel htmlFor="Número">Referencia</InputLabel>
                <Input type="text" id="Número" value={reference} onChange={(e) => setReference(e.target.value)} />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.divButton}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classes.button}
          startIcon={<CheckCircleOutline />}
          onClick={postAddress}>
          Añadir dirección
        </Button>
      </div>
      <Divider className={classes.divider} orientation="horizontal" />
      <div>
        <p>Direcciones registradas: </p>
        {orderStore.addressArray.map((address) => (
          <p key={address.ID_store_direction}>{`${address.street} #${address.number}, ${
            address.local_or_office ? address.local_or_office + ',' : ''
          } ${address.municipality ? address.municipality + ',' : address.subregion ? address.subregion + ',' : ''} ${
            address.country
          }`}</p>
        ))}
      </div>
    </div>
  )
}
