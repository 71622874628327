import { Button, Grid, Input, InputAdornment, makeStyles, Typography, MenuItem, TextField } from '@material-ui/core'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NewOrderContext from '../../context/NewOrderState'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
}))

export default function ModalShipping({ handleClose }) {
  const classes = useStyles()
  const { orderStore, setOrderStore } = useContext(NewOrderContext)
  const [value, setValue] = useState(orderStore.shipping)

  function handleConfirm() {
    setOrderStore({ ...orderStore, shipping: +value })
    handleClose()
  }

  const handleChange = (inputValue) => {
    setValue(inputValue)
  }

  return (
    <Grid container item direction="column" spacing={2}>
      <h2 id="transition-modal-title">Aplicar costo de despacho</h2>
      <Grid container item alignItems="center" spacing={2}>
        <Grid item md={3}>
          <Typography variant="inherit">Monto:</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            id="shipping"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            startAdornment={<InputAdornment position="start">{'$'}</InputAdornment>}
            aria-describedby="apply-shipping"
            type="number"
            inputProps={{
              'aria-label': 'apply-shipping',
            }}></Input>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={handleConfirm}>
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
