import { Chip, Grid, Input, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
  },
}))

export default function ListSelect({
  selectedValues,
  onChange,
  renderElement,
  options,
  optionsRender,
  label,
  id,
  ...rest
}) {
  const classes = useStyles()
  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        <Typography variant="h1" className={classes.h1}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          labelId={`${rest.multiple ? 'multiple' : 'simple'}-${id}-label`}
          id={`${rest.multiple ? 'multiple' : 'simple'}-${id}`}
          value={selectedValues}
          input={<Input id={`select-${rest.multiple ? 'multiple' : 'simple'}-${id}`} />}
          name={id}
          onChange={onChange}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {rest?.multiple ? selected.map(renderElement) : renderElement(selected || null)}
            </div>
          )}
          {...rest}>
          {options.map(optionsRender)}
        </Select>
      </Grid>
    </Grid>
  )
}
