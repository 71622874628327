export const slugGenerator = (str) => {
  if (str) {
    const woSpaces = str.replaceAll(/[\[\]\{\}\/,\. ()_+\\]+/g, '-')
    const lastChar = woSpaces.substring(woSpaces.length - 1)
    const woCapitalize = lastChar !== '-' ? woSpaces.toLowerCase() : woSpaces.slice(0, -1).toLowerCase()
    const woTildes = woCapitalize.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const woSCharacters = woTildes.replace(/[^A-z\d-][\\\^]?/g, '')
    const cleanLast = woSCharacters.replace(/-{0,}(?:$)|-{1,}(?=-)/g, '')
    return cleanLast
  }
  return null
}
