export const formatRut = (rut) => {
  if (rut.length === 10) {
    const first = rut.slice(0, 2)
    const second = rut.slice(2, 5)
    const third = rut.slice(5, 8)
    const checkerDigit = rut.slice(9)
    return `${first}.${second}.${third}-${checkerDigit}`
  } else if (rut.length === 9) {
    const first = rut.slice(0, 1)
    const second = rut.slice(1, 4)
    const third = rut.slice(4, 7)
    const checkerDigit = rut.slice(8)
    return `${first}.${second}.${third}-${checkerDigit}`
  }
}
