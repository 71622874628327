import { ContentState, convertFromHTML } from 'draft-js'
import { QUERY } from '../services/queries'
import { useMutation, useQuery } from '@apollo/client'
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg'
import { useState } from 'react'
import AlertContext from '../context/AlertContext'
import { useContext } from 'react'

const initialLoadBtns = {
  discount: { status: false, type: '' },
  adHomePage: { status: false, type: '' },
  adCatalogue: { status: false, type: '' },
  suggestedProducts: { status: false, type: '' },
}

const initialStates = {
  ads: {
    adHome: MUIEditorState.createEmpty(),
    adCatalogue: MUIEditorState.createEmpty(),
  },
  discounts: {
    firstPurchase: 0,
  },
  products: [],
  suggestedProducts: [],
}

export function useSetup() {
  //general states
  const [load, setLoad] = useState(initialLoadBtns)
  const [initialState, setInitialState] = useState(initialStates)
  const { setAlert } = useContext(AlertContext)
  //ads
  const [adHomePage, setAdHomePage] = useState(MUIEditorState.createEmpty())
  const [adCatalogue, setAdCatalogue] = useState(MUIEditorState.createEmpty())
  //discounts
  const [dscFirstPurchase, setDscFirstPurchase] = useState(0)
  //products
  const [suggestedProducts, setSuggestedProducts] = useState([])
  const [products, setProducts] = useState([])

  const { loading: loadingDiscounts } = useQuery(QUERY.DISCOUNTS.FULL, {
    // fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiStore' },
    onCompleted: (data) => {
      const { ad_main, ad_catalogue, first_purchase } = data.discount.discount[0]
      setAdHomePage(convertToMUIEditor(ad_main || '<p></p>'))
      setAdCatalogue(convertToMUIEditor(ad_catalogue || '<p></p>'))
      setDscFirstPurchase(first_purchase)
      setInitialState({
        ...initialState,
        discounts: {
          firstPurchase: first_purchase,
        },
        ads: {
          adHome: convertToMUIEditor(ad_main || '<p></p>'),
          adCatalogue: convertToMUIEditor(ad_catalogue || '<p></p>'),
        },
      })
    },
  })
  const { loading: loadingFeatured, refetch: refetchFeatured } = useQuery(QUERY.PRODUCTS.FEATURED, {
    // fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
    onCompleted: (data) => {
      const products = structuredClone(data.featuredProduct.product)
      setSuggestedProducts(products)
      setInitialState({
        ...initialState,
        suggestedProducts: products,
      })
    },
  })

  const { loading: loadingProducts } = useQuery(QUERY.PRODUCTS.ALL_SETUP, {
    variables: {
      take: null,
      all: false,
    },
    // fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
    onCompleted: (data) => {
      const products = structuredClone(data.products.product)
      setProducts(
        products.filter(
          (product) => !suggestedProducts.some((suggProduct) => suggProduct.ID_product === product.ID_product)
        )
      )
      setInitialState({
        ...initialState,
        products,
      })
    },
  })

  const onUpdateDone = (message, type, field) => {
    setLoad({ ...load, [field]: { status: false, type: '' } })
    setAlert({
      open: true,
      message: message,
      severity: type,
    })
  }

  const [featuredMutation] = useMutation(QUERY.PRODUCTS.NEW_FEATURED, { context: { clientName: 'apiProducer' } })
  const [updateAdMain] = useMutation(QUERY.DISCOUNTS.PUT_AD_MAIN, {
    onCompleted: () => onUpdateDone('El aviso de la página principal ha sido modificado', 'success', 'adHomePage'),
    onError: () => onUpdateDone('Ocurrió un error al actualizar', 'error'),
  })
  const [updateAdCatalogue] = useMutation(QUERY.DISCOUNTS.PUT_AD_CATALOGUE, {
    onCompleted: () => onUpdateDone('El aviso del catálogo ha sido modificado.', 'success', 'adCatalogue'),
    onError: () => onUpdateDone('Ocurrió un error al actualizar', 'error'),
  })
  const [updateFirstPurchase] = useMutation(QUERY.DISCOUNTS.PUT_FIRST_PURCHASE, {
    onCompleted: () => onUpdateDone('El descuento de primera compra ha sido modificado.', 'success', 'discount'),
    onError: () => onUpdateDone('Ocurrió un error al actualizar', 'error'),
  })

  const handleUpdateAdMain = async () => {
    const stringAdMain = toHTML(adHomePage.getCurrentContent())
    setLoad({ ...load, adHomePage: { status: true, type: 'save' } })
    const {
      data: {
        updateDiscountStatusById: {
          discount: { ad_main },
        },
      },
    } = await updateAdMain({
      variables: {
        idDiscount: '1',
        adMain: stringAdMain === '<p></p>' ? null : stringAdMain,
      },
    })

    setInitialState({ ...initialState, ads: { ...initialState.ads, adHome: convertToMUIEditor(ad_main || '<p></p>') } })
  }

  const handleUpdateAdCatalogue = async () => {
    const stringAdCatalogue = toHTML(adCatalogue.getCurrentContent())
    setLoad({ ...load, adCatalogue: { status: true, type: 'save' } })
    const {
      data: {
        updateDiscountStatusById: {
          discount: { ad_catalogue },
        },
      },
    } = await updateAdCatalogue({
      variables: {
        idDiscount: '1',
        adCatalogue: stringAdCatalogue === '<p></p>' ? null : stringAdCatalogue,
      },
    })
    setInitialState({
      ...initialState,
      ads: { ...initialState.ads, adCatalogue: convertToMUIEditor(ad_catalogue || '<p></p>') },
    })
  }

  const handleUpdateFirstPurchase = async () => {
    const discount = dscFirstPurchase
    setLoad({ ...load, discount: { status: true, type: 'save' } })
    const {
      data: {
        updateDiscountStatusById: {
          discount: { first_purchase },
        },
      },
    } = await updateFirstPurchase({
      variables: {
        idDiscount: '1',
        firstPurchase: discount,
      },
    })
    setInitialState({ ...initialState, discounts: { ...initialState.discounts, first_purchase: first_purchase } })
  }

  const postFeatured = async () => {
    const variables = {
      productIds: suggestedProducts.map((product) => product.ID_product),
      isActive: true,
    }

    setLoad({ ...load, suggestedProducts: { ...load.suggestedProducts, status: true, type: 'save' } })

    const { data } = featuredMutation({
      variables,
      onCompleted: () => {
        refetchFeatured()
        onUpdateDone('Los productos sugeridos, se han actualizado con éxito', 'success', 'suggestedProducts')
      },
      onError: () => onUpdateDone('Ocurrió un error al actualizar', 'error'),
    })
  }

  const convertToMUIEditor = (text) => {
    if (text !== null && text !== undefined) {
      const html = convertFromHTML(text)
      const contentState = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)
      const editorState = MUIEditorState.createWithContent({}, contentState)
      return editorState
    } else {
      const editorState = MUIEditorState.createEmpty()
      return editorState
    }
  }

  return {
    state: {
      ads: {
        adHomePage,
        adCatalogue,
      },
      discounts: { firstPurchase: dscFirstPurchase },
      suggestedProducts,
      products,
    },
    modifiers: {
      adHome: setAdHomePage,
      adCatalogue: setAdCatalogue,
      firstPurchase: setDscFirstPurchase,
      suggestedProducts: setSuggestedProducts,
      products: setProducts,
      initialState: setInitialState,
      loading: setLoad,
    },
    updaters: {
      adHome: handleUpdateAdMain,
      adCatalogue: handleUpdateAdCatalogue,
      firstPurchase: handleUpdateFirstPurchase,
    },
    mutation: {
      postFeatured,
    },
    initialState,
    loading: { ...load, general: loadingDiscounts || loadingFeatured || loadingProducts },
  }
}
