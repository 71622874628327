import React from 'react'
import clsx from 'clsx'
import { lighten, Toolbar, Typography, makeStyles, Tooltip, IconButton } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
}))

export const EnhancedTableToolbar = ({ producersDownload }) => {
  const classes = useToolbarStyles()
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false,
      })}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Productores
      </Typography>
      <Tooltip title="Descargar productores">
        <IconButton aria-label="Descargar productores" onClick={() => producersDownload()}>
          <GetApp />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}
