export function groupIdShippingSummary(shipping) {
  const newOrderShippingStatus = shipping.reduce((prev, act) => {
    const newProducer = !prev.find((el) => el?.producer?.brand_name === act.producer.brand_name)
    const newTrackingNumber = !prev.find((el) => el?.tracking_number === act.tracking_number)
    if (newProducer) {
      return [...prev, { ...act, ID_shipping_status: [act.ID_shipping_status] }]
    } else {
      if (newTrackingNumber) {
        return [...prev, { ...act, ID_shipping_status: [act.ID_shipping_status] }]
      } else {
        const elToEditProducer = prev.findIndex((el) => el?.producer?.brand_name === act?.producer?.brand_name)
        const elToEditTrackingNumber = prev.findIndex((el) => el.tracking_number === act.tracking_number)
        if (elToEditTrackingNumber) {
          const newArr = prev
          newArr[elToEditTrackingNumber] = {
            ...newArr[elToEditTrackingNumber],
            ID_shipping_status: [...newArr[elToEditTrackingNumber].ID_shipping_status, act.ID_shipping_status],
          }
          return newArr
        } else {
          const newArr = prev
          newArr[elToEditProducer] = {
            ...newArr[elToEditProducer],
            ID_shipping_status: [...newArr[elToEditProducer].ID_shipping_status, act.ID_shipping_status],
          }
          return newArr
        }
      }
    }
  }, [])
  return newOrderShippingStatus
}
