import React, { Fragment, useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import PostAdd from '@material-ui/icons/PostAdd'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import logo from '../ComeS-02Sinbajada-01.svg'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import UserContext from '../context/UserContext'
import { IconPeople, IconPeopleAlt } from '@aws-amplify/ui-react'
import { PhonelinkSetup } from '@material-ui/icons'
import AppleIcon from './AppleIcon'
import { useGetAuthToken } from '../hooks/usePostAuth'
import { client, updateToken } from '../services/client'
import { useGetUser } from '../hooks/useGetUser'
import { getToken } from '../utils/getToken'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  img: {
    width: 160,
    margin: 10,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    backgroundColor: '#FFFFFF',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  fontColorPrimary: {
    color: '#9dc34f',
  },
  fontColorSecundary: {
    color: '#FFFFFF',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  bgSecondary: {
    backgroundColor: '#9dc34f',
  },
  container: {
    margin: '0 2rem',
    padding: '2rem',
  },
  Link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

export default function PersistentDrawerLeft({ children }) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const [openButtonProduct, setOpenButtonProduct] = useState(false)
  const [openButtonOrders, setOpenButtonOrders] = useState(false)
  const [openButtonProducers, setOpenButtonProducers] = useState(false)
  const [openButtonSetups, setOpenButtonSetups] = useState(false)
  const { setUser } = useContext(UserContext)
  const { authToken } = useGetAuthToken()
  const { requestGetUser } = useGetUser()

  async function signOut() {
    try {
      await Auth.signOut()
    } catch (error) {
      console.error('error signing out: ', error)
    }
  }

  const handleClickProduct = () => {
    setOpenButtonProduct(!openButtonProduct)
  }
  const handleClickOrders = () => {
    setOpenButtonOrders(!openButtonOrders)
  }

  const handleClickProducers = () => {
    setOpenButtonProducers(!openButtonProducers)
  }
  const handleClickSetups = () => {
    setOpenButtonSetups(!openButtonSetups)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleSignOut = () => {
    signOut()
    client.resetStore()
    localStorage.clear()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const getUser = async () => {
      const {
        data: {
          userByAuthId: { user: userApi },
        },
      } = await requestGetUser({
        variables: {
          authId: Auth.user.attributes.sub,
        },
      })
      setUser({
        auth_id: Auth.user.attributes.sub,
        email: Auth.user.attributes.email,
        name: Auth.user.attributes.name ?? 'Nombre de usuario no registrado',
        userApi,
      })
    }
    getUser()
  }, [])

  useEffect(() => {
    updateToken(localStorage.getItem('TOKEN_DASH'))
    if (!('Notification' in window)) {
      console.info('This browser not support desktop Notifications')
    } else {
      Notification.requestPermission()
    }
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={`${classes.fontColorPrimary} ${clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}`}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <a href="/">
              <img src={logo} className={classes.img} alt="logo" id="logo" />
            </a>
          </Typography>
          <div className={classes.grow}></div>
          <span style={{ fontSize: '18px' }}> {Auth.user.attributes.name ?? 'Nombre de usuario no registrado'}</span>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <AccountCircle />
            </IconButton>

            <Menu
              style={{ marginTop: '2.5rem' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}>
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={`${classes.drawer}`}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: `${classes.drawerPaper}`,
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Producto'].map((text, index) => (
            <Fragment key={text}>
              <ListItem button onClick={handleClickProduct}>
                <ListItemIcon classes={{ root: classes.fontColor }}>
                  <ShoppingCart color="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
                {openButtonProduct ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openButtonProduct} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/productos" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Ver Productos" />
                    </ListItem>
                  </Link>
                  <Link to="/agregar-producto" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Agregar productos" />
                    </ListItem>
                  </Link>
                  <Link to="/editor-precios" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Editor de precios" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </Fragment>
          ))}
          {/* PEDIDOS */}
          {['Pedidos'].map((text, index) => (
            <Fragment key={text}>
              <ListItem button onClick={handleClickOrders}>
                <ListItemIcon classes={{ root: classes.fontColor }}>
                  <PostAdd color="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
                {openButtonOrders ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openButtonOrders} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/pedidos" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Ver Pedidos" />
                    </ListItem>
                  </Link>
                  <Link to="/ingresar-pedido" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Ingresar pedido" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </Fragment>
          ))}
          {/* PRODUCTORES */}
          {['Productores'].map((text, index) => (
            <Fragment key={text}>
              <ListItem button onClick={handleClickProducers}>
                <ListItemIcon classes={{ root: classes.fontColor }} style={{ fontSize: '30px' }}>
                  <IconPeople color="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
                {openButtonProducers ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openButtonProducers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/productores" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Ver Productores" />
                    </ListItem>
                  </Link>
                  <Link to="/pedidos-productor" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Ver Pedidos" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </Fragment>
          ))}
          {/* COTIZADOR */}
          <Link to="/cotizador" className={classes.Link}>
            <ListItem button>
              <ListItemIcon classes={{ root: classes.fontColor }} style={{ fontSize: '30px' }}>
                <AppleIcon />
              </ListItemIcon>
              <ListItemText primary="Cotizador" />
            </ListItem>
          </Link>
          {/* CONFIGURACION DE PAGINAS */}
          {['Configuraciones'].map((text, index) => (
            <Fragment key={text}>
              <ListItem button onClick={handleClickSetups}>
                <ListItemIcon classes={{ root: classes.fontColor }} style={{ fontSize: '30px' }}>
                  <PhonelinkSetup color="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
                {openButtonSetups ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openButtonSetups} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/configuracion/pagina-tiendas" className={classes.Link}>
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Página Tiendas" />
                    </ListItem>
                  </Link>
                  <Link to="/" className={classes.Link}>
                    <ListItem button className={classes.nested} disabled>
                      <ListItemText primary="Página Productores" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Drawer>
      <main
        className={`${clsx(classes.content, {
          [classes.contentShift]: open,
        })}`}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}
