import React, { useContext, useEffect, useState } from 'react'
import {
  Checkbox,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  ButtonGroup,
} from '@material-ui/core'
import TransitionsModal from '../modal/ModalContainer'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { EnhancedTableHead } from './EnhancedTableHead'
import { Close, Done } from '@material-ui/icons'
import AlertContext from '../../context/AlertContext'
import UserContext from '../../context/UserContext'
import { usePostUpdateProduct } from '../../hooks/usePostProducts'
import { QUERY } from '../../services/queries'

function createData(
  idProduct,
  nameProduct,
  producer,
  saleFormat,
  unitCost,
  unitPrice,
  price,
  priceOffer,
  statusProduct,
  net_price
) {
  return {
    idProduct,
    nameProduct,
    producer,
    saleFormat,
    unitCost,
    unitPrice,
    price,
    priceOffer,
    statusProduct,
    net_price,
  }
}

const rows = (products) => {
  if (products && products.length !== 0) {
    const dataList = products.map((product) =>
      createData(
        product.ID_product,
        product.name,
        product.producer.brand_name,
        product.sale_format,
        product.net_unit_cost,
        product.wholesale_unit_price,
        product.price_package,
        product.suggested_sale_price,
        product.isActive ? 'Activo' : 'Inactivo',
        product.net_price_package
      )
    )
    return dataList
  }
  return []
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  linearProgress: {
    width: '100%',
  },
}))

export const EnhancedTableProduct = ({
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSkip,
  rowsPerPage,
  setRowsPerPage,
  loading,
  products,
  setProducts,
  productCount,
  skip,
  setProductCount,
  setLoading,
  isSearched,
  productsFound,
  allProducts,
  setAllProducts,
  variablesProducts,
  productsDownload,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [page, setPage] = useState(0)
  const [openModal, setOpenModal] = useState({ show: false, component: null })
  const [productSelected, setProductSelected] = useState([])
  const [pageProducts, setPageProducts] = useState([])
  const [editedProducts, setEditedProducts] = useState([])
  const { setAlert } = useContext(AlertContext)
  const [isLoading, setIsLoading] = useState(null)
  const { updateProduct } = usePostUpdateProduct()
  const { user } = useContext(UserContext)

  const handleEdit = (id, key, target) => {
    const hasProduct = editedProducts.some((product) => product.ID_product === id)
    const fullProduct = products.find((product) => product.ID_product === id)
      ? products.find((product) => product.ID_product === id)
      : productsFound.find((product) => product.ID_product === id)
    if (hasProduct) {
      const newEditedProducts = editedProducts.map((product) => {
        if (product.ID_product === id) {
          let { wholesale_unit_price, unit_cost, net_unit_cost, net_price_package, price_package } = product

          if (key === 'net_unit_cost') {
            unit_cost = +(+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19).toFixed(2)
          } else if (key === 'sale_format') {
            wholesale_unit_price = +(
              product.price_package / +target.textContent.replace(/[^\d,]/g, '').replace(',', '.')
            ).toFixed(2)
          } else if (key === 'net_price_package') {
            wholesale_unit_price = +(
              (+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19) /
              product.sale_format
            ).toFixed(2)
            price_package = +(+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19).toFixed(2)
          } else if (key === 'price_package') {
            wholesale_unit_price = +(
              +target.textContent.replace(/[^\d,]/g, '').replace(',', '.') / product.sale_format
            ).toFixed(2)
            net_price_package = +(+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') / 1.19).toFixed(2)
          }
          if (key !== 'sale_format') {
            target.textContent =
              '$' + new Intl.NumberFormat('de-DE').format(target.textContent.replace(/[^\d,]/g, '').replace(',', '.'))
          }
          return {
            ...product,
            wholesale_unit_price,
            unit_cost,
            net_unit_cost,
            net_price_package,
            price_package,
            [key]: +target.textContent.replace(/[^\d,]/g, '').replace(',', '.'),
          }
        } else {
          return product
        }
      })
      setEditedProducts(newEditedProducts)
    } else {
      let { wholesale_unit_price, unit_cost, net_unit_cost, net_price_package, price_package } = fullProduct

      if (key === 'net_unit_cost') {
        unit_cost = +target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19
      } else if (key === 'sale_format') {
        wholesale_unit_price = +(
          fullProduct.price_package / +target.textContent.replace(/[^\d,]/g, '').replace(',', '.')
        ).toFixed(2)
      } else if (key === 'net_price_package') {
        wholesale_unit_price = +(
          (+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19) /
          fullProduct.sale_format
        ).toFixed(2)
        price_package = +(+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') * 1.19).toFixed(2)
      } else if (key === 'price_package') {
        wholesale_unit_price = +(
          +target.textContent.replace(/[^\d,]/g, '').replace(',', '.') / fullProduct.sale_format
        ).toFixed(2)
        net_price_package = +(+target.textContent.replace(/[^\d,]/g, '').replace(',', '.') / 1.19).toFixed(2)
      }
      if (key !== 'sale_format') {
        target.textContent =
          '$' + new Intl.NumberFormat('de-DE').format(target.textContent.replace(/[^\d,]/g, '').replace(',', '.'))
      }
      setEditedProducts([
        ...editedProducts,
        {
          ...fullProduct,
          wholesale_unit_price,
          unit_cost,
          net_unit_cost,
          net_price_package,
          price_package,
          [key]: +target.textContent.replace(/[^\d,]/g, '').replace(',', '.'),
        },
      ])
    }
  }

  const handleCancel = (id) => {
    removeFromEdit(id)
    const productNoEdited = products.find((product) => product.ID_product === id)
    for (const key in productNoEdited) {
      const element = document.getElementById(`${id}-${key}`)
      if (element) {
        element.id !== `${id}-sale_format`
          ? (element.textContent = '$' + new Intl.NumberFormat('de-DE').format(productNoEdited[key]))
          : (element.textContent = productNoEdited[key])
      }
    }
  }

  const removeFromEdit = (id) => {
    const noCancelProducts = editedProducts.filter((product) => product.ID_product !== id)
    setEditedProducts(noCancelProducts)
  }

  const handleUpdate = async (id) => {
    setIsLoading(id)
    const updatedProduct = editedProducts.find((el) => el.ID_product === id)
    const {
      stock,
      producer,
      image,
      modified_at,
      modified_by,
      price_package,
      sale_format,
      wholesale_unit_price,
      unit_cost,
      suggested_sale_price,
      net_unit_cost,
      net_price_package,
      ...rest
    } = updatedProduct
    const update = {
      idProduct: id,
      productPayload: {
        modified_at: new Date().toISOString(),
        modified_by: user?.userApi?.ID_user,
        price_package,
        sale_format,
        wholesale_unit_price,
        unit_cost,
        suggested_sale_price,
        net_unit_cost,
        net_price_package,
      },
    }
    const { data, loading, error } = await updateProduct({
      variables: update,
      refetchQueries: [
        {
          query: QUERY.PRODUCTS.FULL,
          variables: variablesProducts,
          fetchPolicy: 'cache-and-network',
          context: { clientName: 'apiProducer' },
        },
      ],
      context: { clientName: 'apiProducer' },
    })
    const accessDenied = data?.updateProduct?.message === 'Acceso denegado'
    if (accessDenied) {
      setAlert({ open: true, message: data.updateProduct.message, severity: 'error' })
      setIsLoading([])
      handleCancel(id)
    } else if (data && !accessDenied) {
      removeFromEdit(id)
      setIsLoading(null)
      setAlert({ open: true, message: 'Datos actualizados', severity: 'success' })
    }
    if (error) {
      console.error(error)
      setAlert({ open: true, message: 'Hubo un error, no se pudo actualizar el producto', severity: 'error' })
      setIsLoading([])
      handleCancel(id)
    }
  }

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setSkip(0)
  }

  useEffect(() => {
    setPage(0)
    isSearched && setPageProducts(productsFound.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [productsFound])

  useEffect(() => {
    isSearched && setPageProducts(productsFound.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [page, rowsPerPage])

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
    !isSearched && setSkip(rowsPerPage * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const isSelected = (nameProduct) => selected.indexOf(nameProduct) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows(products).length - page + 1 * rowsPerPage)
  return (
    <div className={classes.root}>
      <TransitionsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        productSelected={productSelected}
        setSelected={setSelected}
        products={products}
        setProducts={setProducts}
        skip={skip}
        setProductCount={setProductCount}
        setLoading={setLoading}
        rowsPerPage={rowsPerPage}
        orderBy={orderBy}
        order={order}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          productsDownload={productsDownload}
          numSelected={selectedId.length}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <TableContainer>
          {loading ? <LinearProgress style={{ width: '100%' }} /> : ''}
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedId.length}
              order={order}
              productCount={productCount}
              orderBy={orderBy}
              products={products}
              onRequestSort={handleRequestSort}
              rowCount={products ? products.length : 0}
            />
            <TableBody id="tableBody">
              {!loading && products.length ? (
                <>
                  {stableSort(rows(isSearched ? pageProducts : products), getComparator(order, orderBy))
                    .slice(0, rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.nameProduct)
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          id={row.idProduct}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.idProduct}-${row.nameProduct}.${index}`}>
                          <TableCell component="th" id={labelId} scope="row" padding="normal">
                            {row.nameProduct}
                          </TableCell>
                          <TableCell align="center">{row.producer}</TableCell>
                          <TableCell
                            id={`${row.idProduct}-sale_format`}
                            align="center"
                            suppressContentEditableWarning={true}>
                            {row.saleFormat}
                          </TableCell>
                          <TableCell
                            id={`${row.idProduct}-net_unit_cost`}
                            align="center"
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleEdit(row.idProduct, 'net_unit_cost', e.target)}>
                            {' '}
                            {'$' + new Intl.NumberFormat('de-DE').format(row.unitCost)}
                          </TableCell>
                          <TableCell
                            id={`${row.idProduct}-net_price_package`}
                            align="center"
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleEdit(row.idProduct, 'net_price_package', e.target)}>
                            {'$' +
                              ((editedProducts.find((product) => product.ID_product === row.idProduct)
                                ?.net_price_package &&
                                new Intl.NumberFormat('de-DE').format(
                                  editedProducts.find((product) => product.ID_product === row.idProduct)
                                    ?.net_price_package
                                )) ||
                                new Intl.NumberFormat('de-DE').format(row.net_price))}
                          </TableCell>
                          <TableCell
                            id={`${row.idProduct}-price_package`}
                            align="center"
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleEdit(row.idProduct, 'price_package', e.target)}>
                            {'$' +
                              ((editedProducts.find((product) => product.ID_product === row.idProduct)?.price_package &&
                                new Intl.NumberFormat('de-DE').format(
                                  editedProducts.find((product) => product.ID_product === row.idProduct).price_package
                                )) ||
                                new Intl.NumberFormat('de-DE').format(row.price))}
                          </TableCell>
                          <TableCell
                            id={`${row.idProduct}-wholesale_unit_price`}
                            align="center"
                            suppressContentEditableWarning={true}>
                            {'$' +
                              ((editedProducts.find((product) => product.ID_product === row.idProduct)
                                ?.wholesale_unit_price &&
                                new Intl.NumberFormat('de-DE').format(
                                  editedProducts.find((product) => product.ID_product === row.idProduct)
                                    ?.wholesale_unit_price
                                )) ||
                                new Intl.NumberFormat('de-DE').format(row.unitPrice))}
                          </TableCell>
                          <TableCell
                            id={`${row.idProduct}-suggested_sale_price`}
                            align="center"
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleEdit(row.idProduct, 'suggested_sale_price', e.target)}>
                            {row.priceOffer === null || row.priceOffer === 0
                              ? '-'
                              : '$' + new Intl.NumberFormat('de-DE').format(row.priceOffer)}
                          </TableCell>
                          <TableCell align="center">{row.statusProduct}</TableCell>
                          <TableCell align="center" padding="none">
                            {isLoading !== row.idProduct ? (
                              <ButtonGroup disableElevation variant="outlined" radioGroup="">
                                <Tooltip title="Cancelar">
                                  <div>
                                    <IconButton
                                      aria-label="Cancelar"
                                      disabled={
                                        !editedProducts.some((el) => el.ID_product === row.idProduct) || isLoading
                                      }
                                      color="primary"
                                      onClick={
                                        editedProducts.some((el) => el.ID_product === row.idProduct)
                                          ? () => handleCancel(row.idProduct)
                                          : null
                                      }>
                                      <Close />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                                <Tooltip title="Editar">
                                  <div>
                                    <IconButton
                                      aria-label="Editar"
                                      disabled={
                                        !editedProducts.some((el) => el.ID_product === row.idProduct) || isLoading
                                      }
                                      onClick={
                                        editedProducts.some((el) => el.ID_product === row.idProduct)
                                          ? () => handleUpdate(row.idProduct)
                                          : null
                                      }
                                      color="secondary">
                                      <Done />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                              </ButtonGroup>
                            ) : (
                              <div className="loader-spin"></div>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <h3>{loading ? 'Cargando...' : 'Aún no se han ingresado productos'}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={isSearched ? productsFound.length : productCount ? productCount : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<label>Productos por pagina</label>}
        />
      </Paper>
    </div>
  )
}
