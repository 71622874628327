import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { ChevronRight, Info, KeyboardArrowDown, Restore } from '@material-ui/icons'
import { slugGenerator } from '../../utils/slugGenerator'
import Loader from '../../components/Loader'
import { Alert } from '@material-ui/lab'
import { InputUploadFile } from '../../components/InputUploadFile'
import { useEditProducers } from '../../hooks/useEditProducer'
import { CommentFloatButton } from '../../components/producerEditor/CommentFloatButton'
import { InputUploadImage } from '../../components/producerEditor/inputUploadImage'
import { Section } from '../../components/producerEditor/Section'
import { ProductInfo } from '../../components/producerEditor/ProductsInfo'
import { RadioOptions } from '../../components/producerEditor/Radio'
import { InputGeo } from '../../components/InputGeo'
import { useEffect, useState } from 'react'

const ActivitiesResolutionOptions = [
  { label: 'Sí', value: 'Si' },
  { label: 'No', value: 'No' },
  { label: 'En Proceso', value: 'En Proceso' },
]
const HasCertificationsOptions = [
  { label: 'Sí', value: 'true' },
  { label: 'No', value: 'false' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  container: {
    padding: '1.5rem',
  },
  ContainerAddOrder: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '2rem',
    gap: '1rem',
    padding: theme.spacing(0, 15),
  },
  button: {
    color: 'white',
  },
  iconButton: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  info: {
    background: '#FFFDE6',
    color: theme.palette.text.primary,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
  },
  menuSelected: {
    background: '#e2e2e2!important',
  },
}))

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 100 * 4.5 + 10,
      width: 250,
    },
  },
}

export const EditProducer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const {
    producer,
    products,
    deliveryZones,
    producerForm,
    certificationFiles,
    resolutionFiles,
    uploadCertification,
    uploadResolution,
    handleSubmit,
    onChangeInputFile,
    handleCategoryTypeChange,
    handleDeleteCertification,
    handleDeleteResolution,
    inputsFile,
    comment,
    history,
    handleChangeComment,
    handleCopyAdditionalInfo,
    handleChangeHistory,
    loading,
    categories,
    lifeStyles,
    selectedLifeStyles,
    selectedCategoryType,
    selectedCategories,
    handleSelectedCategories,
    productsInfo,
    handleAddressNumber,
    handleChangeAddress,
    address,
    restoreDefaultAddress,
    certificate_categories,
    handleImageFile,
  } = useEditProducers()
  const [formalization, setFormalization] = useState({
    health_resolution: false,
    hasCertification: false,
  })
  const [isActive, setIsActive] = useState(false)
  const handleFormalizationChange = (e) => {
    setFormalization((prev) => ({
      ...prev,
      [e.target.name.replace('formalization_', '')]:
        e.target.name === 'formalization_hasCertification' ? e.target.value === 'true' : e.target.value,
    }))
  }
  useEffect(() => {
    if (!loading) {
      setFormalization({
        health_resolution: producer?.formalization?.health_resolution,
        hasCertification: productsInfo?.hasCertification,
      })
      setIsActive(producer.isActive)
    }
  }, [loading])

  return (
    <>
      {loading && !producer ? (
        <Loader />
      ) : (
        <Paper className={classes.container}>
          <form ref={producerForm} onSubmit={handleSubmit} id="producerForm">
            <Grid container spacing={8} direction="column">
              {/* HEADER */}
              <Grid item style={{ paddingBottom: 0 }}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h1" className={classes.h1}>
                      Editar productor
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<Delete />}>
                      Borrar productor
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
              {/* INFO PRODUCER */}
              <Section title={'Información del productor'}>
                <Grid
                  container
                  direction={matches ? 'column-reverse' : 'row'}
                  justifyContent="space-between"
                  spacing={6}>
                  <Grid item md={8}>
                    <Grid container spacing={3} direction="column">
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isActive"
                                  color="secondary"
                                  value={true}
                                  onClick={(e) => setIsActive(e.target.checked)}
                                  defaultChecked={producer.isActive}
                                />
                              }
                              label="Activo en catálogo"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isPublished"
                                  color="secondary"
                                  value={true}
                                  defaultChecked={producer.isPublished}
                                />
                              }
                              label="Activo en directorio"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item style={{ display: 'none' }}>
                            <TextField defaultValue={producer?.ID_producer} name="ID_producer" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              defaultValue={producer?.brand_name}
                              label="Nombre del productor"
                              name="brand_name"
                              helperText={`Vista previa de su direccion de catálogo: https://alimentos.comes.cl/productor/${slugGenerator(
                                producer?.brand_name
                              )}`}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              defaultValue={producer.business_name}
                              label="Razón social"
                              name="business_name"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField defaultValue={producer.rut} label="RUT" name="rut" fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          {producer.direction[0]?.ID_direction && (
                            <Grid item xs style={{ display: 'none' }}>
                              <TextField
                                defaultValue={producer.direction[0]?.ID_direction || ''}
                                name="ID_direction"
                                fullWidth
                              />
                            </Grid>
                          )}

                          <Grid item xs>
                            <InputGeo
                              setAddress={handleChangeAddress}
                              setHasNumber={handleAddressNumber}
                              defaultValue={address}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton size="medium" className={classes.iconButton} onClick={restoreDefaultAddress}>
                              <Restore />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <TextField
                              defaultValue={producer.delivery_time}
                              type="number"
                              label="Tiempo estimado de entrega (en días)"
                              name="delivery_time"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              defaultValue={producer.min_purchase}
                              label="Pedido mínimo"
                              name="min_purchase"
                              required={isActive}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel id="type_sale_label">Tipo de pedido mínimo</InputLabel>
                              <Select
                                labelId="type_sale_label"
                                id="type_sale"
                                name="type_sale_ID_type_sale"
                                required={isActive}
                                defaultValue={producer.type_sale?.ID_type_sale}
                                fullWidth>
                                <MenuItem value={1}>Unidades</MenuItem>
                                <MenuItem value={2}>Kilos</MenuItem>
                                <MenuItem value={3}>Monto</MenuItem>
                                <MenuItem value={4}>Cajas</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>
                            <Alert
                              className={classes.info}
                              iconMapping={{ warning: <Info color="primary" fontSize="inherit" /> }}
                              variant="filled"
                              severity="warning">
                              Recuerda que para activar un productor en el catálogo, el pedido mínimo debe estar
                              ingresado
                            </Alert>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4}>
                    <InputUploadImage fileList={producer.files[0]} handleImageFile={handleImageFile} />
                  </Grid>
                </Grid>
              </Section>
              {/* CONTACT INFO */}
              <Section title="Información de contacto">
                <Grid container spacing={3}>
                  <Grid item style={{ display: 'none' }}>
                    <TextField
                      defaultValue={producer.contact[0].ID_contact}
                      name="ID_contact"
                      label={'Id de contacto'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      defaultValue={producer.contact[0].name}
                      name="contact_name"
                      label={'Nombre de contacto'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      defaultValue={producer.contact[0].email}
                      name="contact_email"
                      label={'Correo'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      defaultValue={producer.contact[0].phone}
                      name="contact_phone"
                      label={'Teléfono'}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Section>
              {/* CATEGORY PRODUCER */}
              <Section title="Categorías del productor">
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="secondary" />}
                          label="Alimentos frescos"
                          value={1}
                          checked={selectedCategoryType.includes('1')}
                          onChange={handleCategoryTypeChange}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="secondary" />}
                          label="Alimentos elaborados"
                          value={2}
                          checked={selectedCategoryType.includes('2')}
                          onChange={handleCategoryTypeChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {Boolean(selectedCategoryType.length) && (
                    <Grid item>
                      <Grid container>
                        <Grid item xs>
                          <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="multiple-categories-label">Selecciona las categorías</InputLabel>
                            <Select
                              labelId="multiple-categories-label"
                              id="mutiple-categories"
                              multiple
                              value={selectedCategories}
                              input={<Input id="select-multiple-categories" />}
                              name="categories"
                              onChange={handleSelectedCategories}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => {
                                    return (
                                      <Chip key={value.ID_category} label={value.category} className={classes.chip} />
                                    )
                                  })}
                                </div>
                              )}
                              MenuProps={MenuProps}>
                              {categories.map((item, index) => (
                                <MenuItem
                                  key={item.ID_category}
                                  value={item}
                                  classes={{ selected: classes.menuSelected }}>
                                  {item.category}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container>
                      <Grid item xs>
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel id="demo-mutiple-chip-label">
                            Selecciona las categorías de estilo de vida
                          </InputLabel>
                          <Select
                            labelId="demo-mutiple-chip-label"
                            id="lifeStyles"
                            name="lifeStyles"
                            multiple
                            value={selectedLifeStyles}
                            input={<Input id="select-multiple-chip" />}
                            onChange={handleSelectedCategories}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => {
                                  return (
                                    <Chip key={value.ID_category} label={value.category} className={classes.chip} />
                                  )
                                })}
                              </div>
                            )}
                            MenuProps={MenuProps}>
                            {lifeStyles.map((item) => {
                              return (
                                <MenuItem key={item.ID_category} value={item}>
                                  {item.category}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              {/* ADITIONAL INFO */}
              <Section title="Información adicional">
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <TextField defaultValue={producer.web} name="web" label={'URL de la web del productor'} fullWidth />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md>
                        <TextField
                          multiline
                          minRows={5}
                          maxRows={10}
                          defaultValue={producer.additional_info}
                          name="additional_info"
                          label={'Más información'}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="medium"
                          className={classes.iconButton}
                          onClick={() => handleCopyAdditionalInfo()}>
                          <Tooltip
                            children={matches ? <KeyboardArrowDown /> : <ChevronRight />}
                            title={'Copiar a historia'}></Tooltip>
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md>
                        <TextField
                          multiline
                          InputLabelProps={{ shrink: Boolean(history) && true }}
                          minRows={5}
                          maxRows={10}
                          value={history}
                          name="history"
                          label={'Historia'}
                          fullWidth
                          onChange={handleChangeHistory}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              {/* INFO PRODUCTS */}
              <Section title="Información del los productos">
                <Grid container direction="column" spacing={3}>
                  <Grid item xs>
                    <Alert
                      className={classes.info}
                      iconMapping={{ warning: <Info color="primary" fontSize="inherit" /> }}
                      variant="filled"
                      severity="warning">
                      Esta información es sólo de lectura
                    </Alert>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="space-between" spacing={3}>
                      <Grid item>
                        <ProductInfo
                          title="Productos activos/ingresados"
                          value={`${productsInfo.assets}/${productsInfo.total}`}
                          hasInfo
                        />
                      </Grid>
                      <Grid item>
                        <ProductInfo title="Código de barra" value={productsInfo.hasBarcode} hasInfo />
                      </Grid>
                      <Grid item>
                        <ProductInfo title="Ficha técnica" value={productsInfo.hasTechnicalSheet} hasInfo />
                      </Grid>
                      <Grid item>
                        <ProductInfo
                          title="Certificado de productos"
                          value={productsInfo?.hasProductCertification}
                          hasInfo
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              {/* FORMALIZATION */}
              <Section title="Formalización">
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Iniciación de actividades"
                          name="formalization_start_activities"
                          defaultValue={producer.formalization.start_activities}
                          options={ActivitiesResolutionOptions}
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Resolución sanitaria"
                          name="formalization_health_resolution"
                          defaultValue={producer.formalization.health_resolution}
                          options={ActivitiesResolutionOptions}
                          onChange={handleFormalizationChange}
                        />
                      </Grid>
                      <Grid item style={{ display: 'none' }}>
                        <TextField value={productsInfo?.ID_products_info} name="ID_products_info" />
                      </Grid>

                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Cuenta con certificados"
                          name="hasCertification"
                          defaultValue={productsInfo?.hasCertification?.toString()}
                          options={HasCertificationsOptions}
                          onChange={handleFormalizationChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ display: 'none' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Información nutricional"
                          name="hasNutritional_info"
                          defaultValue={productsInfo?.hasNutritional_info?.toString()}
                          options={HasCertificationsOptions}
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Ingredientes"
                          name="hasIngredient_sheet"
                          defaultValue={productsInfo?.hasIngredient_sheet?.toString()}
                          options={HasCertificationsOptions}
                        />
                      </Grid>

                      <Grid item xs={12} md>
                        <RadioOptions
                          label="Fecha de vencimiento"
                          name="hasExpiration_date"
                          defaultValue={productsInfo?.hasExpiration_date?.toString()}
                          options={HasCertificationsOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>

              {formalization.health_resolution !== 'No' && (
                <>
                  {/* UPLOAD HEALTH RESOLUTION */}
                  <Section title="Resolución sanitaria">
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={inputsFile.resolution['Número']}
                              name="Número"
                              label={'Número *'}
                              fullWidth
                              inputProps={{ 'data-group': 'resolution' }}
                              onChange={onChangeInputFile}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={new Date(inputsFile.resolution['Fecha de resolución'])
                                .toISOString()
                                .replace(/T.{1,}/, '')}
                              type="date"
                              name="Fecha de resolución"
                              label={'Fecha de resolución *'}
                              fullWidth
                              inputProps={{ 'data-group': 'resolution' }}
                              onChange={onChangeInputFile}
                              onClick={(e) => e.target.showPicker()}
                              onFocus={(e) => e.target.showPicker()}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={inputsFile.resolution['Código de verificación']}
                              name="Código de verificación"
                              label={'Código de verificación'}
                              fullWidth
                              inputProps={{ 'data-group': 'resolution' }}
                              onChange={onChangeInputFile}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <InputUploadFile
                          fileList={resolutionFiles}
                          handleUpload={uploadResolution}
                          handleDelete={handleDeleteResolution}
                          buttonLabel="Subir resolución sanitaria"
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </>
              )}
              {!['false', false].includes(formalization.hasCertification) && (
                <>
                  {/* UPLOAD CERTIFICATION */}
                  <Section title="Certificados">
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel id="certification_type_label">Tipo</InputLabel>
                              <Select
                                labelId="certification_type_label"
                                id="ID_type_certification"
                                name="ID_type_certification"
                                onChange={(e) => onChangeInputFile(e, 'certification')}
                                defaultValue={'disabled'}
                                fullWidth>
                                <MenuItem disabled value={'disabled'}>
                                  Elige un certificado
                                </MenuItem>
                                {certificate_categories.map((type) => (
                                  <MenuItem value={type.ID_type_certification} key={type.ID_type_certification}>
                                    {type.type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={
                                certificate_categories.find(
                                  (category) =>
                                    category.ID_type_certification === inputsFile.certification.ID_type_certification
                                )?.description || ''
                              }
                              name="Descripción"
                              label={'Descripción'}
                              fullWidth
                              InputLabelProps={{
                                shrink: certificate_categories.some(
                                  (cat) => cat.ID_type_certification === inputsFile.certification.ID_type_certification
                                ),
                              }}
                              inputProps={{ disabled: true, 'data-group': 'certification' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={new Date(inputsFile.certification['Válido hasta'])
                                .toISOString()
                                .replace(/T.{1,}/, '')}
                              name="Válido hasta"
                              type="date"
                              label={'Válido hasta'}
                              fullWidth
                              inputProps={{ 'data-group': 'certification' }}
                              onChange={onChangeInputFile}
                              onClick={(e) => e.target.showPicker()}
                              onFocus={(e) => e.target.showPicker()}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <InputUploadFile
                          fileList={certificationFiles}
                          handleUpload={uploadCertification}
                          handleDelete={handleDeleteCertification}
                          buttonLabel="Subir Certificado"
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </>
              )}

              {/* Bank information */}
              <Section title="Información bancaria (deshabilitado🛠️)">
                <Grid container spacing={3} direction="column">
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          defaultValue={producer?.bank_account?.holder}
                          name="bank_account_holder"
                          label={'Nombre de contacto'}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="bank_account_rut"
                          label={'RUT'}
                          fullWidth
                          disabled
                          defaultValue={producer?.bank_account?.rut}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ display: 'none' }}>
                        <TextField
                          label={'RUT'}
                          fullWidth
                          name="ID_bank_account"
                          defaultValue={producer?.bank_account?.ID_bank_account}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <FormControl className={classes.formControl} fullWidth disabled>
                          <InputLabel id="bank_label">Banco</InputLabel>
                          <Select labelId="bank_label" id="bank" name="bank_account_bank" defaultValue={''} fullWidth>
                            <MenuItem value={''} disabled>
                              Elige un banco
                            </MenuItem>
                            <MenuItem value={5}>Un banco</MenuItem>
                            <MenuItem value={2}>Otro Banco</MenuItem>
                            <MenuItem value={3}>Nuevo Banco</MenuItem>
                            <MenuItem value={4}>Banco Lindo</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className={classes.formControl} fullWidth disabled>
                          <InputLabel id="bank_account_type_label">Tipo de cuenta</InputLabel>
                          <Select
                            labelId="bank_account_label"
                            id="bank_account_type"
                            name="bank_account_type"
                            defaultValue={''}
                            fullWidth>
                            <MenuItem value={''} disabled>
                              Elige un tipo de cuenta
                            </MenuItem>
                            <MenuItem value={4}>Cta vista</MenuItem>
                            <MenuItem value={2}>Cta corriente</MenuItem>
                            <MenuItem value={3}>Cta de ahorro</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          type="number"
                          name="bank_account_number"
                          label={'Número de cuenta'}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              {/* Commentary */}
              <Section title="Comentario">
                <Grid container>
                  <Grid item xs>
                    <TextField
                      value={comment}
                      name="comment"
                      label={'Comentario'}
                      multiline
                      fullWidth
                      onChange={handleChangeComment}
                    />
                  </Grid>
                </Grid>
              </Section>
              {/* Form Buttons */}
              <Grid item xs>
                <Grid container spacing={4} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => window.location.reload(false)}>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="secondary" size="large" className={classes.button}>
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <CommentFloatButton onChange={handleChangeComment} state={comment} />
        </Paper>
      )}
    </>
  )
}
