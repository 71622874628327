import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import ModalAddAddres from './ModalAddAddress'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/styles'
import ModalAddProductOrder from './ModalAddProductOrder'
import ModalDeleteProduct from './ModalDeleteProduct'
import ModalDeleteProductOrder from './ModalDeleteProductOrder'
import ModalConfirmacion from './ModalConfirmacion'
import ModalOrderDetail from './ModalOrderDetail'
import ModalDiscount from './ModalDiscount'
import ModalSummary from './ModalSummary'
import ModalAddCategory from './ModalAddCategory'
import ModalShipping from './ModalShipping'
import ModalSendQuote from './ModalSendQuote'
import ModalInfoShipping from './ModalInfoShipping'
import ModalInvoiceEntry from './ModalInvoiceEntry'
import ModalDownloadProducts from './ModalDownloadProducts'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '90vh',
    overflowY: 'scroll',
    padding: '1rem',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '.5rem',
      backgroundColor: 'transparent',
      borderRadius: '1rem',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '0.5rem',
      backgroundColor: '#8ac541',

      borderRadius: '1rem',
    },
    '&::-webkit-scrollbar-button': {
      width: '.5rem',
      backgroundColor: 'transparent',
      borderRadius: '1rem',
      height: '1rem',
    },
  },
}))
const StyledCloseIcon = withStyles({
  root: {
    margin: 10,
    cursor: 'pointer',
    float: 'right',
  },
})(CloseIcon)
export default function TransitionsModal({
  openModal,
  setOpenModal,
  products,
  productsFound,
  setProducts,
  setProductsFound,
  productSelected,
  setProductSelected,
  rowSelected,
  selected,
  setSelected,
  allProducts,
  orderSelected,
  skip,
  setProductCount,
  setLoading,
  rowsPerPage,
  orderBy,
  order,
  setSelectedId,
  selectedId,
  paramsSearched,
  setParamsSearched,
  setOrders,
  orders,
  message,
  producers,
  setProducers,
  quoter,
  setQuoter,
  setSentInfo,
  getData,
}) {
  const classes = useStyles()

  const handleClose = () => {
    setOpenModal({ show: !openModal.show, component: null })
  }
  // SEGUN EL TEXTO QUE CONTENGA COMPONENT DEVOLVERÁ EL COMPONENTE QUE CORRESPONDE DENTRO DEL MODAL
  const contentModal = () => {
    switch (openModal.component) {
      case 'ModalAddAddress':
        return <ModalAddAddres setDirections={openModal.setDirections} directions={openModal.directions} />
      case 'ModalAddProductOrder':
        return (
          <ModalAddProductOrder
            productsFound={productsFound}
            setProductsFound={setProductsFound}
            handleClose={handleClose}
            setProductSelected={setProductSelected}
            products={products}
            setProducts={setProducts}
            productSelected={productSelected}
            selected={selected}
            setSelected={setSelected}
            allProducts={allProducts}
            paramsSearched={paramsSearched}
            setParamsSearched={setParamsSearched}
          />
        )
      case 'ModalDeleteProduct':
        return (
          <ModalDeleteProduct
            selected={selected}
            productSelected={productSelected}
            handleClose={handleClose}
            setSelected={setSelected}
            products={products}
            setProducts={setProducts}
            skip={skip}
            setProductCount={setProductCount}
            setLoading={setLoading}
            rowsPerPage={rowsPerPage}
            orderBy={orderBy}
            order={order}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
          />
        )
      case 'ModalDeleteProductOrder':
        return (
          <ModalDeleteProductOrder
            rowSelected={rowSelected}
            productSelected={productSelected}
            setProductSelected={setProductSelected}
            handleClose={handleClose}
            selected={selected}
            setSelected={setSelected}
          />
        )
      case 'ModalConfirmacion':
        return <ModalConfirmacion handleClose={handleClose} location={openModal.location} confirm={openModal.confirm} />
      case 'ModalOrderdetail':
        return (
          <ModalOrderDetail
            openModal={openModal}
            orderSelected={orderSelected}
            toPDF={openModal.toPDF}
            orders={orders}
            setOrders={setOrders}
          />
        )
      case 'ModalInvoiceEntry':
        return <ModalInvoiceEntry orderSelected={orderSelected} handleClose={handleClose} data={openModal.data} />
      case 'ModalDiscount':
        return <ModalDiscount handleClose={handleClose} />
      case 'ModalSummary':
        return <ModalSummary handleClose={handleClose} />
      case 'ModalAddCategory':
        return <ModalAddCategory handleClose={handleClose} confirm={openModal.confirm} category={openModal.category} />
      case 'ModalShipping':
        return <ModalShipping handleClose={handleClose} />
      case 'ModalSendQuote':
        return (
          <ModalSendQuote
            handleClose={handleClose}
            message={message}
            producers={producers}
            quoter={quoter}
            setProducers={setProducers}
            setSentInfo={setSentInfo}
            setQuoter={setQuoter}
          />
        )

      case 'ModalDownloadProducts':
        return <ModalDownloadProducts selected={selected} handleClose={handleClose} selectedId={selectedId} />

      case 'ModalInfoShipping':
        return <ModalInfoShipping handleClose={handleClose} orderSelected={orderSelected} getData={getData} />
      default:
        return <div></div>
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal.show}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModal.show}>
          {/* ----CONTENIDO DE MODAL ----- */}
          <div className={classes.paper}>
            <StyledCloseIcon onClick={handleClose} style={{ position: 'absolute', right: '5px' }} />
            {contentModal()}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
