import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function usePostCreateorderNote() {
  const [createOrderNote, { data, loading, error }] = useMutation(QUERY.ORDER_NOTES.CREATE, {
    context: { clientName: 'apiStore' },
  })

  return { createOrderNote, data, loading, error }
}
