import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import TransitionsModal from '../modal/ModalContainer'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { EnhancedTableHead } from './EnhancedTableHead'
import { Edit, LocalShipping, Note, Subject } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { formatRut } from '../../utils/formatRut'
import priceFormat from '../../utils/priceFormat'

function createData(idProducer, nameProducer, nameBusiness, rut, type_sale, min_purchase, isActive) {
  return { idProducer, nameProducer, nameBusiness, rut, type_sale, min_purchase, isActive }
}

const rows = (producers) => {
  if (producers) {
    const dataList = producers?.map((producer) =>
      createData(
        producer.ID_producer,
        producer.brand_name,
        producer.business_name,
        producer.rut,
        producer?.type_sale?.type,
        producer.min_purchase,
        producer.isActive
      )
    )
    return dataList
  }
  return []
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  linearProgress: {
    width: '100%',
  },
}))

export const EnhancedTableProducers = ({
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setSkip,
  rowsPerPage,
  setRowsPerPage,
  loading,
  producers,
  producerCount,
  skip,
  isSearched,
  producersDownload,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [page, setPage] = useState(0)
  const [openModal, setOpenModal] = useState({ show: false, component: null })
  const [producerSelected, setProducerSelected] = useState([])
  const [pageProducers, setPageProducers] = useState([])

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setSkip(0)
  }

  useEffect(() => {
    setPage(0)
    isSearched && setPageProducers(producers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [producers])

  useEffect(() => {
    isSearched && setPageProducers(producers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [page, rowsPerPage])

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
    !isSearched && setSkip(rowsPerPage * newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const isSelected = (nameProducer) => selected.indexOf(nameProducer) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows(producers).length - page + 1 * rowsPerPage)

  const isProducersEmpty = (!isSearched && producers?.length !== 0) || (isSearched && pageProducers.length !== 0)
  const formatMinPurshase = (format, qty) => {
    if (format === 'cajas') {
      return qty === 1 ? qty + ' caja' : qty + ' ' + format
    } else if (format === 'monto') {
      return priceFormat(qty)
    } else if (format === 'unidades') {
      return qty + ' ' + format
    }
  }
  return (
    <div className={classes.root}>
      <TransitionsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        producerSelected={producerSelected}
        setSelected={setSelected}
        producers={producers}
        //setProducers={setProducers}
        skip={skip}
        //setProducerCount={setProducerCount}
        //setLoading={setLoading}
        rowsPerPage={rowsPerPage}
        orderBy={orderBy}
        order={order}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar producersDownload={producersDownload} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              producerCount={producerCount}
              orderBy={orderBy}
              producers={producers}
              onRequestSort={handleRequestSort}
            />
            <TableBody id="tableBody">
              {isProducersEmpty ? (
                <>
                  {stableSort(rows(isSearched ? pageProducers : producers), getComparator(order, orderBy))
                    .slice(0, rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.nameProducer)
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.idProducer}-${row.nameProducer}.${index}`}>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.nameProducer}
                          </TableCell>
                          <TableCell align="center">{row.nameBusiness}</TableCell>
                          <TableCell align="center">{formatRut(row.rut)}</TableCell>
                          <TableCell align="right">
                            {row.type_sale && row.min_purchase
                              ? formatMinPurshase(row.type_sale, row.min_purchase)
                              : ''}
                          </TableCell>
                          <TableCell align="left">{row.isActive ? 'Activo' : 'Inactivo'}</TableCell>
                          <TableCell align="center" padding="none">
                            <ButtonGroup>
                              <Tooltip title="Editar">
                                <Link to={`/editar-productor/${row.idProducer}`}>
                                  <IconButton aria-label="Editar">
                                    <Edit />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <Tooltip title="Ir a pedidos">
                                <Link to={`/pedidos-productor/${row.nameProducer}/${row.idProducer}`}>
                                  <IconButton aria-label="Ir a pedidos">
                                    <Subject />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <Tooltip title="Editar zonas de despacho">
                                <Link to={`/zona-de-despacho/${row.idProducer}/${row.nameProducer}`}>
                                  <IconButton aria-label="Zonas de despacho">
                                    <LocalShipping />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <a id={`sheet-${row.idProducer}-${row.nameProducer}`} className="anchor-btn"></a>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <h3>{loading ? 'Cargando...' : 'Aún no se han ingresado productores'}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={isSearched ? producers.length : producerCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<label>Productores por pagina</label>}
        />
      </Paper>
    </div>
  )
}
