import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { IconButton, lighten, makeStyles, Toolbar, Typography } from '@material-ui/core'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
}))

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected, producerSelected } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          <b>
            {numSelected} {numSelected > 1 ? <span>Pedidos seleccionados</span> : <span>Pedidos seleccionado</span>}
          </b>
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Pedidos {producerSelected.brand_name ? 'de ' + producerSelected.brand_name : ''}
        </Typography>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}
