import { useMutation } from '@apollo/client'
import { QUERY } from '../services/queries'

export function usePostAddZonesDelivery() {
  const [addZonesDelivery, { data, loading, error }] = useMutation(QUERY.ZONES_DELIVERY.CREATE, {
    context: { clientName: 'apiProducer' },
  })

  return { addZonesDelivery, data, loading, error }
}
export function usePostRemoveZoneDelivery() {
  const [removeZoneDelivery, { data, loading, error }] = useMutation(QUERY.ZONES_DELIVERY.REMOVE, {
    context: { clientName: 'apiProducer' },
  })

  return { removeZoneDelivery, data, loading, error }
}
