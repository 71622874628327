import { createContext, useState } from 'react'

const Context = createContext([])

export function ErrorContext({ children }) {
  const [errors, setErrors] = useState([])
  return <Context.Provider value={{ errors, setErrors }}>{children}</Context.Provider>
}

export default Context
