import {
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Select,
  MenuItem,
  Grid,
  IconButton,
  ButtonBase,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import MUIEditor from 'react-mui-draft-wysiwyg'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import configRte from '../utils/configRte'
import ErrorContext from '../context/ErrorContext'
import { slugGenerator } from '../utils/slugGenerator'
import AlertContext from '../context/AlertContext'
import { Undo } from '@material-ui/icons'
import { useRef } from 'react'

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
}))

export default function InputMUI({
  type = 'text',
  label,
  id,
  idError,
  errorText = '',
  list,
  helperText,
  init = '',
  setState,
  productData,
  setIngredients,
  setNutritionalTable,
  nameProducer,
  setNameProducer,
  imageRemoved,
  setImageremoved,
  innerRef,
  ...rest
}) {
  const [value, setvalue] = useState(init)
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const stateInitial = useRef(value)
  const { errors, setErrors } = useContext(ErrorContext)
  const { setAlert } = useContext(AlertContext)

  function FindIdError() {
    if (errors) {
      return errors.find((err) => err.param == idError)
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const validateImage = async (event) => {
    setIsLoading(true)
    const base64Image = await convertBase64(event.file_image)
    try {
      const response = await fetch(`https://haae75tywc.execute-api.us-east-1.amazonaws.com/dev/uploadImage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ file: base64Image }),
      })
      const responseJson = await response.json()
      if (response.status == 403) {
        setAlert({ open: true, message: responseJson.errorMessage, severity: 'error' })
        // setErrors({ ...error, image: responseJson.errorMessage })
      } else {
        // setErrors({ ...error, image: '' })
        setvalue(event)
      }
    } catch (error) {
      setAlert({ open: true, message: 'La imagen no se puede procesar', severity: 'error' })
      console.error('La imagen no se puede procesar', error)
    } finally {
      setIsLoading(false)
    }
  }

  function removeImage() {
    stateInitial.current &&
      !imageRemoved &&
      setImageremoved({ ...value }) &&
      setvalue({
        alt: '',
        name_image: '',
        file_image: '',
        isMain: true,
      })
    setvalue({
      alt: '',
      name_image: '',
      file_image: '',
      isMain: true,
    })
  }

  function restoreImageRemoved() {
    setvalue(stateInitial.current)
    setImageremoved(null)
  }

  useEffect(() => {
    if (value !== init && id !== 'product_margin') {
      id === 'name'
        ? setState({ slug: slugGenerator(`${value ? value : ''}`), name: value })
        : setState({ [id]: value })

      setErrors(errors.filter((e) => e.param !== id))
    }
  }, [value, nameProducer])

  useEffect(() => {
    if (id == 'isActive') {
      setvalue(init)
    } else if (init == '') {
      setvalue('')
    } else {
      setvalue(init)
    }
  }, [init])

  const handle_imageTable = async (file) => {
    setIsLoading(true)
    const base64Image = await convertBase64(file)
    try {
      const response = await fetch(`https://haae75tywc.execute-api.us-east-1.amazonaws.com/dev/getTable`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ file: base64Image }),
      })
      const responseJson = await response.json()
      if (response.status == 403) {
        setAlert({ open: true, message: responseJson.errorMessage, severity: 'error' })
        // setErrors({ ...error, image: responseJson.errorMessage })
      } else if (responseJson.length) {
        // setErrors({ ...error, image: '' })
        setNutritionalTable({ nutritional_info: responseJson })
      } else {
        setAlert({ open: true, message: 'Probablemente la imagen no contiene una tabla', severity: 'error' })
      }
    } catch (error) {
      setAlert({ open: true, message: 'La imagen no se puede procesar', severity: 'error' })
      console.error('La imagen no se puede procesar', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handle_imageIngredients = async (file) => {
    setIsLoading(true)
    const base64Image = await convertBase64(file)
    try {
      const response = await fetch(`https://haae75tywc.execute-api.us-east-1.amazonaws.com/dev/getRawText`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ file: base64Image }),
      })
      const responseJson = await response.json()
      if (response.status == 403) {
        setAlert({ open: true, message: responseJson.errorMessage, severity: 'error' })
      } else {
        setIngredients({ ingredients: responseJson })
      }
    } catch (error) {
      setAlert({ open: true, message: 'La imagen no se puede procesar', severity: 'error' })
      console.error('La imagen no se puede procesar', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      id == 'product_image' &&
      !(Array.isArray(productData?.product_image) ? productData.product_image.length : productData.product_image) &&
      !productData?.ID_producer?.length
    ) {
      setvalue({
        alt: '',
        name_image: '',
        file_image: '',
        isMain: true,
      })
      stateInitial.current = ''
    }

    if (productData?.name && productData?.product_image?.file_image) {
      const nameFormat = productData?.name ? slugGenerator(productData.name) : ''
      const split = productData?.product_image?.file_image?.name
        ? productData?.product_image?.file_image?.name?.split('.')
        : productData?.product_image?.file_image?.split('.')
      const goBackStateInitial = stateInitial.current === value ? true : false
      if (!goBackStateInitial && productData?.product_image?.file_image?.name !== nameFormat + '.' + split[1]) {
        const myNewFile = new File([productData?.product_image?.file_image], nameFormat + '.' + split[1], {
          type: `image/${split[1]}`,
        })
        setvalue(() => ({
          alt: `${nameFormat.replaceAll('-', ' ').substring(0, 35)}`,
          name_image: `${nameFormat.replaceAll('-', ' ').substring(0, 35)}`,
          file_image: myNewFile,
          isMain: true,
        }))
      }
    }
  }, [productData])

  switch (type) {
    case 'file_nutritional_info':
      return (
        <label htmlFor={id}>
          <Input
            className={classes.hidden}
            id={id}
            inputProps={{ accept: 'image/*' }}
            type="file"
            onInput={(e) => {
              if (e.target.files.length && e.target.files[0].type.includes('image')) {
                handle_imageTable(e.target.files[0])
              } else if (!e.target.files[0].type.includes('image')) {
                setAlert({ open: true, message: 'Formato del archivo debe ser una imagen', severity: 'error' })
              } else {
                setNutritionalTable()
              }
            }}
            fontSize="4rem"
            {...rest}
          />
          <Button
            fullWidth
            variant="contained"
            component="span"
            style={{ background: '#F59323', fontWeight: '700' }}
            disabled={isLoading}
            {...rest}>
            {!isLoading ? label : 'Analizando...'}
          </Button>
        </label>
      )
    case 'file_ingredients':
      return (
        <label htmlFor={id}>
          <Input
            className={classes.hidden}
            id={id}
            inputProps={{ accept: 'image/*' }}
            type="file"
            onInput={(e) => {
              if (e.target.files.length && e.target.files[0].type.includes('image')) {
                handle_imageIngredients(e.target.files[0])
              } else if (!e.target.files[0].type.includes('image')) {
                setAlert({ open: true, message: 'Formato del archivo debe ser una imagen', severity: 'error' })
              } else {
                setIngredients()
              }
            }}
            fontSize="4rem"
            {...rest}
          />
          <Button
            fullWidth
            variant="contained"
            component="span"
            style={{ background: '#F59323', fontWeight: '700' }}
            disabled={isLoading}
            {...rest}>
            {!isLoading ? label : 'Analizando...'}
          </Button>
        </label>
      )
    case 'file':
      let valueImage = Array.isArray(value) ? value[0] : value
      return (
        <>
          {valueImage.file_image ? (
            <Grid
              container
              direction="column"
              style={{ borderRadius: '0.5rem', boxShadow: '0 0 5px #00000033', padding: '0.5rem 1.5rem 0 1.5rem' }}>
              {
                <Grid item container justifyContent="flex-end">
                  <IconButton color="primary" aria-label="upload picture" component="span" onClick={removeImage}>
                    <Close />
                  </IconButton>
                </Grid>
              }
              <Grid item container spacing={4} style={{ marginBottom: '1rem', marginTop: !init ? '1rem' : 'inherit' }}>
                <Grid item xs={12} md={4}>
                  <img
                    src={
                      typeof value.file_image == 'string'
                        ? `${process.env.REACT_APP_S3_URL}${value.file_image}?date=${Date.now()}`
                        : typeof value.file_image === 'object'
                        ? URL.createObjectURL(value.file_image)
                        : ''
                    }
                    style={{ width: '100%' }}
                    alt=""
                  />
                </Grid>
                <Grid container item xs={12} md={8} direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle2">Nombre del Archivo: </Typography>
                    <Typography variant="subtitle1" style={{ overflowWrap: 'anywhere' }}>
                      {typeof value.file_image == 'string' ? value.file_image : value.file_image.name}
                    </Typography>
                  </Grid>

                  <Grid item container spacing={2} direction="column">
                    <Grid item>
                      <FormControl variant="standard" color="secondary" fullWidth>
                        <InputLabel htmlFor="name_image">Nombre de la imagen</InputLabel>
                        <Input
                          type="text"
                          id="name_image"
                          aria-describedby="Nombre de la imágen (max 35 caracteres)"
                          inputProps={{ maxLength: 35, autoComplete: 'off' }}
                          onChange={(e) => setvalue({ ...value, name_image: e.target.value })}
                          value={value.name_image}
                          disabled={stateInitial.current === value ? true : false}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl variant="standard" color="secondary" fullWidth>
                        <InputLabel htmlFor="name_image">Texto descriptivo</InputLabel>
                        <Input
                          type="text"
                          id="alt"
                          aria-describedby="Texto descriptivo (max 25 caracteres)"
                          inputProps={{ maxLength: 35, autoComplete: 'off' }}
                          onChange={(e) => setvalue({ ...value, alt: e.target.value })}
                          value={value.alt}
                          disabled={stateInitial.current === value ? true : false}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <label htmlFor={id}>
                {imageRemoved ? (
                  <Undo style={{ padding: 0, marginBottom: '16px', cursor: 'pointer' }} onClick={restoreImageRemoved}>
                    Deshacer
                  </Undo>
                ) : (
                  ''
                )}
                <Input
                  className={classes.hidden}
                  id={id}
                  disabled={isLoading || !productData.name}
                  inputProps={{ accept: 'image/*' }}
                  type="file"
                  onInput={(e) => {
                    const nameFormat = productData?.name ? slugGenerator(productData.name) : ''
                    const split = e.target.files[0]?.name?.split('.')
                    const myNewFile = new File([e.target.files[0]], nameFormat + '.' + split[1], {
                      type: `image/${split[1]}`,
                    })
                    validateImage({
                      ...value,
                      alt: `${nameFormat.replaceAll('-', ' ').substring(0, 35)}`,
                      name_image: `${nameFormat.replaceAll('-', ' ').substring(0, 35)}`,
                      file_image: myNewFile,
                      isMain: true,
                    })
                  }}
                  fontSize="4rem"
                />
                <Button fullWidth variant="contained" disabled={isLoading || !productData.name} component="span">
                  {!isLoading ? label : 'Analizando...'}
                </Button>
              </label>
              {id === 'product_image' && (
                <p>La imagen debe medir aproximadamente 600x600 y el producto estar lo más centrado posible</p>
              )}
            </>
          )}
        </>
      )
    case 'checkbox':
      return (
        <FormControlLabel
          control={<Checkbox checked={value} {...rest} onChange={(e) => setvalue(e.target.checked)} />}
          label={label}
        />
      )

    case 'rte':
      return (
        <>
          <InputLabel htmlFor={id}>
            <Typography variant="subtitle1">{label}</Typography>
          </InputLabel>
          <Divider style={{ marginBottom: '1rem', marginTop: '.5rem' }} />
          <div>
            <MUIEditor
              id={id}
              editorState={value}
              config={configRte}
              {...rest}
              onChange={(newstate) => setvalue(newstate)}
            />
          </div>
        </>
      )
    case 'select':
      return (
        <FormControl fullWidth color="secondary" variant="standard" error={Boolean(FindIdError())}>
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select
            labelId={`${id}-label`}
            id={id}
            value={value}
            label={label}
            onChange={(e) => {
              setvalue(e.target.value)
              id === 'ID_producer' ? setNameProducer(e.nativeEvent.srcElement.innerText) : ''
            }}
            {...rest}>
            <MenuItem value="" name="" disabled>
              <em>Ninguno</em>
            </MenuItem>
            {list &&
              list.map((item) => (
                <MenuItem value={item.ID} key={item.name} selected={item.ID === value}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {FindIdError() && <FormHelperText id={`on-error-${id}`}>{FindIdError().msg}</FormHelperText>}
        </FormControl>
      )

    case 'number': {
      return (
        <FormControl variant="standard" fullWidth color="secondary" error={Boolean(FindIdError())}>
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Input
            type={type}
            id={id}
            aria-describedby={label}
            value={value}
            ref={innerRef}
            onChange={(e) => {
              setvalue(e.target.value ? Number(e.target.value) : e.target.value)
            }}
            {...rest}
          />
          {FindIdError() && <FormHelperText id={`on-error-${id}`}>{FindIdError().msg}</FormHelperText>}
          {helperText !== undefined && <FormHelperText id="component-helper-text">{helperText}</FormHelperText>}
          {/* Para la validación a posterioridad */}
        </FormControl>
      )
    }
    default:
      return (
        <FormControl variant="standard" fullWidth color="secondary" error={Boolean(FindIdError())}>
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Input
            type={type}
            id={id}
            aria-describedby={label}
            value={value}
            onChange={(e) => setvalue(e.target.value)}
            {...rest}
          />
          {FindIdError() && <FormHelperText id={`on-error-${id}`}>{FindIdError().msg}</FormHelperText>}
          {helperText !== undefined && <FormHelperText id="component-helper-text">{helperText}</FormHelperText>}
          {/* Para la validación a posterioridad */}
        </FormControl>
      )
  }
}
