import React, { useContext } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import AlertContext from '../context/AlertContext'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function AlertSnackbar() {
  const classes = useStyles()
  const { alert, setAlert } = useContext(AlertContext)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert({ ...alert, open: false })
  }

  return (
    <div className={classes.root}>
      <Snackbar open={alert.open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity}>
          <div dangerouslySetInnerHTML={{ __html: alert.message }} style={{ whiteSpace: 'pre-line' }}></div>
        </Alert>
      </Snackbar>
    </div>
  )
}
