import { gql } from '@apollo/client'

const DISCOUNTS = {
  FULL: gql`
    query Discount($last: Boolean) {
      discount(last: $last) {
        discount {
          ID_discount
          ad_catalogue
          ad_main
          purchase_new_producer
          first_purchase
        }
      }
    }
  `,
  POST_FULL: gql`
    mutation UpdateDiscountStatusById(
      $idDiscount: String!
      $isActive: Boolean
      $adMain: String
      $adCatalogue: String
      $firstPurchase: Int
      $purchaseNewProducer: Int
    ) {
      updateDiscountStatusById(
        ID_discount: $idDiscount
        isActive: $isActive
        ad_main: $adMain
        ad_catalogue: $adCatalogue
        first_purchase: $firstPurchase
        purchase_new_producer: $purchaseNewProducer
      ) {
        discount {
          ID_discount
          ad_main
          ad_catalogue
          first_purchase
          purchase_new_producer
          isActive
          created_at
        }
      }
    }
  `,
  PUT_AD_MAIN: gql`
    mutation UpdateDiscountStatusById($idDiscount: String!, $adMain: String) {
      updateDiscountStatusById(ID_discount: $idDiscount, ad_main: $adMain) {
        discount {
          ID_discount
          ad_main
        }
      }
    }
  `,
  PUT_AD_CATALOGUE: gql`
    mutation UpdateDiscountStatusById($idDiscount: String!, $adCatalogue: String) {
      updateDiscountStatusById(ID_discount: $idDiscount, ad_catalogue: $adCatalogue) {
        discount {
          ID_discount
          ad_catalogue
        }
      }
    }
  `,
  PUT_FIRST_PURCHASE: gql`
    mutation UpdateDiscountStatusById($idDiscount: String!, $firstPurchase: Int) {
      updateDiscountStatusById(ID_discount: $idDiscount, first_purchase: $firstPurchase) {
        discount {
          ID_discount
          first_purchase
        }
      }
    }
  `,
  PUT_PURCHASE_NEW_PRODUCER: gql`
    mutation UpdateDiscountStatusById($idDiscount: String!, $purchaseNewProducer: String) {
      updateDiscountStatusById(ID_discount: $idDiscount, purchase_new_producer: $purchaseNewProducer) {
        discount {
          ID_discount
          purchase_new_producer
        }
      }
    }
  `,
}
export default DISCOUNTS
