import priceFormat from '../utils/priceFormat'
import heart from '../heart_comes.svg'
import { makeStyles } from '@material-ui/core'
import { useGetSalesAmount } from '../hooks/useGetSales'

export default function Home() {
  const useStyles = makeStyles((theme) => ({
    img: {
      width: '10rem',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '3rem',
      padding: '2rem',
    },
    sales: {
      border: '1px solid #F59323',
      borderRadius: '15px',
      width: 'fit-content',
      padding: '1rem',
    },
    textSales: {
      fontWeight: '700',
      fontSize: '1.25rem',
    },
    total: {
      color: '#6A9C2C',
    },
  }))
  const classes = useStyles()
  const { totalSales } = useGetSalesAmount()
  return (
    <div className={classes.container}>
      <img src={heart} className={classes.img} alt="heart" id="heart" />
      <div className={classes.sales}>
        <span className={classes.textSales} style={{ padding: '1rem' }}>
          Total Ventas Completadas <span className={classes.total}> {priceFormat(totalSales)} </span>
        </span>
      </div>
      <h1 className="textHome">Solo los que corren el riesgo de avanzar, pueden saber hasta dónde pueden llegar</h1>
    </div>
  )
}
