export const materialDateInput = () => {
  const dateNow = new Date() // Se obtiene fecha actual
  const year = dateNow.getFullYear() // año actual
  const monthWithOffset = dateNow.getUTCMonth() + 1 // mes (+1 porque enero es 0)
  const month = // mes actual
    monthWithOffset.toString().length < 2 // si es un digito se antepone el cero
      ? `0${monthWithOffset}`
      : monthWithOffset
  const date =
    dateNow.getUTCDate().toString().length < 2 // si es un digito se antepone el cero
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate()

  return `${date}-${month}-${year}` // formato que recibe TextField
}
