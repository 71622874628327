import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import priceFormat from '../../../utils/priceFormat'

const useStyles = makeStyles({
  root: {
    width: 190,
    maxWidth: 190,
    margin: '1rem',
    marginBottom: '3rem',
  },
  media: {
    height: 190,
    maxHeight: 190,
    backgroundSize: 'contain',
  },
  containerButton: {
    width: 190,
    maxWidth: 190,
    margin: '1rem',
    marginBottom: '3rem',
    '&:hover': {
      background: 'rgba(138, 197, 65, 0.4)',
      cursor: 'pointer',
    },
  },
  textBtn: {
    color: '#7a7a7a',
    fontSize: '1.5rem',
    margin: '43px',
    textAlign: 'center',
    fontWeight: '500',
  },
})

export default function CardProduct({
  product,
  setSuggestedProducts,
  products = [],
  index,
  suggestedProducts = [product],
  add = false,
  addCardProduct,
  deleteCardProduct,
  setProducts,
}) {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState(suggestedProducts[index])

  const defaultProps = {
    options: products,
    getOptionLabel: (option) => option.name,
  }

  useEffect(() => {
    if (value !== suggestedProducts[index]) {
      suggestedProducts[index] = value
      setSuggestedProducts([...suggestedProducts])
      const currentProducts = products.filter((product) => product.ID_product !== value.ID_product)
      setProducts(currentProducts)
    }
  }, [value])

  return (
    <>
      {add ? (
        <Grid container item direction="column" md={4} xs={12} spacing={2} alignItems="center">
          <Grid item>
            <Card className={classes.containerButton} onClick={() => addCardProduct()}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.textBtn}>
                Agregar producto destacado
              </Typography>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="column"
          md={4}
          xs={12}
          spacing={2}
          alignItems="center"
          style={{ margin: '0px' }}>
          <Grid item>
            <Autocomplete
              value={product}
              onChange={(event, newValue) => {
                setValue(newValue ?? product)
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
              id="suggested-product"
              {...defaultProps}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Producto recomendado" variant="outlined" />}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
            <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                image={`${process.env.REACT_APP_S3_URL}${
                  product.product_image[0]?.file_image || 'imagen_no_disponible.jpg'
                }`}
                title={product.product_image[0] ? product.product_image[0].name_image : product.name}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{
                    color: '#7a7a7a',
                    fontSize: '1rem',
                    height: '43px',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                  }}>
                  {product.name}
                </Typography>
                <Typography variant="body2" color="secondary" component="p">
                  {product.producer.brand_name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: '#7a7a7a',
                    fontSize: '1rem',
                    fontWeight: '700',
                    textAlign: 'center',
                    margin: '0.5rem 0',
                  }}
                  component="p">
                  {product.sale_format} un. <span style={{ color: '#f69223' }}>x</span>{' '}
                  {priceFormat(product.price_package)}
                </Typography>
              </CardContent>

              <Button
                fullWidth={true}
                size="medium"
                style={{
                  cursor: 'text',
                  color: 'white',
                  background: '#f69223',
                  borderRadius: '0',
                  textTransform: 'none',
                }}>
                ¡Lo quiero!
              </Button>
            </Card>
            {index !== 0 && (
              <>
                <IconButton
                  color="primary"
                  aria-label="delete card"
                  component="span"
                  size="small"
                  onClick={() => deleteCardProduct(index)}>
                  <Cancel />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}
