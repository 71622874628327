import { Button, Grid, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { Error, WhatsApp } from '@material-ui/icons'
import { useContext, useState } from 'react'
import NewOrderContext from '../../context/NewOrderState'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import priceFormat from '../../utils/priceFormat'
import AlertContext from '../../context/AlertContext'
import setDetails from '../../utils/setDetails'
import UserContext from '../../context/UserContext'
import SendWsOrder from '../../utils/SendWsOrder'
import { usePostCreateorderNote } from '../../hooks/usePostOrderNote'
import { useGetLazyProductsByIds } from '../../hooks/useGetProducts'
import { fullOrderNote } from '../../utils/fullOrderNote'
import { useGetLazyOrderNoteByProducer } from '../../hooks/useGetOrderNotes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '50vw',
    padding: '0 1.5rem',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(2, 2),
  },
  height: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: '#3A3A3A',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    marginBottom: '0.5rem',
    width: 'fit-content',
    alignSelf: 'center',
    border: '1px solid #9dc34f',
    padding: '0.5rem 0.8rem',
    borderRadius: '18px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },
  containerError: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1rem',
  },
}))

export default function ModalSummary({ handleClose }) {
  const classes = useStyles()

  const { orderStore, setOrderStore } = useContext(NewOrderContext)
  const { alert, setAlert } = useContext(AlertContext)
  const { user } = useContext(UserContext)
  const [value, setValue] = useState(orderStore.discount)
  const [load, setLoad] = useState(false)
  const [error, setError] = useState(false)
  const [errorWS, setErrorWs] = useState(false)
  const [numberOrder, setNumberOrder] = useState()
  const { createOrderNote } = usePostCreateorderNote()
  const { requestOrderNoteByProducer } = useGetLazyOrderNoteByProducer()
  const { requestProducstByIds } = useGetLazyProductsByIds()

  async function handleConfirm() {
    setLoad(true)
    let payload = {
      number: null,
      neto: +orderStore.totalNeto.toFixed(0),
      iva: +orderStore.totalTax.toFixed(0),
      total: +orderStore.total.toFixed(0),
      total_order: +orderStore.totalDsc.toFixed(0),
      shipping: +orderStore.shipping?.toFixed(0),
      type_discount: orderStore.discount !== '0' ? orderStore.discountType : null,
      discount: orderStore.discount !== '0' ? +(+orderStore.discount).toFixed(0) : null,
      comment_by_order: orderStore.comment_by_order,
      vendor: orderStore.vendor.ID_user,
      created_by: user.ID_user,
      ID_store: orderStore.ID_store,
      ID_order_status: '1',
      ID_store_direction: orderStore.address.ID_store_direction,
      modification_date: new Date().toISOString(),
      creation_date: new Date().toISOString(),
      detail_order_note: {
        create: setDetails(orderStore.details, orderStore.internalOrder),
      },
    }
    if (!orderStore.internalOrder) {
      payload = {
        ...payload,
        order_note_producer: {
          ID_producer: orderStore.details[0].producer.ID_producer,
          producer_brand_name: orderStore.details[0].producer.brand_name,
        },
      }
    }

    const { data, loading, error } = await createOrderNote({
      variables: {
        payload,
      },
    })
    if (data) {
      setNumberOrder(data?.createOrder?.order?.number)
      const {
        data: { orderNoteById },
      } = await requestOrderNoteByProducer({
        variables: {
          idProducer: null,
          idOrderNote: data?.createOrder?.order?.ID_order_note,
        },
      })
      const fullOrder = await fullOrderNote(orderNoteById, requestProducstByIds)
      SendWsOrder(fullOrder).then((result) => {
        if (result.some((item) => item.type === 'error')) {
          setAlert({
            open: true,
            message: result
              .filter((item) => item.type === 'error')
              .map((item) => item.message)
              .toLocaleString()
              .replace(',', '\n'),
            severity: result.filter((item) => item.type === 'error')[0].type,
          })
        } else {
          setAlert({
            open: true,
            message: 'Mensaje enviado a los productores',
            severity: result[0].type,
          })
        }
      })
      handleClose()
      setOrderStore({
        ID_order_status: '0',
        details: [],
        discount: '0',
        discountType: 'percent',
        image: [],
        totalDsc: 0,
      })
    }
    if (error) {
      setAlert({ open: true, message: 'Error de comunicacion.', severity: 'error' })
      console.error(error)
      setError(true)
      setErrorWs(true)
      setOrderStore({
        ID_order_status: '0',
        details: [],
        discount: '0',
        discountType: 'percent',
        image: [],
      })
    }

    setLoad(false)
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2}>
        <Grid container item alignItems="center" spacing={2}>
          <Grid item>
            <Error fontSize="large" color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="h5" id="transition-modal-title">
              RESUMEN
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography variant="body1" id="transition-modal-title">
              Estás apunto de enviar un pedido con los siguientes datos:
            </Typography>
          </Grid>
          {/* grid principal */}
          <Grid container item spacing={6} direction={'column'}>
            {/* Grid hijo */}
            <Grid container item spacing={3}>
              <Grid container item spacing={2}>
                <Grid item padding={'0'}>
                  <Typography variant="body1" id="transition-modal-title">
                    <b>Tienda:</b> {orderStore.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" id="transition-modal-title">
                    <b>Dirección:</b> {orderStore.address.street} #{orderStore.address.number + ', '}
                    {orderStore.address.local_or_office ? orderStore.address.local_or_office + ', ' : ''}
                    {orderStore.address.municipality}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" id="transition-modal-title">
                    <b>Vendedor:</b> {orderStore.vendor.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item className={classes.height}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell align="right">Productor</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderStore.details.map((product) => (
                        <TableRow key={product.ID_product} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {product.name}
                          </TableCell>
                          <TableCell align="right">{product.producer.brand_name}</TableCell>
                          <TableCell align="right">{product.qty}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {/* Grid hijo */}
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    id="comment_by_order"
                    label="Nota de pedido"
                    multiline
                    rows={4}
                    inputProps={{ readOnly: true }}
                    defaultValue={orderStore.comment_by_order}
                  />
                </FormControl>
              </Grid>
              <Grid container item direction="column">
                <Grid item>
                  <Typography variant="body1">
                    <b>{orderStore.discount > 0 || orderStore.shipping ? 'subtotal:' : 'total:'}</b>{' '}
                    {priceFormat(orderStore.total)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <b>Descuento:</b>{' '}
                    {orderStore.discountType === 'amount' ? priceFormat(orderStore.discount) : orderStore.discount}{' '}
                    {orderStore.discountType === 'percent' ? '%' : ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <b>Despacho:</b>
                    {orderStore.shipping ? priceFormat(orderStore.shipping) : 0}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <b>Total</b> {priceFormat(orderStore.totalDsc)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={handleClose}
              disabled={load}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={load}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              onClick={handleConfirm}>
              <Grid container spacing={2}>
                <Grid item>Confirmar</Grid>
                {load && (
                  <Grid item>
                    <CircularProgress color="primary" size={20} />
                  </Grid>
                )}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
