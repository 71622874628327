import { createContext, useEffect, useState } from 'react'
import { MUIEditorState } from 'react-mui-draft-wysiwyg'

const Context = createContext({})

export function NewProductState({ children }) {
  const [productData, setProductData] = useState({
    description: MUIEditorState.createEmpty(),
    benefit: MUIEditorState.createEmpty(),
    conservation: MUIEditorState.createEmpty(),
    isActive: true,
    duration_type: '',
  })

  return <Context.Provider value={{ productData, setProductData }}>{children}</Context.Provider>
}

export default Context
