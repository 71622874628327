import { Grid, InputAdornment, LinearProgress, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { EnhancedTableProducers } from '../../components/producers/EnhancedTableProducers'
import * as XSLX from 'xlsx'
import { formatMinPurchase } from '../../utils/formatMinPurchase'
import { useGetLazyProducers, useGetProducers } from '../../hooks/useGetProducers'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
  },
}))

export const Producers = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('brand_name')
  const [skip, setSkip] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [paramsSearched, setParamsSearched] = useState('')
  const { requestProducers } = useGetLazyProducers()
  const query = paramsSearched.length !== 0 ? paramsSearched.toLowerCase() : ''
  let { producers, loading } = useGetProducers({
    skip,
    take: query ? null : rowsPerPage,
    order: order.toUpperCase(),
    field: orderBy.toUpperCase(),
    isActive: null,
    isDeleted: false,
    q: query,
  })

  const classes = useStyles()

  const producersDownload = async () => {
    const { data } = await requestProducers({
      variables: {
        skip: 0,
        take: null,
        field: 'BRAND_NAME',
        order: 'ASC',
        isActive: null,
        isDeleted: false,
        q: '',
      },
    })
    const allProducers = data?.producers?.producer
    //crear un libro
    const workbook = XSLX.utils.book_new()
    // crear columnas basadas en un arreglo de objetos
    const rows = (query.length ? producers.producer : allProducers).map((producer) => ({
      Productor: producer.brand_name,
      'Razón social': producer.business_name,
      RUT: producer.rut,
      'Mínimo de venta': formatMinPurchase(producer),
      Estado: producer.isActive ? 'Activo' : 'Inactivo',
      'Nombre de contacto': producer.contact[0].name,
      'N° de contacto': producer.contact[0].phone,
      historia: producer.history,
      slogan: producer.slogan ?? ' ',
      slug: producer.slug ?? ' ',
    }))
    // crear la hoja
    const worksheet = XSLX.utils.json_to_sheet(rows)
    //Define el ancho de las columnas
    worksheet['!cols'] = [
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ]
    //unir hoja con el libro
    XSLX.utils.book_append_sheet(workbook, worksheet, 'Productores')
    //generar el archivo
    XSLX.writeFile(workbook, 'listado_de_productores.xlsx')
  }

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Buscar productor"
            type="search"
            variant="outlined"
            onChange={(event) =>
              setTimeout(() => {
                setParamsSearched(event.target.value)
                setSkip(0)
              }, 1000)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={12}>
          {loading ? (
            <LinearProgress style={{ width: '100%' }} />
          ) : (
            <EnhancedTableProducers
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setSkip={setSkip}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              loading={loading}
              producers={producers?.producer}
              producerCount={producers?._count}
              skip={skip}
              isSearched={Boolean(paramsSearched)}
              producersDownload={producersDownload}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
