import Layout from './components/Layout'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import theme from './utils/theme'
import Login from './components/login'
import AddProduct from './pages/product/AddProduct'
import { ErrorContext } from './context/ErrorContext'
import { NewProductState } from './context/NewProductState'
import { OrderList } from './pages/orders/OrdersList'
import AddOrder from './pages/orders/AddOrder'
import { Product } from './pages/product/Product'
import { AlertContext } from './context/AlertContext'
import AlertSnackbar from './components/Snackbar'
import { NewOrderState } from './context/NewOrderState'
import { PriceEditor } from './pages/product/PriceEditor'
import { UserContext } from './context/UserContext'
import { Producers } from './pages/producers/Producers'
import { DeliveryZones } from './components/producers/delivery-zones/Delivery-zones'
import OrderProducer from './pages/producers/OrderProducer'
import SetupStore from './pages/pages-setup/setup-store'
import Quoter from './pages/Quoter/Quoter'
import { ApolloProvider } from '@apollo/client'
import { client } from './services/client'
import Home from './components/Home'
import { EditProducer } from './pages/producers/EditProducer'

function App() {
  return (
    <UserContext>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <NewProductState>
            <NewOrderState>
              <ErrorContext>
                <AlertContext>
                  <AlertSnackbar />
                  <div className="App">
                    <Login>
                      <Layout>
                        <Switch>
                          <Route path="/" exact={true}>
                            <Home />
                          </Route>
                          <Route path="/productos" component={Product} />
                          <Route path="/agregar-producto" component={AddProduct} />
                          <Route path="/editar-producto/:id" component={AddProduct} />
                          <Route path="/pedidos" component={OrderList} />
                          <Route path="/ingresar-pedido" component={AddOrder} />
                          <Route path="/editor-precios" component={PriceEditor} />
                          <Route path="/productores" component={Producers} />
                          <Route path="/configuracion/pagina-tiendas" component={SetupStore} />
                          <Route
                            path={['/pedidos-productor/:name/:id', '/pedidos-productor']}
                            component={OrderProducer}
                          />
                          <Route path="/editar-productor/:id" component={EditProducer} />
                          <Route path="/cotizador" component={Quoter} />
                          <Route path="/zona-de-despacho/:id/:name" component={DeliveryZones} />
                        </Switch>
                      </Layout>
                    </Login>
                  </div>
                </AlertContext>
              </ErrorContext>
            </NewOrderState>
          </NewProductState>
        </ThemeProvider>
      </ApolloProvider>
    </UserContext>
  )
}

export default App
