import { gql } from '@apollo/client'

const EDITPRODUCER = {
  GETDATA: gql`
    query Nombre($idCategoryType: ID!, $idProducer: ID!, $idCategoryTypeTwo: ID!, $idCategoryTypeThree: ID!) {
      typeCertification {
        typeCertifications {
          type
          description
          ID_type_certification
        }
      }
      categoriesByIdType(ID_category_type: $idCategoryType) {
        categories {
          category
          ID_category
          category_type {
            ID_category_type
            type
          }
        }
      }
      categoriesByIdType_Two: categoriesByIdType(ID_category_type: $idCategoryTypeTwo) {
        categories {
          category
          ID_category
          category_type {
            ID_category_type
            type
          }
        }
      }
      categoriesByIdType_Three: categoriesByIdType(ID_category_type: $idCategoryTypeThree) {
        categories {
          category
          ID_category
          category_type {
            ID_category_type
            type
          }
        }
      }
      productInfoByProducer(ID_producer: $idProducer) {
        assets
        hasBarcode
        hasProductCertification
        hasTechnicalSheet
        total
      }
      resolutionByIdProducer(ID_producer: $idProducer) {
        resolution {
          ID_resolution
          emit_date
          url
          number
          verification_code
          created_at
          modificated_at
          ID_producer
        }
        message
      }
      certificationByIdProducer(ID_producer: $idProducer) {
        certification {
          ID_certification
          ID_producer
          created_at
          modificated_at
          type_certification {
            ID_type_certification
            description
            type
          }
          url
          valid_until
        }
      }
      categoriesProducerByIdProducer(ID_producer: $idProducer) {
        assignedCategories {
          ID_category
          category
          category_type {
            type
            ID_category_type
          }
        }
        message
        unassignedCategories {
          category_type {
            ID_category_type
            type
          }
          category
          ID_category
        }
      }
      producerLogo(ID_producer: $idProducer) {
        logo {
          file_name
          ID_producer
          ID_files
        }
        message
      }
      productsInfoByIdProducer(ID_producer: $idProducer) {
        productsInfo {
          hasCertification
          hasNutritional_info
          hasIngredient_sheet
          hasExpiration_date
          ID_products_info
        }
      }
      producerWithProducts(ID_producer: $idProducer) {
        productCount
        deliveryZones {
          zones
          _count
        }
        producer {
          ID_producer
          comment
          additional_info
          bank_account {
            type
            rut
            number
            holder
            bank
            ID_bank_account
          }
          brand_name
          business_name
          contact {
            ID_contact
            email
            name
            phone
          }
          delivery_time
          direction {
            ID_direction
            label
            sub_region
            street
            region
            reference
            number
            municipality
            longitud
            local_or_office
            latitud
            country
            code_country
          }
          files {
            file_name
            ID_files
          }
          formalization {
            health_resolution
            start_activities
          }
          history
          isActive
          isDeleted
          isPublished
          min_purchase
          producer_category {
            category {
              category_type {
                type
                ID_category_type
              }
              ID_category
              category
            }
          }
          rut
          slug
          type_sale {
            type
            ID_type_sale
          }
          web
        }
      }
    }
  `,
  UPDATE_PRODUCER: gql`
    mutation UpdateProducer(
      $ID_producer: ID!
      $ID_contact: ID
      $ID_direction: ID
      $ID_products_info: ID
      $contactPayload: contactUpdPayload
      $producerPayload: producerUpdPayload
      $directionPayload: directionUpdPayload
      $productsInfoPayload: productsUpdPayload
      $createProducerCategoryPayload: [producerCategory!]!
      $deleteProducerCategoryByIdPayload: [producerCategory]!
      $createCertificationPayload: [certificationPayload]
      $createResolutionPayload: resolutionPayload
    ) {
      upsertContact(payload: $contactPayload, ID_contact: $ID_contact) {
        message
        ID_contact
      }
      updateProducer(ID_producer: $ID_producer, payload: $producerPayload) {
        ID_producer
        message
      }
      upsertDirection(payload: $directionPayload, ID_direction: $ID_direction) {
        ID_direction
        message
      }
      upsertProductsInfo(ID_products_info: $ID_products_info, payload: $productsInfoPayload) {
        ID_products_info
      }
      createProducerCategory(ID_producer: $ID_producer, payload: $createProducerCategoryPayload) {
        message
        totalAssigned
      }
      deleteProducerCategoryById(payload: $deleteProducerCategoryByIdPayload, ID_producer: $ID_producer) {
        message
        totalDeleted
      }
      createCertification(ID_producer: $ID_producer, payload: $createCertificationPayload) {
        count
        message
      }
      createResolution(payload: $createResolutionPayload) {
        ID_resolution
        message
      }
    }
  `,
  DELETE_DOCS: gql`
    mutation DeleteDocs($payload: [String], $origen: OrigenDocs) {
      deleteDocs(payload: $payload, origen: $origen) {
        message
      }
    }
  `,
}
export default EDITPRODUCER
