import { Checkbox, FormControlLabel, Grid, makeStyles, withStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Add, Minimize } from '@material-ui/icons'
import { useState } from 'react'

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function CheckZones({ option, state, addMunicipality, addRegion, disabled, cleanString }) {
  const useStyles = makeStyles((theme) => ({
    containerCheck: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    container: {
      width: `${window.innerWidth > 800 ? '25%' : '100%'}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '0.725rem 2rem',
    },
    icon: {
      cursor: `${disabled ? 'not-allowed' : 'pointer'}`,
      color: `${disabled ? 'rgba(0, 0, 0, 0.38)' : 'primary'}`,
    },
    details: {
      marginLeft: '2rem',
    },
  }))

  const classes = useStyles()

  const [more, setMore] = useState({
    checked: false,
  })
  const isSelected = (municipality, option) => {
    if (state.selected) {
      return state.selected.some((el) => {
        if (cleanString(el.name) === cleanString(option.name)) {
          return el.municipality.some((muni) => cleanString(muni.name) === cleanString(municipality.name))
        } else {
          return false
        }
      })
    } else {
      return false
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerCheck}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.selected.some((el) => cleanString(el.name) === cleanString(option.name))}
                onChange={(e) => addRegion(e, option)}
                name="checkedRegion"
                disabled={disabled}
              />
            }
            label={option.name}
          />
          {option.municipality ? (
            more.checked ? (
              <Minimize
                color="primary"
                className={classes.icon}
                onClick={() => (disabled ? '' : setMore({ ...more, checked: false }))}
                style={{ marginBottom: '1rem' }}
              />
            ) : (
              <Add
                color="primary"
                className={classes.icon}
                onClick={() => (disabled ? '' : setMore({ ...more, checked: true }))}
              />
            )
          ) : (
            ''
          )}
        </div>
        {more.checked ? (
          <Grid container direction="column">
            {option.municipality.map((municipality) => (
              <FormControlLabel
                key={municipality.ID_municipality}
                control={
                  <GreenCheckbox
                    checked={isSelected(municipality, option)}
                    onChange={(e) => addMunicipality(e, municipality, option)}
                    name="idMunicipality"
                    disabled={disabled}
                  />
                }
                label={`${municipality.name} `}
                className={classes.details}
              />
            ))}
          </Grid>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
