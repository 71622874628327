const sendEmailErrorWsp = async ({
  production = false,
  nameProducer = 'Nombre Productor',
  nameStore = 'Nombre tienda',
  error = 'Fallo de prueba',
  numberOrder = 1111,
  place = 'Lugar de prueba',
  products = 'Productos de prueba',
}) => {
  try {
    const res = await fetch(process.env.REACT_APP_EMAIL_ERROR_WS, {
      method: 'POST',
      body: JSON.stringify({
        production,
        nameProducer,
        nameStore,
        error,
        numberOrder,
        place,
        products,
      }),
    })
  } catch (error) {
    console.error(error)
  }
}
export default sendEmailErrorWsp
