import { createContext, useEffect, useState } from 'react'

const Context = createContext({})
const TAX_RATE = 0.19

export function NewOrderState({ children }) {
  const [orderStore, setOrderStore] = useState({
    ID_order_status: '0',
    details: [],
    discount: '0',
    discountType: 'percent',
    image: [],
    shipping: 0,
    totalDsc: 0,
  })

  function total(items) {
    if (orderStore.details && orderStore.details.length !== 0) {
      return items.reduce((a, cur) => a + cur.net_price_package * cur.qty, 0) + +totalTax()
    } else {
      return 0
    }
  }

  function totalItems() {
    if (orderStore.details && orderStore.details.length !== 0) {
      return orderStore.details.reduce((a, cur) => a + cur.qty, 0)
    }
  }

  function totalNeto(items) {
    return orderStore.details && orderStore.details.length === 0
      ? 0
      : items.reduce((a, cur) => a + cur.net_price_package * cur.qty, 0)
  }

  const desc = () => {
    return orderStore.discountType === 'percent'
      ? +((orderStore.details.length ? total(orderStore.details) : 0) * (orderStore.discount * 0.01)).toFixed(0)
      : orderStore.discount
  }
  const totalTax = () =>
    orderStore.details && orderStore.details.length === 0 ? 0 : +(totalNeto(orderStore.details) * TAX_RATE).toFixed(0)
  const totalDsc = () =>
    (orderStore.details && orderStore.details.length ? total(orderStore.details) : 0) -
    desc() +
    Number(orderStore.shipping ?? 0)

  useEffect(() => {
    setOrderStore({
      ...orderStore,
      total: total(orderStore.details),
      totalNeto: totalNeto(orderStore.details),
      totalDiscount: desc(),
      totalTax: totalTax(),
      totalDsc: totalDsc(),
    })
  }, [totalItems(), orderStore.discount, orderStore.shipping, orderStore.details])

  return <Context.Provider value={{ orderStore, setOrderStore }}>{children}</Context.Provider>
}

export default Context
