import { gql } from '@apollo/client'

const TOKEN = {
  GET: gql`
    mutation Auth($payload: authPayload!) {
      auth(payload: $payload) {
        HTTP_status {
          code
          status
        }
        token
      }
    }
  `,
}
export default TOKEN
