import { gql } from '@apollo/client'

const SHIPPING_STATUS = {
  GET: gql`
    query ShippingStatus($idOrderNote: ID!) {
      shippingStatus(ID_order_note: $idOrderNote) {
        shipping {
          ID_shipping_status
          courier {
            ID_courier
            name
          }
          created_at
          producer {
            ID_producer
            brand_name
            isTrusted
          }
          status
          tracking_number
        }
      }
    }
  `,
  GET_COURIER: gql`
    query Courier {
      courier {
        couriers {
          ID_courier
          name
        }
      }
    }
  `,
  CREATE: gql`
    mutation CreateShippingStatus($payload: [shippingStatusPayload]!) {
      createShippingStatus(payload: $payload) {
        invalid {
          courier
          tracking_number
        }
        message
      }
    }
  `,
  UPDATE: gql`
    mutation UpdateShippingStatus($payload: [updShippingStatusPayload!]!) {
      updateShippingStatus(payload: $payload) {
        message
      }
    }
  `,
}
export default SHIPPING_STATUS
