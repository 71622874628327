import { gql } from '@apollo/client'

const ORDER_STATUS = {
  UPDATE: gql`
    mutation OrderStatusChange($idOrderStatus: ID, $idOrderNote: ID, $modificatedBy: String) {
      orderStatusChange(ID_order_status: $idOrderStatus, ID_order_note: $idOrderNote, modificated_by: $modificatedBy) {
        currentStatus {
          ID_order_status
          status
        }
        message
        orderNote {
          ID_order_note
          number
          order_note_producer {
            ID_order_note_producer
            ID_producer
            number
            producer_brand_name
          }
        }
      }
    }
  `,
  GET: gql`
    query OrdersStatus {
      ordersStatus {
        orderStatus {
          ID_order_status
          status
        }
      }
    }
  `,
}
export default ORDER_STATUS
