import { gql } from '@apollo/client'

const CERTIFICATION = {
  UPDATE_PRODUCT_CERTIFICATION: gql`
    mutation CreateProductCertification(
      $idProduct: ID!
      $uploadPayload: [productCertificationPayload!]
      $deletePayload: [productCertificationDeletePayload]
    ) {
      createProductCertification(ID_product: $idProduct, payload: $uploadPayload) {
        count
      }
      deleteProductCertification(payload: $deletePayload) {
        count
        message
      }
    }
  `,
}
export default CERTIFICATION
