import { useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetSalesAmount() {
  const { data, error, loading } = useQuery(QUERY.SALES.GET_TOTAL, {
    context: { clientName: 'apiStore' },
    fetchPolicy: 'cache-and-network',
  })
  return { totalSales: data?.totalSales.amount, error, loading }
}
