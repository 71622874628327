import { Button, Grid, Input, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core'
import { useContext, useEffect, useRef, useState } from 'react'
import priceFormat from '../../utils/priceFormat'
import { useCreateInvoice, useUpdateInvoice } from '../../hooks/useInvoice'
import { QUERY } from '../../services/queries'
import { DescriptionOutlined } from '@material-ui/icons'
import AlertContext from '../../context/AlertContext'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
}))

export default function ModalInvoiceEntry({ orderSelected, handleClose, data }) {
  const classes = useStyles()
  const initialStateInvoice = useRef(orderSelected.order.invoice[0])
  const [invoice, setInvoice] = useState(
    orderSelected.order.invoice[0]
      ? {
          ...orderSelected.order.invoice[0],
          issue_date: new Date(+orderSelected.order.invoice[0].issue_date).toISOString(),
          due_date: new Date(+orderSelected.order.invoice[0].due_date).toISOString(),
          payment: 0,
        }
      : {}
  )
  const { createInvoice } = useCreateInvoice()
  const { updateInvoice } = useUpdateInvoice()
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    const diff =
      orderSelected.order.total_order - (initialStateInvoice?.current?.payment ?? 0) - (invoice?.payment ?? 0)
    setInvoice((currentState) => ({
      ...currentState,
      amount: orderSelected.order.total_order,
      ID_order_note: orderSelected.order.ID_order_note,
      ID_store: orderSelected.order.store.ID_store,
      payment: 0,
      balance: diff ?? 0,
    }))
    if (initialStateInvoice.current) {
      creditDays(
        new Date(+orderSelected.order.invoice[0].issue_date).toISOString(),
        new Date(+orderSelected.order.invoice[0].due_date).toISOString()
      )
    }
  }, [])

  const creditDays = (issue_date, due_date) => {
    const startDate = new Date(issue_date.replace(/T.{1,}/, '')).getTime()
    const endDate = new Date(due_date.replace(/T.{1,}/, '')).getTime()
    const diff = endDate - startDate
    setInvoice((currentState) => ({ ...currentState, credit_days: diff / (1000 * 60 * 60 * 24) }))
  }

  useEffect(() => {
    let diff
    if (initialStateInvoice?.current?.payment) {
      diff = orderSelected.order.total_order - (initialStateInvoice?.current?.payment ?? 0) - (invoice?.payment ?? 0)
    } else {
      diff = invoice?.amount - invoice?.payment
    }
    setInvoice((currentState) => ({ ...currentState, balance: diff }))
  }, [invoice?.payment, invoice?.amount])

  const addInvoice = async () => {
    const refetchQueries = {
      refetchQueries: [
        {
          query: QUERY.ORDER_NOTES.GET,
          variables: data,
          fetchPolicy: 'cache-and-network',
          context: { clientName: 'apiStore' },
        },
      ],
    }
    let error
    if (initialStateInvoice.current) {
      const { ID_invoice, ID_order_note, ID_store, credit_days, balance, __typename, status, ...updateRest } = invoice
      const { data, loading, error } = await updateInvoice({
        variables: {
          idInvoice: ID_invoice,
          payload: {
            ...updateRest,
          },
        },
        ...refetchQueries,
      })
      error
    } else {
      const { balance, credit_days, ID_invoice, status, __typename, ...rest } = invoice
      const { data, loading, error } = await createInvoice({
        variables: { payload: { ...rest } },
        ...refetchQueries,
      })
      error
    }
    if (error) {
      setAlert({ open: true, message: 'Ocurrió un error al ingresar los datos de factura', severity: 'error' })
    } else {
      setAlert({ open: true, message: 'Datos de factura guardados', severity: 'success' })
    }
    handleClose()
  }

  const handleDueDate = (e) => {
    if (invoice.issue_date && e.target.value) {
      const newDate = +new Date(invoice.issue_date).setDate(
        +new Date(invoice.issue_date).getDate() + +invoice.credit_days
      )
      setInvoice((currentState) => ({
        ...currentState,
        due_date: new Date(newDate).toISOString(),
      }))
    }
  }
  return (
    <form autoComplete="off" method="POST">
      <Grid container direction="column" spacing={4} style={{ padding: '0 2rem', maxWidth: '700px' }}>
        <Grid container item alignItems="center" style={{ gap: '1rem' }}>
          <div style={{ backgroundColor: '#bdbdbd', padding: '0.4rem 0.4rem 0.15rem 0.4rem', borderRadius: '50%' }}>
            <DescriptionOutlined style={{ color: 'white' }} />
          </div>
          <h2>Datos de factura:</h2>
        </Grid>
        <Grid container item alignItems="center" spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} md={4}>
            <TextField
              value={invoice?.n_invoice ?? ''}
              id="n_invoice"
              required
              name="n_invoice"
              label={'Número de factura'}
              fullWidth
              onChange={(e) => setInvoice((currentState) => ({ ...currentState, n_invoice: +e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              value={invoice.issue_date ? invoice?.issue_date.replace(/T.{1,}/, '') : ''}
              name="issue_date"
              type="date"
              required
              label={'Fecha de emisión'}
              fullWidth
              onChange={(e) => {
                setInvoice((currentState) => ({
                  ...currentState,
                  issue_date: new Date(+new Date(e.target.value) + 10800000).toISOString(),
                }))
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} md={4}>
            <TextField
              value={invoice.due_date ? invoice.due_date.replace(/T.{1,}/, '') : ''}
              name="due_date"
              type="date"
              required
              label={'Fecha de vencimiento'}
              fullWidth
              onChange={(e) => {
                const newDue_date = new Date(+new Date(e.target.value) + 10800000).toISOString()
                setInvoice((currentState) => ({
                  ...currentState,
                  due_date: newDue_date,
                }))
                if (invoice?.issue_date) {
                  creditDays(invoice?.issue_date, newDue_date)
                }
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              value={invoice?.credit_days ?? ''}
              type="number"
              id="credit_days"
              name="credit_days"
              label={'Días credito'}
              fullWidth
              onChange={(e) => {
                setInvoice((currentState) => ({
                  ...currentState,
                  credit_days: e.target.value,
                }))
              }}
              onBlur={(e) => handleDueDate(e)}
            />
          </Grid>
        </Grid>

        <Grid container item md={12} justifyContent="flex-end" style={{ marginTop: '1.5rem' }}>
          <Grid container item alignItems="center" spacing={2} justifyContent="flex-end">
            <Grid item md={2}>
              <Typography variant="inherit">Total factura</Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Input
                id="amount"
                value={invoice?.amount ? priceFormat(invoice?.amount) : ''}
                disabled
                aria-describedby="apply-shipping"></Input>
            </Grid>
          </Grid>
          {initialStateInvoice.current && (
            <Grid container item alignItems="center" spacing={2} justifyContent="flex-end">
              <Grid item md={2}>
                <Typography variant="inherit">Pagado</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={initialStateInvoice.current?.payment ? priceFormat(initialStateInvoice.current?.payment) : 0}
                  id="payment"
                  name="payment"
                  required
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          <Grid container item alignItems="center" spacing={2} justifyContent="flex-end">
            <Grid item md={2}>
              <Typography variant="inherit">Abono *</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={invoice?.payment ?? 0}
                id="payment"
                name="payment"
                required
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                }}
                onChange={(e) => setInvoice((currentState) => ({ ...currentState, payment: +e.target.value }))}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" spacing={2} justifyContent="flex-end">
            <Grid item md={2}>
              <Typography variant="inherit" style={{ textAlign: 'end' }}>
                Saldo
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={invoice?.balance ? priceFormat(invoice?.balance) : 0}
                id="balance"
                name="balance"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" style={{ margin: '1rem 0' }}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              onClick={(e) => {
                e.preventDefault()
                addInvoice()
              }}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
