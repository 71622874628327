import { gql } from '@apollo/client'

const SALES = {
  GET_TOTAL: gql`
    query TotalSales {
      totalSales {
        amount
      }
    }
  `,
}
export default SALES
