import { Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { EnhancedTableProduct } from '../../components/priceEditor/EnhancedTableProduct'
import { useGetLazyProducts, useGetProducts } from '../../hooks/useGetProducts'
import * as XSLX from 'xlsx'
import priceFormat from '../../utils/priceFormat'
import { formatMinPurchase } from '../../utils/formatMinPurchase'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
  },
}))

export const PriceEditor = () => {
  const { requestProducts } = useGetLazyProducts()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [skip, setSkip] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [loading, setLoading] = useState(false)
  const [productCount, setProductCount] = useState()
  const [products, setProducts] = useState([])
  const [paramsSearched, setParamsSearched] = useState('')
  const [allProducts, setAllProducts] = useState([])
  const [productsFound, setProductsFound] = useState([])
  const query = paramsSearched.length !== 0 ? paramsSearched.toLowerCase() : ''
  const variablesProducts = {
    take: query.length ? null : rowsPerPage,
    skip,
    all: true,
    field: orderBy.toUpperCase(),
    order: order.toUpperCase(),
    categories: null,
    q: query,
  }
  const { data, loading: loadingProducts } = useGetProducts(variablesProducts)

  const classes = useStyles()

  useEffect(() => {
    if (data) {
      setProducts(data.product)
      setProductCount(query?.length ? data.product.length : data._count)
    }
    if (data && query.length) {
      setProductsFound(data.product)
    }
    setLoading(loadingProducts)
  }, [data, loadingProducts])

  const productsDownload = () => {
    const products = new Promise(async (resolve) => {
      if (!query.length) {
        const { data } = await requestProducts({
          variables: {
            take: null,
            all: true,
          },
        })
        resolve(data.products.product)
      } else {
        resolve(productsFound)
      }
    })
    products.then((productsDownload) => {
      // crear un libro
      const workbook = XSLX.utils.book_new()
      // crear columnas basadas en un arreglo de objetos
      const rows = productsDownload.map((product) => ({
        Producto: product.name,
        Productor: product.producer.brand_name,
        'Formato de venta': 'Caja ' + product.sale_format ? product.sale_format + ' un.' : ' ',
        'Costo neto': priceFormat(product.net_unit_cost),
        'Costo c/iva': priceFormat(product.unit_cost),
        'Precio de venta neto': priceFormat(product.net_price_package),
        'Precio de venta c/iva': priceFormat(product.price_package),
        'Precio unitario neto': priceFormat((product.net_price_package / product.sale_format).toFixed(2)),
        'Precio unitario c/iva': priceFormat(product.wholesale_unit_price),
        'Precio sugerido de venta': priceFormat(product.suggested_sale_price),
        Estado: product.isActive ? 'Activo' : 'Inactivo',
        'Pedido minimo (Productor)': formatMinPurchase(product.producer),
        Categorias: product.product_category.map((item) => item.category_product.category).join(', '),
        'Enlace a producto': 'https://www.alimentos.comes.cl/' + product.slug,
        'Imagen de producto': product.product_image[0]
          ? `${process.env.REACT_APP_S3_URL}${product.product_image[0]?.file_image}`
          : ' ',
      }))
      // crear la hoja
      const worksheet = XSLX.utils.json_to_sheet(rows)
      //Define el ancho de las columnas
      worksheet['!cols'] = [
        { wch: 30 }, //Producto
        { wch: 20 }, //Productor
        { wch: 15 }, //Formato de venta
        { wch: 12 }, //Costo neto
        { wch: 12 }, //Costo c/iva
        { wch: 17 }, //Precio de venta neto
        { wch: 17 }, //precio de venta c/iva
        { wch: 17 }, //Precio unitario neto
        { wch: 17 }, //Precio unitario c/iva
        { wch: 18 }, //Precio sugerido de venta
        { wch: 10 }, //Estado
        { wch: 22 }, //Pedido minimo
        { wch: 25 }, //Categorias
        { wch: 35 }, //Enlace de producto
        { wch: 35 }, //Imagen de producto
      ]
      //unir hoja con el libro
      XSLX.utils.book_append_sheet(workbook, worksheet, 'Productos ComeS')
      //generar el archivo
      XSLX.writeFile(workbook, 'Productos_ComeS.xlsx')
    })
  }

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={12}>
          <TextField
            fullWidth={true}
            id="outlined-search"
            label="Buscar producto o productor"
            type="search"
            variant="outlined"
            onChange={(event) =>
              setTimeout(() => {
                setParamsSearched(event.target.value)
                setSkip(0)
              }, 500)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <EnhancedTableProduct
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setSkip={setSkip}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            loading={loading}
            products={products}
            productsFound={productsFound}
            setProducts={setProducts}
            productCount={productCount}
            skip={skip}
            setProductCount={setProductCount}
            setLoading={setLoading}
            isSearched={Boolean(paramsSearched)}
            setAllProducts={setAllProducts}
            allProducts={allProducts}
            variablesProducts={variablesProducts}
            productsDownload={productsDownload}
          />
        </Grid>
      </Grid>
    </>
  )
}
