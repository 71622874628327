import { Button, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/styles'
import { useContext } from 'react'
import AlertContext from '../../context/AlertContext'
import { useDeleteProducts } from '../../hooks/usePostProducts'

const useStyles = makeStyles(() => ({
  container: {
    // width: '50rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    paddingLeft: '4rem',
    gap: '0.5rem',
  },
  containerBtns: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0.5rem',
    marginTop: '1rem',
    justifyContent: 'space-around',
  },
  ul: {
    margin: '0 1.5rem',
  },
  li: {
    margin: ' 0.5rem 0',
  },

  containerProducts: {
    minHeight: '2rem',
    maxHeight: '12rem',
    width: 'auto',
    overflowY: 'auto',
  },
}))
const ConfirmButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '1rem',
    height: '3rem',
    margin: '1rem',
    color: 'white',
    padding: (2, 28),
    fontWeight: 'bold',
    borderRadius: '10px',
  },
})(Button)

export default function ModalDeleteProduct({ selected, handleClose, setSelectedId, selectedId }) {
  const { deleteProducts } = useDeleteProducts()

  async function deleteProduct() {
    const { data, loading, error } = await deleteProducts({
      variables: {
        productIds: selectedId.map((product) => product.ID_product),
      },
    })
    if (error) {
      setAlert({ open: true, message: 'Error al eliminar el producto', severity: 'error' })
    } else {
      const accessDenied = data?.deleteProducts?.message === 'Acceso denegado'
      if (accessDenied) {
        setAlert({ open: true, message: data.deleteProducts.message, severity: 'error' })
      }
      if (data && !accessDenied) {
        setAlert({ open: true, message: 'Producto/s eliminado/s', severity: 'success' })
        setSelectedId([])
        handleClose()
      }
    }
  }
  const classes = useStyles()
  const { setAlert } = useContext(AlertContext)
  return (
    <div className={classes.container}>
      <InfoIcon fontSize="large" color="primary" style={{ fontSize: 70, color: 'red' }} />
      <h1>
        {' '}
        ¿Deseas eliminar {selectedId.length} {selectedId.length === 1 ? 'producto' : 'productos'} del catálogo?
      </h1>
      <div className={classes.containerProducts}>
        <ol className={classes.ul}>
          {selected.map((product) => (
            <li className={classes.li}>{product}</li>
          ))}
        </ol>
      </div>
      <div className={classes.containerBtns}>
        <ConfirmButton style={{ backgroundColor: '#f69223' }} onClick={() => handleClose()}>
          Cancelar
        </ConfirmButton>
        <ConfirmButton style={{ backgroundColor: '#8ac541' }} onClick={() => deleteProduct()}>
          Eliminar
        </ConfirmButton>
      </div>
    </div>
  )
}
