import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY } from '../services/queries'

export function useGetProducts({
  take = 12,
  skip = 0,
  all = null,
  field = null,
  order = 'ASC',
  categories = null,
  q = null,
}) {
  const { data, loading, error } = useQuery(QUERY.PRODUCTS.FULL, {
    variables: {
      take,
      skip,
      all,
      field,
      order,
      categories,
      q,
    },
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { data: data?.products, loading, error }
}

export function useGetLazyProducts() {
  const [requestProducts, { data, error }] = useLazyQuery(QUERY.PRODUCTS.EXCEL, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { requestProducts, data, error }
}
export function useGetLazyProductById() {
  const [requestProductById, { data, error }] = useLazyQuery(QUERY.PRODUCTS.BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { requestProductById, data, error }
}
export function useGetLazyProductsByIds() {
  const [requestProducstByIds, { data, error }] = useLazyQuery(QUERY.PRODUCTS.BY_IDS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'apiProducer' },
  })
  return { requestProducstByIds, data, error }
}
