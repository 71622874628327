import priceFormat from './priceFormat'

export const formatMinPurchase = (producer) => {
  if (producer.type_sale && producer.min_purchase) {
    if (producer.type_sale.type === 'monto') {
      return priceFormat(producer.min_purchase)
    } else {
      return producer.min_purchase + ' ' + producer.type_sale.type
    }
  } else {
    return ' '
  }
}
