import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import CardProduct from './CardProduct'

const product = {
  ID_product: 'ID_product',
  name: 'Nombre producto',
  producer: { brand_name: 'Nombre de productor' },
  sale_format: 0,
  price_package: 0,
  product_image: [{ file_image: 'imagen_no_disponible.jpg', name_image: 'Nombre del producto' }],
}

export default function SuggestedProducts({
  suggestedProducts,
  setSuggestedProducts,
  initialState,
  saveSuggestedProducts,
  load,
  products,
  setProducts,
}) {
  const handleCancel = () => {
    setSuggestedProducts(initialState)
  }

  const addCardProduct = () => {
    const newCard = [...suggestedProducts, product]
    setSuggestedProducts(newCard)
  }
  const deleteCardProduct = (index) => {
    const removedProduct = suggestedProducts.splice(index, 1)[0]
    if (removedProduct.ID_product !== 'ID_product') {
      setProducts([removedProduct, ...products])
    }
    setSuggestedProducts([...suggestedProducts])
  }

  return (
    <Paper style={{ padding: '2rem', margin: '1rem 0rem' }}>
      <Grid container spacing={6}>
        <Grid container item spacing={3}>
          <Typography variant="h5">Productos sugeridos</Typography>
        </Grid>
        <Grid container item xs={12} direction="row" justifyContent="flex-start" alignItems="center">
          {suggestedProducts &&
            suggestedProducts.map((product, index) => (
              <CardProduct
                key={index}
                product={product}
                index={index}
                suggestedProducts={suggestedProducts}
                setSuggestedProducts={setSuggestedProducts}
                products={products}
                deleteCardProduct={deleteCardProduct}
                setProducts={setProducts}
              />
            ))}
          <CardProduct
            product={product}
            key="addCard"
            index="addCard"
            suggestedProducts={suggestedProducts}
            setSuggestedProducts={setSuggestedProducts}
            products={products}
            add={true}
            addCardProduct={addCardProduct}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start">
          <Button
            disabled={load.suggestedProducts.status}
            color="primary"
            variant="contained"
            onClick={() => handleCancel()}
            style={{ color: 'white', margin: '0.5rem' }}>
            <Grid container spacing={2}>
              <Grid item>Cancelar</Grid>
              {load.suggestedProducts.type == 'cancel' && (
                <Grid item>
                  <CircularProgress color="primary" size={15} />
                </Grid>
              )}
            </Grid>
          </Button>
          <Button
            disabled={load.suggestedProducts.status}
            color="secondary"
            variant="contained"
            style={{ color: 'white', margin: '0.5rem' }}
            onClick={() => saveSuggestedProducts()}>
            <Grid container spacing={2}>
              <Grid item>Guardar</Grid>
              {load.suggestedProducts.type == 'save' && (
                <Grid item>
                  <CircularProgress color="primary" size={15} />
                </Grid>
              )}
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
