import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core'

export const RadioOptions = ({ options, name, defaultValue, label, onChange = () => {} }) => {
  return (
    <Grid container>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup row name={name} defaultValue={defaultValue} onChange={onChange}>
            {options.map((option) => (
              <FormControlLabel
                key={`${name}-${option.value}-radio`}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}
